import { Router } from '@angular/router';
import { NewsService } from './../_service/news/news.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

const API_URL = environment.serviceUrl;

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  list:[]
  isOther:boolean=false
  isApply:boolean=true
  ApiPath = API_URL;
  BlogPath: any;
  url:any;
  content :any;

  constructor(private service:NewsService,private router:Router) { }
  

  more(index:any){
      this.BlogPath='https://www.sourcecode.tw/Blog/#/Imformation/'+index;
      console.log(this.BlogPath);
      console.log(this.list[index]);
  }

  ngOnInit(): void {
    
    this.service.qNews({}).subscribe(data=>{
      if(data.result){
        this.list=data.data;
      }
    });
  }

}
