<main>
  <div class="position-relative">
    <!-- Hero for FREE version -->
    <section class="section section-lg section-hero section-shaped">
      <!-- Background circles -->
      <div class="shape shape-style-1 shape-primary">
        <!-- <span class="span-150"></span>
        <span class="span-50"></span>
        <span class="span-50"></span>
        <span class="span-75"></span>
        <span class="span-100"></span>
        <span class="span-75"></span>
        <span class="span-50"></span>
        <span class="span-100"></span>
        <span class="span-50"></span>
        <span class="span-100"></span> -->
      </div>
      <div class="page-header">
        <div class="container shape-container d-flex align-items-center py-lg">
          <div class="col px-0">
            <div class="row align-items-center justify-content-center">
              <div class="col-lg-6 text-center">
                <h1 class="text-white display-2 wow fadeIn" style="padding-bottom: 10%;">探索創意，行動為本</h1>
                <div class="btn-wrapper mt-6">
                  <a [routerLink]="['/board']" class="btn btn-sourcecode btn-icon mt-3 mb-sm-0">
                    <span class="btn-inner--text">與我們聯繫</span>
                    <span class="btn-inner--icon"><i class="ni ni-button-play"></i></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- SVG separator -->
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-white" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </section>
  </div>
  <div class="features-1">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto text-center">
          <h3 class="display-3">WHAT WE DO</h3>
          <p class=" mt-4">原碼數位科技，擅長在問題中找出最佳平衡。<br />無論是APP行動裝置應用程式的開發，或是涵蓋更廣的系統開發！</p>
        </div>
      </div>
      <div class="row mt-5 text-center">
		<div class="col-md-4">
			<div class="info">
				<div class="hi-icon-wrap hi-icon-effect-1 hi-icon-effect-1a">
				  <a [routerLink]="['/application']" ><i class="hi-icon hi-icon-mobile"></i></a>
				</div>
			  <h6 class="info-title text-uppercase text-dark mt-5 text-center">APP 應用程式開發</h6>
			  <p class="description opacity-8  text-center">IOS程式開發 ． Android程式開發  <br />APP後台管理系統 ． APP介面設計</p>
			</div>
		  </div>
        <div class="col-md-4">
          <div class="info">
              <div class="hi-icon-wrap hi-icon-effect-1 hi-icon-effect-1a">
                <a [routerLink]="['/website']"><i class="hi-icon hi-icon-earth"></i></a>
              </div>
            <h6 class="info-title text-uppercase text-dark mt-5 ">網頁開發與設計</h6>
            <p class="description opacity-8">企業形象網站設計 ． 購物網站 ． 影音平台</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="info">
              <div class="hi-icon-wrap hi-icon-effect-1 hi-icon-effect-1a">
                <a [routerLink]="['/system']"><i class="hi-icon hi-icon-screen"></i></a>
            </div>  
            <h6 class="info-title text-uppercase text-dark mt-5 text-center">系統開發設計</h6>
            <p class="description opacity-8">影像監控系統 ． 影音視訊應用 <br />企業應用開發 ． 醫療影像系統開發</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section features-6 wow fadeInDown">
<svg version="1.1" id="圖層_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1400 787.1" style="enable-background:new 0 0 1400 787.1;" xml:space="preserve">
<style type="text/css">
	.st0{opacity:0.7;fill:url(#Background_1_);enable-background:new    ;}
	.st1{opacity:0.7;fill:url(#SVGID_1_);enable-background:new    ;}
	.st2{opacity:0.7;fill:url(#SVGID_2_);enable-background:new    ;}
	.st3{opacity:0.7;fill:url(#SVGID_3_);enable-background:new    ;}
	.st4{enable-background:new    ;}
	.st5{fill:#FFFFFF;}
	.st6{opacity:0.74;fill:#FFFFFF;enable-background:new    ;}
	.st7{fill:#3769A5;}
	.st8{opacity:0.69;fill:#FFFFFF;enable-background:new    ;}
	.st9{fill:#F7B58E;}
	.st10{fill:#FFDA70;}
	.st11{fill:#F6A503;}
	.st12{fill:#F39F85;}
	.st13{fill:#202A53;}
	.st14{fill:#00666B;}
	.st15{fill:#F08711;}
	.st16{fill:#00888B;}
</style>
<linearGradient id="Background_1_" gradientUnits="userSpaceOnUse" x1="1059.746" y1="57.8739" x2="-125.3239" y2="1341.7041" gradientTransform="matrix(1 0 0 1 0 -54)">
	<stop  offset="0" style="stop-color:#3E92C8"/>
	<stop  offset="1" style="stop-color:#1281B6"/>
</linearGradient>
<rect id="Background" y="-1" class="st0" width="1400" height="789.2"/>
<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="1242.4586" y1="159.8863" x2="361.1587" y2="1196.9764" gradientTransform="matrix(1 0 0 1 0 -54)">
	<stop  offset="0" style="stop-color:#3883C4"/>
	<stop  offset="1" style="stop-color:#006FA2"/>
</linearGradient>
<path class="st1" d="M594.4,1c0,0,94.9,35.1,151.2,180S830,422.4,955.3,489s217.3,98.6,279.5,198.8s94.9,100.2,94.9,100.2h70.3V1
	H594.4z"/>
<linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="1115.8365" y1="8.6821" x2="1416.4166" y2="501.0522" gradientTransform="matrix(1 0 0 1 0 -54)">
	<stop  offset="0" style="stop-color:#1F72B9"/>
	<stop  offset="1" style="stop-color:#365198"/>
</linearGradient>
<path class="st2" d="M900.9,1c0,0-3.5,76.3,118.5,112.8s155.6,82.3,177.8,140.8s109.1,102.3,202.7,140V1H900.9z"/>
<linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="256.25" y1="518.4999" x2="256.25" y2="799.6199" gradientTransform="matrix(1 0 0 1 0 -54)">
	<stop  offset="0" style="stop-color:#1F72B9"/>
	<stop  offset="1" style="stop-color:#365198"/>
</linearGradient>
<path class="st3" d="M0,480.2c0,0,80.5-29.7,117.4,35.6s18.3,83.1,104,83.1S386,588,401.9,688s110.6,100,110.6,100H0V480.2z"/>
<g class="st4">
	<path class="st5" d="M185.5,88.8h-12.2c-0.3,0.9-0.7,1.8-1.1,2.8h10.8v13.3h-8.3v7.9c0,0.7-0.2,1.2-0.6,1.7
		c-0.4,0.5-0.8,0.8-1.4,0.9c-0.5,0.2-1.5,0.2-3,0.2c-0.6,0-1.2,0-1.9-0.1l-0.9-2.9c2.4,0,3.8-0.1,4.1-0.2s0.5-0.4,0.5-1v-6.7h-8.5
		V91.6h5.9c0.4-0.8,0.7-1.7,1.1-2.8h-9.6v11.4c0,6.7-1.1,11.9-3.4,15.9l-2.7-1.9c2-3.6,3-8.5,3-14.6V85.9h28.2L185.5,88.8z
		 M168.7,107.2c-1.6,2.7-3.8,5.1-6.6,7.3l-2.5-2c3.1-2.4,5.2-4.7,6.5-6.8L168.7,107.2z M179.7,96.9v-2.5h-13.6v2.5H179.7z
		 M179.7,102.1v-2.5h-13.6v2.5H179.7z M185.8,112.7l-2.4,2c-1.6-2.4-3.6-4.8-6.2-7.1l2.4-1.8C181.9,107.8,184,110.1,185.8,112.7z"/>
	<path class="st5" d="M203.5,89.2h-4.6c-0.7,2.6-1.4,4.8-2.2,6.7h6.2v15.4h-3v-1.1h-2.5v2.2h-3v-11.7c-0.5,0.9-1.1,1.7-1.8,2.4
		l-1.4-3.3c1.9-2.5,3.3-6,4.5-10.6h-3.9v-3h11.7V89.2z M200,107.1v-8.1h-2.5v8.1H200z M207.3,106.2c-0.2,3-0.9,6-2.1,8.8l-2.5-1.4
		c1.1-2.5,1.7-5.1,2-8L207.3,106.2z M222.2,101.2c0,2.6-0.1,5-0.2,7.4s-0.3,3.9-0.5,4.5c-0.2,0.6-0.5,1.2-0.9,1.6
		c-0.4,0.4-1,0.7-1.7,0.9s-2,0.3-3.7,0.3l-1-3c0.8,0,1.4,0.1,1.9,0.1c1.1,0,1.7-0.1,2-0.2s0.4-0.4,0.5-0.6c0.1-0.3,0.2-1.2,0.3-2.9
		c0.1-1.7,0.2-3.4,0.2-5h-14.3V85.8H222v2.7h-6.4v2.3h5.3v2.7h-5.3v2.4h5.3v2.7h-5.3v2.5H222.2z M212.5,90.9v-2.3H208v2.3H212.5z
		 M212.5,96.1v-2.4H208v2.4H212.5z M212.5,101.2v-2.4H208v2.4H212.5z M211.3,113.4l-2.6,0.5c0-2.8-0.2-5.4-0.6-7.6l2.3-0.4
		C210.9,108.4,211.2,110.9,211.3,113.4z M215.1,111.7l-2.5,0.6c-0.1-2.2-0.6-4.2-1.2-6.2l2.4-0.6
		C214.4,107.3,214.8,109.3,215.1,111.7z M218.3,110.2l-2.2,0.7c-0.2-1.5-0.6-3.2-1.2-5l2.1-0.6C217.7,106.9,218.2,108.5,218.3,110.2
		z"/>
	<path class="st5" d="M259.2,100.1h-25.1v5.8c0,4.3-1.1,7.7-3.2,10.1l-2.5-2.2c0.7-0.7,1.4-1.8,1.9-3.2s0.8-3.7,0.8-6.8v-6.5h28.1
		V100.1z M258.3,90.1h-5.2c-1,1-2,1.8-3.1,2.5c2.7,0.6,5.3,1.3,7.5,2l-1.2,2.1c-2.9-1-6.1-1.8-9.4-2.4c-4.4,1.7-8.9,2.4-13.5,2.3
		l-0.9-2.4c3,0,5.9-0.2,8.8-0.8c-2-0.4-4.4-0.7-6.9-0.9l1.4-2.1c3.3,0.3,6.8,0.8,10.5,1.5c1.1-0.6,2-1.3,2.7-1.9h-19.7v-2.8h13.1
		c-0.3-0.6-0.6-1.2-1-1.8l3.2-0.7c0.5,0.8,0.9,1.6,1.4,2.4h12.2V90.1z M259.2,115.1H234v-2.6h11.2v-2.3h-7.1v-2.5h7.1v-2h-6.2
		c-0.9,1.4-1.9,2.5-2.9,3.3l-1.9-2.5c1.7-1.4,3-3.2,3.9-5.6l2.9,0.9c-0.1,0.5-0.3,1-0.5,1.3h4.7v-2.1h3.2v2.1h9.2v2.6h-9.2v2h8.4
		v2.5h-8.4v2.3h10.8V115.1z"/>
	<path class="st5" d="M279.3,115.7h-3.1v-1.8h-6.5v1.8h-3.1V99.9h12.7V115.7z M276.2,111.1v-8.3h-6.5v8.3H276.2z M291.7,96.8h-21.6
		V85.7h21.6V96.8z M288.5,94v-5.5h-15.2V94H288.5z M295,115.7H292v-1.8h-6.6v1.8h-3.1V99.9H295V115.7z M292,111.1v-8.3h-6.6v8.3H292
		z"/>
	<path class="st5" d="M315.8,100.4h-5c-0.1,5.7-2.1,10.8-5.7,15.5l-2.5-2.4c3.2-3.6,4.9-8,5.2-13.1h-5.2v-2.8h5.4v-5h-4.7v-2.9h4.7
		v-4.7h3.1v4.7h4.2v2.9h-4.2v5h4.9V100.4z M316.1,109.5l-2.4,2.1c-0.9-2-2-3.9-3.2-5.7l2.2-2C314.3,106,315.4,107.9,316.1,109.5z
		 M333.3,108.7c0,2-0.2,3.5-0.4,4.5c-0.3,1-0.7,1.6-1.3,1.9c-0.6,0.2-1.9,0.4-4,0.4c-1.8,0-2.7-0.9-2.7-2.8v-7.2h-2.1
		c0,4.4-2.1,8-6.1,10.6l-2.2-2.5c1.7-0.9,3-2,3.9-3.5c1-1.5,1.4-3,1.4-4.7h-3V85.7h14.9v19.6H328v6.2c0,0.5,0.4,0.8,1.1,0.8
		c0.5,0,0.9-0.1,1-0.4s0.3-1,0.3-2.2s0-2,0-2.2L333.3,108.7z M328.6,91.4v-2.7h-8.7v2.7H328.6z M328.6,96.8v-2.7h-8.7v2.7H328.6z
		 M328.6,102.4v-2.9h-8.7v2.9H328.6z"/>
	<path class="st5" d="M360.1,92h-2.2v3.3H351v1.4h7.4v2H351v1.6h8.2v2h-19.5v-2h8.4v-1.6h-7.4v-2.1h7.4v-1.4h-6.7v-1.9h6.7V92h-8.7
		v-2h8.7v-1.4h-6.7v-1.9h6.7V85h3v1.6h6.9V90h2.2V92z M359.6,115.2h-20.3V113h20.3V115.2z M357.9,111.5H341v-7.7h16.9V111.5z
		 M348.1,106.7v-1.1H344v1.1H348.1z M348.1,109.7v-1.2H344v1.2H348.1z M355,106.7v-1.1H351v1.1H355z M355,109.7v-1.2H351v1.2H355z
		 M354.9,90v-1.4H351V90H354.9z M354.9,93.4V92H351v1.4H354.9z M363.9,109.3h-3V87.5h3V109.3z M369.4,112.8c0,1-0.3,1.8-1,2.2
		c-0.7,0.5-2.6,0.7-5.8,0.7l-0.6-3.1c0.9,0.1,1.8,0.1,2.6,0.1c0.8,0,1.3-0.1,1.5-0.2c0.2-0.1,0.3-0.4,0.3-0.7V85h3V112.8z"/>
	<path class="st5" d="M383.9,98.2l-1.9,2.6c-1.6-1.6-3.6-3.1-6-4.6l1.9-2.6C380.5,95.1,382.5,96.7,383.9,98.2z M383.8,103.8
		c-1.1,4.2-2.8,8.3-5,12.2l-2.7-1.9c2.4-4.5,4.1-8.4,5-11.9L383.8,103.8z M384,89.8l-2.2,2.4c-1.1-1.5-2.9-3.2-5.3-5.1l2.2-2.4
		C380.7,86.2,382.4,87.9,384,89.8z M390.1,101c0,0.5,0,1.1,0,1.6c0,3.7-0.5,6.6-1.4,8.6s-2.3,3.7-4.1,5l-2.2-2.2
		c3-1.7,4.5-5.4,4.5-10.9c0-0.6,0-1.3,0-2H390.1z M407,99.1l-2.9,1.5c-0.4-0.7-0.9-1.4-1.5-2.1c-7.4,0.6-13.2,1-17.3,1l-0.6-2.6
		c1.6-0.8,3.5-2.7,5.6-5.6h-5.6v-2.9h7.6c0.5-0.8,1.1-2,1.8-3.6l3.3,0.7c-0.4,1-0.9,1.9-1.4,2.9h10.7v2.9h-12.4
		c-1.2,1.8-2.7,3.5-4.6,5.2c3.6,0,7.2-0.3,10.7-0.7c-0.5-0.7-1.3-1.4-2.2-2.3l2.9-1.4C403.4,94.2,405.3,96.6,407,99.1z M396.3,115.8
		h-3V101h3V115.8z M406.8,115.5h-4.7c-0.8,0-1.5-0.2-1.9-0.7s-0.7-1.1-0.7-1.9v-12h3.1v10.9c0,0.2,0.1,0.4,0.2,0.5
		c0.1,0.2,0.3,0.2,0.4,0.2h3.6V115.5z"/>
	<path class="st5" d="M425.6,102.6l-2.1,2.1c-0.6-1.2-1.2-2.4-2-3.5v14.5h-3V103c-0.9,1.8-2.1,3.7-3.7,5.7l-1.8-3
		c2-2.5,3.7-5.6,5.3-9.3h-5v-2.9h5.2v-4.4c-1.7,0.3-3,0.5-3.9,0.5l-1.2-2.7c4.2-0.4,7.6-1.2,10.3-2.2l1.4,2.6
		c-1.1,0.5-2.3,0.9-3.6,1.2v5.1h3.8v2.9h-3.8v0.7C423.2,99.3,424.6,101,425.6,102.6z M443.8,115.4H424v-2.8h8.6v-4.4h-7.3v-2.9h7.3
		v-3.6c-2.8,0.2-5,0.3-6.5,0.4l-1-2.7c6.7-0.2,12.4-0.8,17-1.9l1.2,2.6c-2.6,0.6-5.2,1-7.6,1.3v3.9h7.3v2.9h-7.3v4.4h8.1V115.4z
		 M442,96.4h-15.6V85.9H442V96.4z M438.8,93.5v-4.8h-9.2v4.8H438.8z"/>
</g>
<path class="st6" d="M576,460.1H189.6c-6.5,0-11.9-5.6-11.9-12.2v-22.3c0-6.7,5.4-12.2,11.9-12.2H576c6.5,0,11.9,5.6,11.9,12.2v22.3
	C587.9,454.6,582.6,460.1,576,460.1z"/>
<path class="st6" d="M411.3,251.1H191.2c-6.6,0-12-5.6-12-12.2v-22.3c0-6.7,5.4-12.2,12-12.2h220.1c6.6,0,12,5.6,12,12.2v22.3
	C423.3,245.6,417.9,251.1,411.3,251.1z"/>
<path class="st6" d="M411.3,183.1H191.2c-6.6,0-12-5.6-12-12.2v-22.3c0-6.7,5.4-12.2,12-12.2h220.1c6.6,0,12,5.6,12,12.2v22.3
	C423.3,177.7,417.9,183.1,411.3,183.1z"/>
<path class="st6" d="M411.3,319.6H191.2c-6.6,0-12-5.6-12-12.2v-22.3c0-6.7,5.4-12.2,12-12.2h220.1c6.6,0,12,5.6,12,12.2v22.3
	C423.3,314.1,417.9,319.6,411.3,319.6z"/>
<path class="st6" d="M411.3,389.1H191.2c-6.6,0-12-5.6-12-12.2v-22.3c0-6.7,5.4-12.2,12-12.2h220.1c6.6,0,12,5.6,12,12.2v22.3
	C423.3,383.5,417.9,389.1,411.3,389.1z"/>
<g>
	<g class="st4">
		<path class="st7" d="M220.3,160.4h-8.5c-0.2,0.4-0.4,0.8-0.6,1.2h8.1v4.7c0,0.6-0.2,1-0.6,1.4c-0.4,0.3-1.4,0.5-3.1,0.5l-0.5-1.7
			l1.8,0c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.2,0.2-0.4v-2.8h-3v4.7h-1.9v-4.7h-3.2v4.7h-1.8v-4.7h-3v5.1h-1.9v-6.6h6.5
			c0.3-0.5,0.6-0.9,0.7-1.2h-8.2v-1.6h18.7V160.4z M219.7,157.4h-1.9V154h-6v4.1h-1.9V154h-6v3.4H202v-4.9h7.9v-1h-7.2v-1.6H219v1.6
			h-7.1v1h7.9V157.4z M209.3,157.7c-1.3,0.3-2.7,0.5-4.3,0.6l-0.4-1.4c1.4-0.1,2.8-0.2,4.1-0.5L209.3,157.7z M209.4,154.9l-0.6,1.3
			c-0.8-0.1-2.2-0.3-4.1-0.3l0.5-1.3C206.6,154.5,208,154.7,209.4,154.9z M217.2,157.1l-0.4,1.3c-1.3-0.3-2.8-0.4-4.3-0.5l0.5-1.3
			C214.7,156.7,216.2,156.8,217.2,157.1z M217.1,155.7c-1.3,0.2-2.7,0.3-4.1,0.3l-0.3-1.3c1.3,0,2.7-0.1,4-0.2L217.1,155.7z"/>
		<path class="st7" d="M231.7,161.5c-2.8,2.6-5,4.4-6.8,5.4l-1.1-1.6c2.6-1.6,4.9-3.4,6.9-5.3L231.7,161.5z M242.7,165.2l-1,1.7
			c-3.5-1.7-5.9-3.7-7.2-6v5.2c0,0.7-0.2,1.2-0.5,1.6s-0.9,0.6-1.6,0.6c-0.7,0-1.6,0.1-2.5,0.1l-0.6-1.9c0.5,0,1.4,0.1,2.6,0.1
			c0.5,0,0.7-0.3,0.7-0.8v-10.8h-8.5v-1.9h8.5v-3.3h2v3.3h8.1v1.9h-8.1v2.7c0.2,0.6,0.5,1.2,0.9,1.9c1.9-1.3,3.5-2.6,4.6-3.8
			l1.6,1.2c-1.3,1.4-3,2.8-5.1,4.2C237.8,162.5,239.9,164,242.7,165.2z M230.3,158.9l-1.4,1.3c-1-1.2-2.2-2.3-3.8-3.5l1.4-1.3
			C227.8,156.4,229.1,157.5,230.3,158.9z M240.5,151.6l-1.7,1c-0.8-0.9-1.7-1.6-2.5-2.1l1.7-1C239.1,150.3,240,151,240.5,151.6z"/>
		<path class="st7" d="M253.6,153.2h-7.4v-1.7h2.7c-0.2-0.5-0.4-1-0.7-1.6l1.9-0.5c0.3,0.6,0.6,1.4,0.9,2.2h2.5V153.2z M253.1,167.8
			h-1.9v-0.9h-2.6v1.3h-1.9v-7.3h6.4V167.8z M253.1,156.4h-6.4v-1.7h6.4V156.4z M253.1,159.4h-6.4v-1.7h6.4V159.4z M251.2,165.1
			v-2.4h-2.6v2.4H251.2z M264.9,154.5h-6.2c0,0.7-0.1,1.6-0.1,2.7h5.5c0,2.1-0.1,4.2-0.3,6.3s-0.3,3.3-0.5,3.7
			c-0.2,0.4-0.4,0.7-0.8,0.9c-0.4,0.2-0.9,0.3-1.7,0.3c-0.3,0-0.9,0-1.8-0.1l-0.5-1.9c0.6,0,1.2,0.1,1.7,0.1c0.6,0,1,0,1.2-0.1
			c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-1,0.2-2.6s0.2-3,0.2-4.4h-3.7c-0.2,4.3-1.4,7.4-3.4,9.3l-1.5-1.2c0.9-0.7,1.6-1.8,2.2-3.3
			s1-4.6,1.1-9.4h-2.6v-1.8h4.3c-0.2-0.9-0.5-1.8-0.9-2.8l2-0.5c0.4,1.1,0.8,2.2,1.1,3.3h4.2V154.5z"/>
		<path class="st7" d="M275.4,153.3h-7v-1.8h2.6c-0.2-0.4-0.4-0.9-0.8-1.5l1.9-0.7c0.4,0.6,0.7,1.3,1.1,2.2h2.2V153.3z M274.9,156.4
			h-6v-1.8h6V156.4z M274.9,159.5h-6v-1.8h6V159.5z M275,167.8h-1.9V167h-2.2v1.2H269v-7.3h6V167.8z M273.1,165.2v-2.5h-2.2v2.5
			H273.1z M287.4,166.7l-1,1.7c-2.2-0.8-3.8-2.1-4.7-4.1c-1.1,1.9-2.9,3.2-5.3,4.1l-1-1.7c1.1-0.4,2.1-0.9,3-1.4
			c0.9-0.6,1.5-1.3,1.8-2.2c0.3-0.9,0.5-2.6,0.5-5.2l1.9,0c0,1.2,0,2.5-0.2,3.7c1.1-0.7,2.1-1.4,3-2.2l1.4,1.3
			c-0.8,0.9-2,1.7-3.6,2.6c0.3,0.7,0.8,1.3,1.6,2S286.5,166.4,287.4,166.7z M287.4,157.5l-1.3,1.5c-1.5-1.2-3-2.3-4.6-3.1
			c-1.2,1.6-2.9,2.6-5.1,3.1l-1-1.8c2-0.4,3.5-1.1,4.2-2c0.8-0.9,1.1-2.8,1-5.8l1.9-0.1c0,1.1,0,2.1-0.1,3.2c1-0.6,2-1.3,2.9-2.2
			l1.4,1.4c-1.1,1.1-2.4,2-3.9,2.8C284.7,155.6,286.2,156.6,287.4,157.5z M279.7,162.8l-1.6,1.1c-0.6-1.2-1.4-2.3-2.4-3.4l1.6-1.1
			C278.3,160.5,279.1,161.6,279.7,162.8z M279.7,153.6l-1.6,1.2c-0.5-1.1-1.2-2.1-2.2-3.3l1.5-1.1
			C278.3,151.4,279.1,152.5,279.7,153.6z"/>
		<path class="st7" d="M309.5,166.7l-1,1.6c-2.1-0.9-3.9-1.9-5.4-3c-1.8,1.2-4,2.3-6.7,3.1l-1.1-1.7c2.4-0.7,4.5-1.7,6.2-2.7
			c-1.4-1.4-2.7-3.3-4-5.7c-0.8,3.9-2.6,7.2-5.5,9.8l-1.3-1.5c1.8-1.7,3.1-3.5,3.9-5.4s1.3-5,1.6-9.5h-3.9v-1.9h14.4
			c-0.5,2-1.1,3.9-1.8,5.8h3.4c-0.6,3.7-1.9,6.5-3.8,8.4C305.8,165,307.5,165.9,309.5,166.7z M304.1,151.7h-5.9
			c0,1.4-0.2,2.7-0.3,3.9h4.9C303.2,154.5,303.7,153.2,304.1,151.7z M306,157.5h-6.8c1.2,2.3,2.5,4.1,3.9,5.4
			C304.3,161.5,305.3,159.8,306,157.5z"/>
		<path class="st7" d="M321.2,163.2c-2.7,1.4-5.3,2.5-7.8,3.2l-0.4-1.8c1.2-0.4,2.2-0.7,3.2-1.1v-10.1h-2.7v-1.8h7.2v1.8h-2.6v9.2
			c0.9-0.3,1.8-0.8,2.9-1.3L321.2,163.2z M331.4,153.5c0,3.5-0.2,6.5-0.3,9c-0.2,2.6-0.4,4.1-0.6,4.5c-0.2,0.5-0.5,0.8-1,1
			c-0.4,0.2-1.2,0.3-2.3,0.3c-0.6,0-1.1,0-1.4-0.1l-0.7-1.9c0.7,0.1,1.4,0.1,2,0.1c0.7,0,1.1,0,1.3-0.1c0.2-0.1,0.3-0.1,0.3-0.3
			c0.1-0.1,0.2-0.8,0.3-2s0.2-2.6,0.3-4.2c0.1-1.5,0.1-3,0.2-4.5h-3.4c-0.2,3.9-0.8,6.7-1.9,8.5c-1.1,1.8-2.3,3.3-3.7,4.6l-1.4-1.4
			c1.5-1.3,2.7-2.7,3.6-4.5s1.4-4.1,1.5-7.2h-2.9v-1.9h3l0.1-4h1.9c0,0.7,0,2-0.1,4H331.4z"/>
		<path class="st7" d="M344.4,166.6c0,0.4-0.1,0.8-0.3,1c-0.2,0.3-0.4,0.4-0.7,0.5s-0.8,0.1-1.4,0.1c-0.3,0-0.7,0-1.3,0l-0.5-1.8
			c0.3,0,0.7,0,1.1,0c0.6,0,0.9,0,1-0.1c0.1-0.1,0.2-0.2,0.2-0.3v-2.3c-2.5,1.1-3.8,1.6-3.9,1.6l-0.5-0.9c-0.2,1.5-0.6,2.8-1.2,3.8
			l-1.5-1.3c0.7-1.2,1-3.1,1-5.8v-4.6h8V166.6z M345.7,154.8l-1.5,1c-0.3-0.5-0.5-0.9-0.8-1.1c-2.3,0.4-4.8,0.7-7.5,0.9l-0.5-1.5
			c1.5-1.4,2.7-3,3.5-4.7l1.8,0.6c-0.9,1.6-1.8,2.8-2.7,3.7c1.1-0.1,2.5-0.2,4.1-0.5c-0.5-0.6-1-1.2-1.5-1.6l1.6-1.1
			C343.7,151.9,344.9,153.3,345.7,154.8z M342.5,161.9v-3.5h-4.2v1.2c1.4,0.2,2.6,0.4,3.7,0.8l-0.6,1.6c-0.6-0.2-1.6-0.4-3.1-0.7
			c0,0.8,0,1.6-0.1,2.4C339.1,163.5,340.5,162.9,342.5,161.9z M354.2,164.5c0,1.4-0.2,2.3-0.5,2.6c-0.3,0.3-0.5,0.6-0.8,0.8
			c-0.3,0.2-1.3,0.2-3.2,0.2c-1.4,0-2.3-0.1-2.6-0.2s-0.6-0.4-0.8-0.7c-0.2-0.3-0.3-0.7-0.3-1.1v-7.5h1.9v2.8h5.6v1.7h-5.6v2.8
			c0,0.3,0.1,0.4,0.5,0.4h3.2c0.3,0,0.5-0.1,0.6-0.4c0.1-0.2,0.2-0.9,0.3-2.1L354.2,164.5z M353.9,157.9H348c-0.6,0-1-0.2-1.3-0.5
			c-0.3-0.4-0.5-0.8-0.5-1.4v-6.4h1.9v2.4h5.6v1.7H348v2.2c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.1,0.3,0.1h5.5V157.9z"/>
		<path class="st7" d="M364.8,152.1h-2.7c-0.4,1.8-0.8,3.3-1.2,4.6h3.5v10.7h-1.8v-1h-1.4v1.4h-1.8v-8c-0.4,0.6-0.7,1.1-0.9,1.4
			l-1-2c1.2-1.7,2.1-4.1,2.7-7.1h-2.4v-1.8h6.9V152.1z M362.6,164.6v-6.1h-1.4v6.1H362.6z M376.4,167.3h-8.1v1.1h-1.8v-9.4
			c-0.3,0.2-0.7,0.5-1.2,1l-0.7-1.8c1.9-1.6,3.3-3.3,4-5.2h-1.6v2.3h-1.8v-4h4.1c0.1-0.3,0.3-0.9,0.6-1.9l1.7,0.3
			c-0.1,0.5-0.2,1-0.4,1.6h4.9v4h-1.8v-2.3h-3.7c-0.5,1.3-1.2,2.5-1.9,3.5h2.3c-0.1-0.4-0.3-1-0.6-1.6l1.7-0.5
			c0.2,0.6,0.5,1.3,0.7,2.2h3.4v1.6h-3.4v1.4h3v1.6h-3v1.5h3v1.5h-3v1.6h3.7V167.3z M371,159.4V158h-2.7v1.4H371z M371,162.5V161
			h-2.7v1.5H371z M371,165.6V164h-2.7v1.6H371z"/>
		<path class="st7" d="M386.6,153.2H380v-1.7h2.6c-0.1-0.5-0.4-1-0.7-1.5l1.8-0.6c0.3,0.7,0.6,1.4,0.8,2.1h2.1V153.2z M386.3,156.4
			h-5.7v-1.7h5.7V156.4z M386.3,159.5h-5.7v-1.7h5.7V159.5z M386.3,167.9h-1.8V167h-2.1v1.3h-1.8v-7.4h5.7V167.9z M384.5,165.2v-2.6
			h-2.1v2.6H384.5z M389.4,161.2c-0.2,2.3-0.6,4.2-1.2,5.9l-1.6-1c0.5-1.6,0.8-3.4,1-5.3L389.4,161.2z M398.1,150
			c0,1.5-0.1,3-0.1,4.5c-0.1,1.5-0.2,2.3-0.2,2.6c-0.1,0.3-0.2,0.6-0.4,0.8c-0.2,0.3-0.5,0.4-0.8,0.6c-0.3,0.1-0.9,0.2-1.7,0.2
			c-0.3,0-0.6,0-0.9,0l-0.7-1.7l2.1,0c0.3,0,0.6-0.2,0.7-0.6c0.1-0.4,0.2-1.9,0.3-4.5H394c-0.3,3.9-2.4,6.5-6.1,7.8l-1.1-1.5
			c1.4-0.5,2.6-1.2,3.6-2.1c-0.7-0.7-1.5-1.4-2.5-2.1l1.2-1.3c0.8,0.6,1.6,1.2,2.3,1.9c0.4-0.8,0.6-1.7,0.7-2.8h-4.7V150H398.1z
			 M396.1,164c0,0.9-0.1,1.7-0.3,2.3c-0.2,0.6-0.5,1.1-0.9,1.3s-1.1,0.4-2.1,0.4c-0.9,0-1.4,0-1.7-0.1c-0.3-0.1-0.5-0.3-0.7-0.5
			c-0.2-0.3-0.3-0.6-0.3-1V161h1.8v4.9c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.4,0.1,0.9,0.1c0.4,0,0.7,0,0.8-0.1s0.3-0.4,0.3-0.9
			c0.1-0.5,0.1-1.1,0.1-1.8L396.1,164z M394.9,161.4l-1.4,1.1c-0.9-1.1-1.8-2-2.8-2.7l1.3-1.2C393.1,159.4,394.1,160.3,394.9,161.4z
			 M399,165.7l-1.7,0.8c-0.4-1.8-1-3.5-1.7-5.3l1.6-0.8C397.9,162,398.5,163.8,399,165.7z"/>
	</g>
</g>
<g>
	<g class="st4">
		<path class="st7" d="M251.7,235.3h-3.3v3.7h-1.8v-3.7h-3.7v-1.7h3.7v-1.7h-3.2v-1.7h1.7c-0.2-0.9-0.4-1.9-0.7-2.8H243v-1.7h3.6
			v-1.6h-3v-1.7h3v-2.1h1.8v2.1h2.8v1.7h-2.8v1.6h3.3v1.7h-1c-0.2,0.7-0.5,1.6-0.8,2.8h1.5v1.7h-2.9v1.7h3.3V235.3z M248.8,227.4
			h-2.6c0.2,0.7,0.4,1.7,0.6,2.8h1.2C248.3,229.3,248.6,228.4,248.8,227.4z M261.8,237.6l-1.2,1.5c-1.3-0.9-2.2-1.7-2.7-2.4
			c-0.7,0.9-1.4,1.7-2.2,2.4l-1.2-1.4c1-0.8,1.7-1.6,2.3-2.4c-1.1-1.7-1.8-3.7-2.1-6.3h-0.5v10h-1.9v-18.4h8.5c0,1.1,0,2.1-0.1,3
			c-0.1,0.9-0.2,1.5-0.4,1.7s-0.4,0.4-0.6,0.6c-0.2,0.2-0.9,0.3-1.9,0.3c-0.3,0-0.7,0-1,0l-0.6-1.7c0.4,0,1.1,0,2.1,0
			c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.8,0.2-1.8h-4.7v4.8h6.7c-0.3,3.2-0.9,5.8-1.9,7.8C259.7,235.9,260.7,236.8,261.8,237.6z
			 M258.8,229h-2.4c0.3,1.8,0.7,3.2,1.4,4.4C258.3,232.2,258.6,230.8,258.8,229z"/>
		<path class="st7" d="M270.1,220.4c-0.2,0.7-0.4,1.7-0.8,2.8l-0.5,1.6V239H267v-10.2c-0.3,0.6-0.7,1.2-1.1,1.7l-1-2
			c1.5-2.5,2.7-5.3,3.4-8.5L270.1,220.4z M282.6,236.1h-7.7l0.9,1.1c-1.1,0.7-2.7,1.4-5,2l-0.9-1.5c2-0.6,3.4-1.1,4.4-1.6h-2.9v-8.2
			h11.3V236.1z M283.8,222.2h-4.2v0.9h3.7v3.9h-12.7v-3.9h3.8v-0.9h-4v-1.5h13.5V222.2z M274.3,225.6v-1.1h-1.9v1.1H274.3z
			 M280.7,230.2v-0.9h-7.5v0.9H280.7z M280.7,232.4v-1h-7.5v1H280.7z M280.7,234.7v-1h-7.5v1H280.7z M277.9,223.1v-0.9h-1.8v0.9
			H277.9z M277.9,225.6v-1.1h-1.8v1.1H277.9z M283.9,237.7l-0.9,1.4c-1.4-0.6-3.1-1.1-5-1.6l1-1.4
			C280.9,236.6,282.5,237.1,283.9,237.7z M281.5,225.6v-1.1h-1.8v1.1H281.5z"/>
		<path class="st7" d="M306.2,237.5l-1,1.6c-2.1-0.9-3.9-1.9-5.4-3c-1.8,1.2-4,2.3-6.7,3.1l-1.1-1.7c2.4-0.7,4.5-1.7,6.2-2.7
			c-1.4-1.4-2.7-3.3-4-5.7c-0.8,3.9-2.6,7.2-5.5,9.8l-1.3-1.5c1.8-1.7,3.1-3.5,3.9-5.4s1.3-5,1.6-9.5H289v-1.9h14.4
			c-0.5,2-1.1,3.9-1.8,5.8h3.4c-0.6,3.7-1.9,6.5-3.8,8.4C302.5,235.8,304.2,236.7,306.2,237.5z M300.8,222.5h-5.9
			c0,1.4-0.2,2.7-0.3,3.9h4.9C299.9,225.3,300.4,224,300.8,222.5z M302.7,228.3h-6.8c1.2,2.3,2.5,4.1,3.9,5.4
			C301,232.3,302,230.5,302.7,228.3z"/>
		<path class="st7" d="M328.7,228l-1,1.6c-1.7-0.4-3.4-0.9-4.9-1.5v0.9h-7.4v-1c-1.6,0.7-3.3,1.2-4.9,1.5l-1.1-1.6
			c4.2-0.9,7.3-2.2,9.2-3.9l2,0.5c0,0.1-0.1,0.2-0.2,0.3C322.5,226.2,325.2,227.3,328.7,228z M318.8,223.1h-5.4
			c-0.6,0.9-1.4,1.7-2.4,2.4l-1.3-1.4c1.4-0.9,2.5-2.3,3.5-4.1l1.9,0.4c-0.1,0.3-0.3,0.6-0.6,1.1h4.3V223.1z M318.4,237.4l-1.1,1.5
			c-0.9-0.8-1.8-1.6-2.7-2.1c-1,1-2.3,1.8-3.7,2.3l-1.3-1.5c2.2-0.8,3.8-1.9,4.6-3.3h-2.6v-4.4h6.5v4.4h-1.8l-0.5,1.1
			C316.7,235.9,317.6,236.6,318.4,237.4z M316.3,232.8v-1.3h-2.7v1.3H316.3z M316.8,225l-1.8,0.7c-0.2-0.4-0.5-0.9-1-1.6l1.8-0.7
			C316.1,223.9,316.5,224.4,316.8,225z M321.6,227.6c-1-0.5-1.8-1-2.5-1.5c-0.8,0.6-1.7,1.1-2.6,1.5H321.6z M328.7,237.7l-1,1.5
			c-1.3-0.9-2.8-1.7-4.5-2.5c-1,1.1-2.1,1.9-3.3,2.5l-1.3-1.5c2-0.9,3.3-2.1,3.9-3.4h-2.5v-4.4h6.5v4.4h-2c-0.2,0.5-0.3,0.8-0.4,0.9
			C325.9,235.9,327.4,236.7,328.7,237.7z M328.2,221.5l0,1.6h-6.4c-0.3,0.4-0.5,0.7-0.9,1l-1.8-0.6c1-1.1,1.7-2.2,2.3-3.5l1.8,0.5
			c-0.1,0.4-0.2,0.7-0.4,1H328.2z M324.7,232.8v-1.3H322v1.3H324.7z M326,225.1l-1.9,0.6c-0.4-0.7-0.8-1.2-1.2-1.7l2-0.6
			C325.3,223.9,325.7,224.5,326,225.1z"/>
		<path class="st7" d="M335.1,234.1c-0.3,1.9-0.8,3.6-1.4,5l-1.7-0.8c0.6-1.5,1-3.1,1.3-4.7L335.1,234.1z M341.4,232.4l-1.6,0.7
			c-0.1-0.4-0.3-0.8-0.5-1.2c-2.3,0.6-4.5,1-6.4,1.3l-0.7-1.7c1.6-1.4,3-2.9,4.1-4.4c-1,0.2-2.1,0.4-3.4,0.5l-0.5-1.8
			c1.5-1.6,2.6-3.5,3.6-5.6l1.8,0.8c-0.9,1.8-1.9,3.4-3,4.6c1-0.1,2-0.2,3-0.5c0.5-0.7,1-1.4,1.5-2.3l1.6,0.9
			c-1.5,2.7-3.5,5.1-5.8,7.2c1.1-0.1,2.2-0.3,3.5-0.7c-0.1-0.3-0.3-0.6-0.5-1l1.7-0.6C340.3,229.5,340.9,230.8,341.4,232.4z
			 M338.5,238l-1.8,0.5c-0.2-1.6-0.5-3.1-0.9-4.3l1.8-0.3C338,235.1,338.3,236.5,338.5,238z M341.3,237l-1.7,0.5
			c-0.2-1.1-0.5-2.3-1.1-3.6l1.6-0.4C340.7,234.8,341.1,236,341.3,237z M350.6,223.5c0,2.1-0.1,4.9-0.2,8.4
			c-0.1,3.5-0.4,5.4-0.7,5.9c-0.4,0.5-0.7,0.8-1.1,1c-0.4,0.2-1.2,0.3-2.4,0.3c-0.4,0-0.9,0-1.4,0l-0.8-1.9h3.2
			c0.3,0,0.5-0.1,0.6-0.2s0.3-0.3,0.3-0.5c0.1-0.2,0.1-1,0.2-2.4s0.2-2.9,0.2-4.4c0.1-1.5,0.1-2.9,0.1-4.2h-5.2
			c-0.6,1.2-1.1,2.2-1.7,3l-1.6-1.1c1.3-1.8,2.3-4.2,3.1-7.2l1.9,0.4c-0.1,0.8-0.4,1.8-0.9,3H350.6z M347.4,233.2l-1.6,1.1
			c-0.7-1.4-1.9-3-3.5-4.8l1.5-1.1C345.4,230,346.6,231.6,347.4,233.2z"/>
	</g>
</g>
<g>
	<g class="st4">
		<path class="st7" d="M253.8,303.6l-4.2,0l0,1.8c0,0.6-0.2,1-0.5,1.4s-1,0.6-2,0.6c-0.4,0-1.1,0-2-0.1l-0.5-1.7c1.4,0,2.3,0,2.6,0
			s0.5-0.2,0.5-0.5l0-1.6l-12.6-0.1l0-1.5l12.6,0.1l0-0.7l1.9,0l0,0.7l4.2,0L253.8,303.6z M253.6,300.8l-1.6,0.9
			c-0.4-0.5-0.7-0.9-0.9-1.1c-6,0.3-11,0.4-15,0.3l-0.5-1.5c1,0,2.2,0.1,3.6,0.1c1.8,0,3.2,0,4.3,0l0-1.1l-6.5-0.1l0.1-6.2l6.5,0.1
			l0-1l-8.1-0.1l0-1.5l8.1,0.1l0-1.1l1.8,0l0,1.1l8,0.1l0,1.5l-8-0.1l0,1l6.6,0.1l-0.1,6.2l-0.6,0
			C252.2,299.3,253,300.1,253.6,300.8z M243.5,305.7L242,307c-0.8-0.6-1.9-1.2-3.3-2l1.4-1.3C241.4,304.4,242.5,305,243.5,305.7z
			 M243.5,296.9l0-1l-4.7,0l0,1L243.5,296.9z M243.6,294.6l0-1l-4.7,0l0,1L243.6,294.6z M250.2,298.4l-4.8-0.1l0,1.1
			c2,0,3.4-0.1,4.4-0.2c-0.2-0.2-0.3-0.3-0.4-0.4L250.2,298.4z M250.2,296.9l0-1l-4.8-0.1l0,1L250.2,296.9z M250.2,294.7l0-1
			l-4.8-0.1l0,1L250.2,294.7z"/>
		<path class="st7" d="M264.5,304.1c-1.8,1.4-3.9,2.4-6.1,3.2l-1-1.6c2.1-0.6,4-1.5,5.7-2.7L264.5,304.1z M276.1,302.5l-8.4-0.1
			l-0.1,5.1l-1.9,0l0.1-5.1l-8.3-0.1l0-1.7l8.3,0.1l0-1.3l1.9,0l0,1.3l8.4,0.1L276.1,302.5z M275.7,295.1l-5.1-0.1
			c-0.6,0.9-1.2,1.5-1.7,1.9c2.2,0.6,4.3,1.3,6.1,2l-0.9,1.4c-1.9-0.8-4.1-1.6-6.9-2.3c-2.2,1.2-5.3,1.9-9.2,2l-0.5-1.6
			c2.8,0.1,5.2-0.3,7.2-1c-1.4-0.3-2.9-0.6-4.6-0.9c0.5-0.5,1.1-1,1.8-1.7l-4.3,0l0-1.6l5.6,0.1c0.4-0.4,0.7-0.9,1-1.4l1.8,0.5
			c-0.1,0.2-0.4,0.6-0.6,0.9l4,0l0.5-0.9l1.8,0.3c0,0.1-0.1,0.3-0.3,0.6l4.2,0L275.7,295.1z M275.4,292.9l-1.8,0l0-1.2l-13.5-0.1
			l0,1.2l-1.9,0l0-2.7l7.8,0.1c-0.2-0.3-0.3-0.6-0.5-0.9l1.8-0.5l0.8,1.4l7.3,0.1L275.4,292.9z M268.5,295l-4.1,0
			c-0.3,0.3-0.5,0.6-0.7,0.8c1.4,0.3,2.6,0.5,3.3,0.7C267.7,295.9,268.2,295.4,268.5,295z M276.1,305.9l-1,1.5
			c-2-1.2-4.1-2.1-6.3-2.8l1-1.5C272.2,303.9,274.3,304.8,276.1,305.9z"/>
		<path class="st7" d="M285.7,306.2l-1.8,0l0-1l-1.8,0l0,1.5l-1.9,0l0.2-16.5l5.5,0.1L285.7,306.2z M283.9,303.5l0-4.7l-1.8,0l0,4.7
			L283.9,303.5z M284,297.1l0.1-5.1l-1.8,0l-0.1,5.1L284,297.1z M298.4,300.9l-2.1,0l-0.1,5c0,0.5-0.1,0.9-0.3,1.2
			c-0.2,0.3-0.5,0.5-0.8,0.6s-1,0.1-1.9,0.1c-0.5,0-0.9,0-1.3-0.1l-0.5-1.8c1,0,1.8,0,2.6,0c0.3,0,0.5-0.2,0.5-0.5l0-4.6l-7.8-0.1
			l0-1.7l7.8,0.1l0-1.4l1.8,0l0,1.4l2.1,0L298.4,300.9z M298.3,293l-4.8-0.1l0,2.3l4.2,0l0,1.7l-10.2-0.1l0-1.7l4.1,0l0-2.3l-4.7,0
			l0-1.7l4.7,0l0-2.1l1.8,0l0,2.1l4.8,0.1L298.3,293z M291.8,304.5l-1.6,1c-0.7-1-1.6-2-2.7-3.1l1.5-1.1
			C290.3,302.5,291.2,303.5,291.8,304.5z"/>
		<path class="st7" d="M309.6,300l-1.3,1.3c-0.3-0.7-0.7-1.5-1.2-2.1L307,308l-1.8,0l0.1-7.7c-0.5,1.1-1.3,2.2-2.3,3.5l-1-1.8
			c1.2-1.5,2.3-3.4,3.3-5.6l-3.1,0l0-1.8l3.2,0l0-2.7c-1,0.2-1.8,0.3-2.4,0.3l-0.7-1.7c2.5-0.2,4.6-0.7,6.3-1.3l0.8,1.6
			c-0.7,0.3-1.4,0.5-2.2,0.7l0,3.1l2.3,0l0,1.8l-2.3,0l0,0.5C308.2,298,309,299.1,309.6,300z M320.6,307.9l-12.1-0.1l0-1.7l5.2,0.1
			l0-2.7l-4.5,0l0-1.7l4.5,0l0-2.2c-1.7,0.1-3,0.2-3.9,0.2l-0.6-1.7c4.1-0.1,7.6-0.4,10.4-1l0.7,1.6c-1.6,0.3-3.1,0.6-4.6,0.7l0,2.4
			l4.5,0l0,1.7l-4.5,0l0,2.7l4.9,0.1L320.6,307.9z M319.7,296.3l-9.5-0.1l0.1-6.4l9.5,0.1L319.7,296.3z M317.7,294.6l0-2.9l-5.6-0.1
			l0,2.9L317.7,294.6z"/>
		<path class="st7" d="M332.5,298.9l-3.1,0c-0.1,3.5-1.3,6.6-3.6,9.4l-1.5-1.5c2-2.2,3-4.8,3.2-8l-3.2,0l0-1.7l3.3,0l0-3l-2.8,0
			l0-1.8l2.8,0l0-2.9l1.9,0l0,2.9l2.6,0l0,1.8l-2.6,0l0,3l3,0L332.5,298.9z M332.6,304.5l-1.5,1.3c-0.5-1.2-1.2-2.4-1.9-3.5l1.4-1.2
			C331.6,302.3,332.2,303.5,332.6,304.5z M343.2,304.1c0,1.2-0.1,2.1-0.3,2.7c-0.2,0.6-0.4,1-0.8,1.1c-0.4,0.1-1.2,0.2-2.5,0.2
			c-1.1,0-1.6-0.6-1.6-1.7l0-4.4l-1.3,0c0,2.7-1.3,4.8-3.8,6.4l-1.4-1.5c1-0.5,1.8-1.2,2.4-2.1c0.6-0.9,0.9-1.8,0.9-2.8l-1.8,0
			l0.1-11.9l9.1,0.1l-0.1,11.9l-2.3,0l0,3.8c0,0.3,0.2,0.5,0.6,0.5c0.3,0,0.5-0.1,0.6-0.2c0.1-0.1,0.2-0.6,0.2-1.4
			c0-0.8,0-1.2,0-1.3L343.2,304.1z M340.3,300.2l0-1.7l-5.3-0.1l0,1.7L340.3,300.2z M340.4,296.8l0-1.6l-5.3-0.1l0,1.6L340.4,296.8z
			 M340.4,293.5l0-1.7l-5.3-0.1l0,1.7L340.4,293.5z"/>
		<path class="st7" d="M358.9,308.2l-12.4-0.1l0-1.4l12.4,0.1L358.9,308.2z M359.4,294.1l-1.4,0l0,2l-4.2,0l0,0.9l4.5,0l0,1.2
			l-4.5,0l0,1l5,0.1l0,1.2l-11.9-0.1l0-1.2l5.1,0.1l0-1l-4.5,0l0-1.3l4.5,0l0-0.8l-4.1,0l0-1.1l4.1,0l0-0.9l-5.3-0.1l0-1.2l5.3,0.1
			l0-0.9l-4.1,0l0-1.2l4.1,0l0-1l1.8,0l0,1l4.2,0l0,2l1.4,0L359.4,294.1z M357.9,306l-10.3-0.1l0-4.7l10.3,0.1L357.9,306z
			 M352,304.8l0-0.7l-2.5,0l0,0.7L352,304.8z M352,303l0-0.7l-2.5,0l0,0.7L352,303z M356.2,304.8l0-0.7l-2.5,0l0,0.7L356.2,304.8z
			 M356.2,303l0-0.7l-2.5,0l0,0.7L356.2,303z M356.2,294.9l0-0.9l-2.4,0l0,0.9L356.2,294.9z M356.2,292.8l0-0.9l-2.4,0l0,0.9
			L356.2,292.8z M361.6,304.6l-1.8,0l0.1-13.3l1.8,0L361.6,304.6z M364.9,306.8c0,0.6-0.2,1.1-0.6,1.3c-0.4,0.3-1.6,0.4-3.5,0.4
			l-0.4-1.9c0.6,0,1.1,0.1,1.6,0.1c0.5,0,0.8,0,0.9-0.1c0.1-0.1,0.2-0.2,0.2-0.4l0.2-16.4l1.8,0L364.9,306.8z"/>
	</g>
</g>
<g>
	<g class="st4">
		<path class="st7" d="M229.2,371.8c-1.8,1.4-3.8,2.5-5.9,3.3l-1.1-1.5c2.1-0.8,3.9-1.8,5.6-3L229.2,371.8z M240.6,369.7l-1.6,1.1
			c-0.5-0.7-0.9-1.2-1.2-1.6c-2,0.3-3.8,0.4-5.3,0.5v4.4c0,0.6-0.1,1-0.5,1.4c-0.4,0.3-1.5,0.5-3.4,0.5l-0.8-1.9
			c0.8,0.1,1.5,0.1,2.2,0.1c0.4,0,0.6-0.2,0.6-0.6V370c-2.9,0.1-5.1,0.2-6.5,0.3l-0.5-1.6c1.8-0.5,3.9-1.4,6.3-2.7
			c-2.5,0.1-4.3,0.2-5.3,0.2l-0.3-1.4c2.9-0.9,5.6-2.4,7.9-4.5l1.8,1c-1.4,1.3-3.1,2.3-5.2,3.2l1.7-0.1l2.6-0.2
			c0.1,0,0.6-0.3,1.4-0.9l1.5-1.1l1.7,1.2c-3.4,2.4-6.5,4.1-9.5,5.1c2.5-0.1,5.3-0.3,8.3-0.7c-0.4-0.4-0.8-0.8-1.3-1.2l1.7-1
			C238.5,367.1,239.7,368.5,240.6,369.7z M234,358.3c-2.1,1.7-5.2,3.1-9,4.2l-1.1-1.6c3.4-0.7,6.3-1.9,8.5-3.6L234,358.3z
			 M241.1,373.9l-1.3,1.4c-1.4-1.2-3.3-2.4-5.6-3.5l1.3-1.3C237.8,371.6,239.6,372.7,241.1,373.9z"/>
		<path class="st7" d="M247.1,371.1c-0.3,2.1-0.7,3.8-1.4,5.2l-1.6-0.7c0.6-1.2,1.1-2.8,1.4-4.7L247.1,371.1z M252.4,369.7l-1.7,0.6
			c0-0.1-0.1-0.5-0.2-1.1c-1.9,0.6-3.8,1-5.7,1.3l-0.3-1.5c1.2-1,2.5-2.5,3.9-4.4c-1.1,0.2-2.2,0.3-3.3,0.4l-0.2-1.3
			c1.6-1.8,2.9-3.9,3.8-6.4l1.8,0.6c-0.9,2.1-1.9,3.8-3.1,5.3c0.7-0.1,1.4-0.1,2.1-0.3c0.4-0.6,0.8-1.3,1-1.9l1.7,0.9
			c-1.5,2.7-3,4.8-4.5,6.4c0.9-0.2,1.7-0.4,2.5-0.7c-0.1-0.4-0.3-0.7-0.3-0.9l1.5-0.6C251.7,367.3,252.1,368.5,252.4,369.7z
			 M250,375.4l-1.6,0.4c0-1.4-0.1-2.9-0.5-4.6l1.5-0.4C249.7,372.4,249.9,374,250,375.4z M252.4,373.6l-1.4,0.6
			c-0.2-1.2-0.5-2.2-0.8-3l1.4-0.5C252,371.4,252.2,372.3,252.4,373.6z M263.4,371.1c-0.1,2.2-0.3,3.5-0.4,3.7
			c-0.1,0.2-0.3,0.5-0.6,0.8c-0.3,0.3-1,0.4-2.2,0.4c-1,0-1.6-0.1-1.9-0.4c-0.3-0.3-0.5-0.9-0.5-1.8v-7.2c-0.7,0.1-1.2,0.2-1.6,0.2
			c0,1.5-0.1,2.9-0.2,3.9c-0.1,1.1-0.5,2.1-1.1,3.1c-0.6,1-1.3,1.9-2.3,2.6l-1.4-1.3c0.6-0.4,1.2-1,1.7-1.8c0.5-0.7,0.9-1.6,1.1-2.4
			s0.3-2.2,0.3-3.9l-1.9,0.2l-0.3-1.4c1.2-1.5,2.2-3.1,3-4.7h-3.2v-1.8h3.9c0.2-0.4,0.4-1.1,0.7-2l2,0.3c-0.2,0.7-0.5,1.2-0.6,1.7
			h5.3v1.8h-6.1c-0.6,1.4-1.4,2.7-2.5,4.2c1.8-0.1,3.5-0.3,5-0.6c-0.3-0.4-0.6-0.9-1.1-1.4l1.5-1c1.5,1.8,2.5,3.3,3.2,4.5l-1.6,1.1
			c-0.4-0.7-0.7-1.3-1-1.7l-1.1,0.2v7.5c0,0.3,0.3,0.5,1,0.5c0.5,0,0.8-0.2,0.9-0.6s0.2-1.5,0.3-3.3L263.4,371.1z"/>
		<path class="st7" d="M274.3,361.1h-7.5v-1.7h2.9c-0.2-0.6-0.5-1.1-0.7-1.6l1.9-0.5c0.3,0.5,0.6,1.2,0.9,2.1h2.6V361.1z
			 M273.8,364.2h-6.4v-1.7h6.4V364.2z M273.8,367.3h-6.4v-1.7h6.4V367.3z M273.8,375.6h-1.9v-0.9h-2.7v1.4h-1.8v-7.4h6.4V375.6z
			 M271.9,373v-2.5h-2.7v2.5H271.9z M285.8,374.5l-0.9,1.7c-1.8-0.6-3.4-1.4-4.9-2.5c-1.3,1-2.9,1.9-4.7,2.6l-1.1-1.6
			c1.8-0.5,3.2-1.3,4.4-2.2c-1.2-1.3-2.1-2.8-2.7-4.4h-1v-1.8h9.3v1.8c-0.9,1.9-1.9,3.3-2.9,4.4C282.5,373.3,284,374,285.8,374.5z
			 M285.5,365h-2.8c-0.4,0-0.7-0.2-1-0.5s-0.4-0.6-0.4-1v-3.8h-2.9v1.3c0,0.9-0.2,1.9-0.7,2.7c-0.5,0.9-1.1,1.6-1.9,2l-1.3-1.3
			c1.4-0.7,2.1-2.1,2.1-4V358h6.7v4.8c0,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2h1.7V365z M282.2,368h-4.3
			c0.6,1.3,1.3,2.4,2.1,3.2C280.8,370.5,281.5,369.4,282.2,368z"/>
		<path class="st7" d="M298,361h-8.9v-1.7h3.5c-0.2-0.6-0.5-1.2-0.8-1.7l2-0.4c0.3,0.6,0.6,1.3,0.9,2.1h3.2V361z M297,364.2h-7.1
			v-1.7h7.1V364.2z M297,367.3h-7.1v-1.7h7.1V367.3z M297.1,375.8h-1.9v-1.1h-3.3v1.4H290v-7.4h7.1V375.8z M295.2,373v-2.6h-3.3v2.6
			H295.2z M307.8,365.2h-3.9v10.9H302v-10.9h-3.8v-1.9h3.8v-5.9h1.9v5.9h3.9V365.2z"/>
		<path class="st7" d="M330.1,374.5l-1,1.6c-2.1-0.9-3.9-1.9-5.4-3c-1.8,1.2-4,2.3-6.7,3.1l-1.1-1.7c2.4-0.7,4.5-1.7,6.2-2.7
			c-1.4-1.4-2.7-3.3-4-5.7c-0.8,3.9-2.6,7.2-5.5,9.8l-1.3-1.5c1.8-1.7,3.1-3.5,3.9-5.4s1.3-5,1.6-9.5H313v-1.9h14.4
			c-0.5,2-1.1,3.9-1.8,5.8h3.4c-0.6,3.7-1.9,6.5-3.8,8.4C326.5,372.9,328.2,373.8,330.1,374.5z M324.8,359.6h-5.9
			c0,1.4-0.2,2.7-0.3,3.9h4.9C323.9,362.4,324.4,361.1,324.8,359.6z M326.6,365.3h-6.8c1.2,2.3,2.5,4.1,3.9,5.4
			C325,369.4,326,367.6,326.6,365.3z"/>
		<path class="st7" d="M341.5,358c-0.5,1.5-1.4,3.1-2.6,4.7c-1.2,1.6-2.5,3-3.9,3.9l-1.3-1.4c2.7-1.8,4.8-4.5,6.2-8L341.5,358z
			 M349.3,365.1c0,2-0.1,4-0.2,5.9c-0.2,2-0.3,3.2-0.5,3.6c-0.2,0.5-0.5,0.8-0.9,1.1s-0.9,0.4-1.5,0.4c-1.3,0-2.2,0-2.7,0l-0.6-1.9
			c1,0.1,2,0.1,3.1,0.1c0.3,0,0.5-0.1,0.7-0.2s0.4-1,0.5-2.7s0.2-3.1,0.2-4.4h-4.6c-0.6,4.2-2.9,7.3-6.9,9.3l-1.4-1.5
			c1.5-0.6,2.8-1.6,4.1-2.9c1.2-1.3,1.9-3,2.2-4.9h-3.4v-1.9H349.3z M352.6,365.3l-1.2,1.5c-2.7-2.3-4.4-4.7-5.3-7.4h-2v-1.8h3.2
			C348.1,360.7,349.9,363.2,352.6,365.3z"/>
		<path class="st7" d="M364.4,367.1l-1.3,1.5c-0.6-1-1.2-1.8-1.9-2.5v10h-1.8v-9.8c-0.5,1.6-1.3,3.2-2.3,4.6l-1.2-1.6
			c1.4-1.8,2.5-3.9,3.4-6.4h-2.9V361h3v-3.6h1.8v3.6h2.7v1.8h-2.7v0.6C362.7,365,363.8,366.2,364.4,367.1z M374.8,365.8h-3v10.3H370
			v-10.3h-3.2c0,4.6-1,8.1-3,10.5l-1.5-1.1c1.7-2,2.6-4.8,2.6-8.5v-7.4c3.4-0.2,6.3-0.8,8.7-1.8l1.1,1.6c-2,0.9-4.6,1.5-7.8,1.9v3h8
			V365.8z"/>
	</g>
</g>
<path class="st6" d="M411.3,529.3H191.2c-6.6,0-12-5.6-12-12.2v-22.3c0-6.7,5.4-12.2,12-12.2h220.1c6.6,0,12,5.6,12,12.2v22.3
	C423.3,523.8,417.9,529.3,411.3,529.3z"/>
<g>
	<g class="st4">
		<path class="st7" d="M234.7,512.5h-4.2v1.8c0,0.6-0.2,1-0.5,1.4c-0.3,0.4-1,0.6-2,0.6c-0.4,0-1.1,0-2-0.1l-0.5-1.7
			c1.4,0,2.3,0,2.6,0c0.3,0,0.5-0.2,0.5-0.5v-1.6H216V511h12.6v-0.7h1.9v0.7h4.2V512.5z M234.5,509.7l-1.6,0.9
			c-0.4-0.5-0.7-0.9-0.9-1.1c-6,0.3-11,0.5-15,0.4l-0.5-1.5c1,0,2.2,0,3.6,0c1.8,0,3.2,0,4.3-0.1v-1.1h-6.5v-6.2h6.5v-1h-8.1v-1.5
			h8.1v-1.1h1.8v1.1h8v1.5h-8v1h6.6v6.2h-0.6C233.2,508.2,233.9,509,234.5,509.7z M224.4,514.7l-1.4,1.3c-0.8-0.6-1.9-1.2-3.3-1.9
			l1.4-1.3C222.4,513.4,223.5,514,224.4,514.7z M224.4,503.6v-1h-4.7v1H224.4z M224.4,505.9v-1h-4.7v1H224.4z M231.1,503.6v-1h-4.8
			v1H231.1z M231.1,505.9v-1h-4.8v1H231.1z M231.1,507.4h-4.8v1.1c2-0.1,3.4-0.1,4.4-0.3c-0.2-0.2-0.3-0.3-0.4-0.4L231.1,507.4z"/>
		<path class="st7" d="M257,511.2h-8.4v5.1h-1.9v-5.1h-8.4v-1.6h8.4v-1.1h-7.4v-1.6h7.4v-1h-7.9v-1.6h4.9c-0.2-0.3-0.5-0.6-0.7-0.9
			l1.4-0.8h-6.1v-1.6h6.6v-3.2h1.9v3.2h1.9v-3.2h1.9v3.2h6.5v1.6h-5.9l1.3,0.7c-0.3,0.5-0.5,0.8-0.7,1h4.9v1.6h-7.9v1h7.4v1.6h-7.4
			v1.1h8.4V511.2z M245.2,513c-2,1.4-3.8,2.5-5.6,3.2l-1.2-1.5c1.7-0.6,3.5-1.6,5.3-3L245.2,513z M243.3,499.7l-1.4,1
			c-0.6-0.7-1.3-1.4-1.9-1.9l1.4-1.1C242.2,498.4,242.8,499.1,243.3,499.7z M250.4,502.5h-5.6c0.4,0.5,0.8,1.1,1.2,1.7h3.2
			C249.7,503.7,250.1,503.1,250.4,502.5z M257.4,514.7l-1,1.5c-2.1-1.2-4.1-2.2-6.1-2.9l1.1-1.5
			C253.3,512.5,255.3,513.4,257.4,514.7z M255.4,498.6c-0.4,0.6-1,1.2-1.8,2l-1.7-1c0.7-0.5,1.3-1.2,1.9-1.9L255.4,498.6z"/>
		<path class="st7" d="M269.3,504.9h-6.1v11.4h-1.9v-18.2h8.1V504.9z M267.4,500.8v-0.9h-4.2v0.9H267.4z M267.4,503.3v-0.9h-4.2v0.9
			H267.4z M276.1,511.3h-3v4.6h-1.9v-4.6h-2.5c-0.2,1.9-1.1,3.5-2.8,4.7l-1.6-1.3c1.4-0.9,2.2-2.1,2.4-3.4h-3v-1.8h3.2v-1.8h-2.5
			V506h11.1v1.8h-2.4v1.8h3V511.3z M271.2,509.5v-1.8h-2.4v1.8H271.2z M278.8,514.7c0,0.5-0.2,0.9-0.5,1.2c-0.3,0.3-1.3,0.4-2.9,0.4
			c-0.3,0-0.6,0-0.8,0l-0.5-2.1c0.5,0.1,1.1,0.2,1.8,0.2c0.4,0,0.6,0,0.7-0.1c0.1-0.1,0.2-0.2,0.2-0.4v-9h-6.1v-6.8h8V514.7z
			 M276.9,500.8v-0.9h-4.2v0.9H276.9z M276.9,503.3v-0.9h-4.2v0.9H276.9z"/>
		<path class="st7" d="M291.9,499.6c-1.1,1.6-2.7,2.9-4.8,4.1h3.9v4.9h-4.2l-0.2,1.9h4.7c0,1.9-0.2,3.3-0.4,4.1s-0.5,1.3-1,1.5
			s-1.4,0.3-2.8,0.3c-0.5,0-1,0-1.7,0l-0.6-1.8c0.7,0,1.9,0.1,3.6,0.1c0.7,0,1-0.9,1-2.6h-4.8l0.6-5.1h4.1v-1.6h-3.6v-0.9
			c-0.5,0.2-1,0.5-1.8,0.7l-0.9-1.6c1.1-0.3,2.1-0.8,3.2-1.3c-0.5-0.3-1.1-0.6-1.8-1l1.3-1.3c0.6,0.2,1.4,0.7,2.3,1.2
			c0.7-0.5,1.3-1,1.8-1.6h-4.8v-1.7h7.1V499.6z M301.9,503.6l-1,1.5c-0.7-0.3-1.4-0.7-2-1v2.2c0,0.2,0.1,0.3,0.4,0.3h1.9v1.6h-2.5
			c-1,0-1.5-0.5-1.5-1.6v-1.3h-2.1c0,1.5-0.8,2.6-2.5,3.5l-1.2-1.4c0.6-0.2,1-0.6,1.3-1s0.5-1.1,0.5-2v-0.8h5
			c-2.7-1.6-4.5-3.5-5.5-5.7l1.7-0.5c0.2,0.5,0.6,1.1,1.1,1.7c0.6-0.5,1.2-1.1,1.6-1.7l1.5,1c-0.7,0.8-1.3,1.5-2,2
			c0.2,0.2,0.5,0.5,1,0.8c0.8-0.7,1.5-1.3,2.1-2l1.5,0.9c-0.7,0.8-1.4,1.5-2.1,2C299.8,502.7,300.7,503.1,301.9,503.6z M301.5,515.3
			l-1.3,1.2c-0.9-0.7-2-1.4-3.2-2.1c-1.2,0.9-2.7,1.6-4.6,2.1l-0.9-1.6c1.7-0.4,2.9-1,3.8-1.5c-1.1-0.7-2.1-1.2-2.9-1.6l1.1-1.1
			h-1.3v-1.7h8v1.7c-0.6,0.9-1.2,1.7-1.9,2.4C299.4,513.9,300.5,514.6,301.5,515.3z M298,510.8h-4c1,0.5,1.9,1,2.8,1.5
			C297.3,511.7,297.8,511.2,298,510.8z"/>
		<path class="st7" d="M312.8,508.3l-1.3,1.3c-0.3-0.7-0.8-1.5-1.2-2.1v8.8h-1.8v-7.7c-0.5,1.1-1.3,2.2-2.2,3.5l-1.1-1.8
			c1.2-1.5,2.3-3.4,3.2-5.7h-3.1v-1.8h3.2v-2.7c-1,0.2-1.8,0.3-2.4,0.3l-0.8-1.7c2.5-0.2,4.6-0.7,6.3-1.4l0.9,1.6
			c-0.7,0.3-1.4,0.5-2.2,0.7v3.1h2.3v1.8h-2.3v0.5C311.4,506.3,312.2,507.4,312.8,508.3z M324,516.1h-12.1v-1.7h5.2v-2.7h-4.5V510
			h4.5v-2.2c-1.7,0.1-3,0.2-3.9,0.2l-0.6-1.7c4.1-0.1,7.6-0.5,10.4-1.1l0.7,1.6c-1.6,0.4-3.1,0.6-4.6,0.8v2.4h4.5v1.7H319v2.7h4.9
			V516.1z M322.9,504.6h-9.5v-6.4h9.5V504.6z M320.9,502.8v-2.9h-5.6v2.9H320.9z"/>
		<path class="st7" d="M346.3,511.8c-0.3,3.1-1.1,4.7-2.6,4.7c-1.3,0-2.5-1.2-3.4-3.7c-1-2.5-1.5-5.7-1.6-9.8h-11v-1.8h10.9
			c-0.1-1.5-0.1-2.6-0.1-3.5h1.9c0,0.8,0,2,0.1,3.5h5.8v1.8h-5.7c0.1,4,0.5,7,1.3,8.8s1.4,2.7,1.7,2.7c0.4,0,0.7-1.2,0.8-3.7
			L346.3,511.8z M338.4,513c-4,1-7.3,1.7-10,2l-0.4-1.7c1.2-0.2,2.6-0.4,4.2-0.7v-5.7h-3.4v-1.8h9.1v1.8h-3.7v5.3
			c1.7-0.4,3.2-0.7,4.2-1L338.4,513z M345.7,499.7l-1.4,1.2c-0.9-0.8-1.8-1.4-2.7-1.9l1.5-1.3C344.1,498.3,345,498.9,345.7,499.7z"
			/>
		<path class="st7" d="M368.7,510.3h-9.4c-1.1,0.9-2,1.6-2.8,1.9v2.2c1.3-0.2,2.6-0.5,4.1-0.8l0,1.7c-2.4,0.6-4.4,1-6.2,1.1
			l-0.4-1.6c0.3-0.1,0.5-0.4,0.5-0.7v-1c-1.1,0.5-2.3,0.9-3.8,1.3l-1.1-1.7c2.8-0.6,5-1.5,6.7-2.5h-6.5v-1.6h8.5
			c-0.1-0.2-0.2-0.5-0.5-0.8l2.1-0.3c0.2,0.4,0.4,0.7,0.6,1.1h8.1V510.3z M362.2,502.6H357v0.9h4.2v2.4c0,0.4-0.1,0.8-0.4,1
			c-0.3,0.3-1.2,0.4-2.7,0.3l-0.4-1.4l1.6,0c0.2,0,0.2-0.1,0.2-0.3V505H357v2.9h-1.8V505h-2.6v2.3H351v-3.7h4.3v-0.9h-5.4v-1.5h5.4
			v-1h-3.1c-0.1,0.2-0.3,0.4-0.7,0.8l-1.5-1c0.7-0.7,1.3-1.6,1.8-2.4l1.7,0.5c-0.1,0.3-0.2,0.5-0.4,0.6h2.1v-1.2h1.8v1.2h4.5v1.4
			H357v1h5.2V502.6z M368.9,514.9l-1,1.6c-4-0.7-6.7-2.5-8-5.4l1.9-0.3c0.5,0.8,1,1.5,1.7,2.1c1-0.5,2-1.2,3-2.1l1.6,1.1
			c-0.7,0.7-1.6,1.4-2.8,2.1C366.6,514.5,367.8,514.8,368.9,514.9z M364.9,504.7h-1.8v-6.1h1.8V504.7z M368.2,506
			c0,0.4-0.1,0.7-0.3,1s-0.5,0.5-0.7,0.6s-0.9,0.2-1.9,0.2c-0.4,0-0.7,0-1.1,0l-0.5-1.7c0.2,0,0.5,0,0.9,0c0.9,0,1.4,0,1.6-0.1
			c0.2-0.1,0.2-0.2,0.2-0.3v-7.9h1.8V506z"/>
		<path class="st7" d="M378.3,497.9c-0.3,0.9-0.6,1.7-0.9,2.3l-0.9,2.1v14h-1.9v-10.7c-0.6,0.8-1,1.4-1.4,1.8l-0.9-2
			c1.8-2.2,3.2-4.8,4.1-8L378.3,497.9z M390.9,502.1h-6.7v3.2h6.2v1.8h-6.2v3.1h6.2v1.7h-6.2v4.4h-1.9v-14.2h-0.7
			c-1,2-2.1,3.6-3.1,4.7l-1.4-1.3c1.8-2.2,3.3-4.9,4.2-8l1.9,0.4c-0.1,0.4-0.4,1.2-0.8,2.4h8.5V502.1z"/>
	</g>
</g>
<g>
	<g class="st4">
		<path class="st7" d="M205,442.4c-1.8,1.4-3.8,2.5-5.9,3.3l-1.1-1.5c2.1-0.8,3.9-1.8,5.6-3L205,442.4z M216.5,440.3l-1.6,1.1
			c-0.5-0.7-0.9-1.2-1.2-1.6c-2,0.3-3.8,0.4-5.3,0.5v4.4c0,0.6-0.1,1-0.5,1.4c-0.4,0.3-1.5,0.5-3.4,0.5l-0.8-1.9
			c0.8,0.1,1.5,0.1,2.2,0.1c0.4,0,0.6-0.2,0.6-0.6v-3.7c-2.9,0.1-5.1,0.2-6.5,0.3l-0.5-1.6c1.8-0.5,3.9-1.4,6.3-2.7
			c-2.5,0.1-4.3,0.2-5.3,0.2l-0.3-1.4c2.9-0.9,5.6-2.4,7.9-4.5l1.8,1c-1.4,1.3-3.1,2.3-5.2,3.2l1.7-0.1l2.6-0.2
			c0.1,0,0.6-0.3,1.4-0.9l1.5-1.1l1.7,1.2c-3.4,2.4-6.5,4.1-9.5,5.1c2.5-0.1,5.3-0.3,8.3-0.7c-0.4-0.4-0.8-0.8-1.3-1.2l1.7-1
			C214.4,437.6,215.6,439,216.5,440.3z M209.9,428.8c-2.1,1.7-5.2,3.1-9,4.2l-1.1-1.6c3.4-0.7,6.3-1.9,8.5-3.6L209.9,428.8z
			 M217,444.4l-1.3,1.4c-1.4-1.2-3.3-2.4-5.6-3.5l1.3-1.3C213.6,442.1,215.5,443.3,217,444.4z"/>
		<path class="st7" d="M223,441.7c-0.3,2.1-0.7,3.8-1.4,5.2l-1.6-0.7c0.6-1.2,1.1-2.8,1.4-4.7L223,441.7z M228.2,440.3l-1.7,0.6
			c0-0.1-0.1-0.5-0.2-1.1c-1.9,0.6-3.8,1-5.7,1.3l-0.3-1.5c1.2-1,2.5-2.5,3.9-4.4c-1.1,0.2-2.2,0.3-3.3,0.4l-0.2-1.3
			c1.6-1.8,2.9-3.9,3.8-6.4l1.8,0.6c-0.9,2.1-1.9,3.8-3.1,5.3c0.7-0.1,1.4-0.1,2.1-0.3c0.4-0.6,0.8-1.3,1-1.9l1.7,0.9
			c-1.5,2.7-3,4.8-4.5,6.4c0.9-0.2,1.7-0.4,2.5-0.7c-0.1-0.4-0.3-0.7-0.3-0.9l1.5-0.6C227.6,437.9,228,439.1,228.2,440.3z
			 M225.8,446l-1.6,0.4c0-1.4-0.1-2.9-0.5-4.6l1.5-0.4C225.6,443,225.8,444.6,225.8,446z M228.3,444.1l-1.4,0.6
			c-0.2-1.2-0.5-2.2-0.8-3l1.4-0.5C227.9,441.9,228.1,442.9,228.3,444.1z M239.3,441.6c-0.1,2.2-0.3,3.5-0.4,3.7
			c-0.1,0.2-0.3,0.5-0.6,0.8c-0.3,0.3-1,0.4-2.2,0.4c-1,0-1.6-0.1-1.9-0.4c-0.3-0.3-0.5-0.9-0.5-1.8v-7.2c-0.7,0.1-1.2,0.2-1.6,0.2
			c0,1.5-0.1,2.9-0.2,3.9c-0.1,1.1-0.5,2.1-1.1,3.1c-0.6,1-1.3,1.9-2.3,2.6l-1.4-1.3c0.6-0.4,1.2-1,1.7-1.8c0.5-0.7,0.9-1.6,1.1-2.4
			c0.2-0.9,0.3-2.2,0.3-3.9l-1.9,0.2l-0.3-1.4c1.2-1.5,2.2-3,3-4.7h-3.2v-1.8h3.9c0.2-0.4,0.4-1.1,0.7-2l2,0.3
			c-0.2,0.7-0.5,1.2-0.6,1.7h5.3v1.8H233c-0.6,1.4-1.4,2.7-2.5,4.2c1.8-0.1,3.5-0.3,5-0.6c-0.3-0.4-0.6-0.9-1.1-1.4l1.5-1
			c1.5,1.8,2.5,3.3,3.2,4.5l-1.6,1.1c-0.4-0.7-0.7-1.3-1-1.7l-1.1,0.2v7.5c0,0.3,0.3,0.5,1,0.5c0.5,0,0.8-0.2,0.9-0.6
			s0.2-1.5,0.3-3.3L239.3,441.6z"/>
		<path class="st7" d="M255.6,438.9c0,4.7-2.1,7-6.4,7c-4.1,0-6.1-2.3-6.1-6.8v-9.7h2.5v9.7c0,3.1,1.3,4.6,3.8,4.6
			c2.5,0,3.7-1.5,3.7-4.5v-9.8h2.5V438.9z"/>
		<path class="st7" d="M264.4,431.4h-1.5v12.1h1.5v2.1h-5.5v-2.1h1.5v-12.1h-1.5v-2.1h5.5V431.4z"/>
		<path class="st7" d="M275.1,439.6l-1.3,1.4c-0.2-0.5-0.8-1.3-1.7-2.5v8.1h-1.9v-7.1c-1,0.9-1.6,1.5-2,1.7l-1-1.7
			c2.4-1.7,4.1-3.7,5.1-6.2h-4.8v-1.9h6.8v1.9c-0.4,0.9-0.9,2-1.7,3.2C273.9,438,274.7,439,275.1,439.6z M272.6,430.5l-1.7,0.8
			c-0.5-0.9-1.1-1.7-1.9-2.6l1.7-0.9C271.6,428.9,272.3,429.8,272.6,430.5z M286.2,442.7c-0.1,1.7-0.3,2.7-0.7,3.2
			c-0.4,0.4-1.2,0.7-2.5,0.7c-1.6,0-2.4-0.6-2.4-1.8v-4.3h-1.4c-0.4,3.2-2,5.4-4.9,6.5l-1.1-1.6c2.6-1.1,4-2.7,4.1-4.9h-2v-11.9h9.3
			v11.9h-2.3v3.8c0,0.4,0.3,0.6,0.8,0.6c0.4,0,0.6-0.1,0.8-0.3c0.1-0.2,0.3-1.1,0.4-2.5L286.2,442.7z M282.9,431.9v-1.7h-5.6v1.7
			H282.9z M282.9,435.3v-1.8h-5.6v1.8H282.9z M282.9,438.8v-1.8h-5.6v1.8H282.9z"/>
		<path class="st7" d="M308.3,443.7c0,0.7-0.1,1.3-0.3,1.7c-0.2,0.4-0.4,0.7-0.8,0.9c-0.3,0.2-0.6,0.3-0.8,0.3c-0.2,0-0.9,0-2.2,0
			c-1.6,0-2.6,0-2.9-0.1c-0.3-0.1-0.6-0.2-0.9-0.5s-0.4-0.6-0.4-1.1v-1h-2c-0.6,1-1.5,1.7-2.6,2.1s-2.9,0.6-5.3,0.7l-0.6-1.7
			c3.3,0.1,5.5-0.3,6.4-1.2h-2.9v-6.6h11.9v6.6h-3v0.6c0,0.2,0.1,0.4,0.3,0.5c0.2,0.1,0.9,0.1,2.2,0.1c1,0,1.6,0,1.8-0.1
			c0.2-0.1,0.3-0.3,0.4-0.6c0.1-0.3,0.1-0.8,0.1-1.3L308.3,443.7z M308.1,439h-1.8v-2.4h-14.6v2.4h-1.9v-3.9h1.4l-0.4-6.3
			c1.6-0.1,3.1-0.5,4.4-1l0.7,1.3c-1,0.4-2.1,0.7-3.2,0.8l0.1,0.8h2.8v1.2h-2.7l0,0.9h2.7v1.2H293l0.1,1h11.9l0.1-1h-2.7v-1.2h2.7
			l0.1-0.9h-2.8v-1.2h2.8l0.1-0.9h-3.1v-1.4h5l-0.4,6.7h1.4V439z M303.1,439.4v-0.8h-8.2v0.8H303.1z M303.1,441.1v-0.8h-8.2v0.8
			H303.1z M303.1,442.8V442h-8.2v0.9H303.1z M301.6,432.3c-0.4,0.3-0.8,0.6-1.3,0.9c0.5,0.3,0.9,0.6,1.2,0.9l-1,0.9
			c-0.7-0.5-1.2-0.9-1.6-1c-1.4,0.6-2.1,0.8-2.2,0.9l-0.8-1.1c0.5-0.1,1-0.3,1.5-0.6c-0.4-0.2-0.8-0.4-1.3-0.6l0.9-0.9
			c0.8,0.3,1.4,0.6,1.8,0.8c0.6-0.3,1.2-0.7,1.7-1.1L301.6,432.3z M301.7,428.6c-0.3,0.3-0.8,0.6-1.3,1c0.4,0.3,0.8,0.5,1,0.6
			l-0.8,0.9c-0.6-0.4-1.1-0.7-1.4-0.8c-0.8,0.4-1.6,0.8-2.4,1.1l-0.8-1.1c0.6-0.2,1.1-0.4,1.7-0.7c-0.4-0.2-0.8-0.4-1.3-0.6l0.8-1
			c0.8,0.3,1.4,0.6,1.9,0.8c0.6-0.3,1.1-0.7,1.6-1.1L301.7,428.6z"/>
		<path class="st7" d="M319.4,431.7h-7.5V430h2.9c-0.2-0.6-0.5-1.1-0.7-1.6l1.9-0.5c0.3,0.5,0.6,1.2,0.9,2.1h2.6V431.7z
			 M318.9,434.8h-6.4v-1.7h6.4V434.8z M318.9,437.9h-6.4v-1.7h6.4V437.9z M318.9,446.2H317v-0.9h-2.7v1.4h-1.8v-7.4h6.4V446.2z
			 M317,443.6V441h-2.7v2.5H317z M330.8,445.1l-0.9,1.7c-1.8-0.6-3.4-1.4-4.9-2.5c-1.3,1-2.9,1.9-4.7,2.6l-1.1-1.6
			c1.8-0.5,3.2-1.3,4.4-2.2c-1.2-1.3-2.1-2.8-2.7-4.4h-1v-1.8h9.3v1.8c-0.9,1.9-1.9,3.3-2.9,4.4
			C327.6,443.9,329.1,444.6,330.8,445.1z M330.6,435.6h-2.8c-0.4,0-0.7-0.2-1-0.5c-0.3-0.3-0.4-0.6-0.4-1v-3.8h-2.9v1.3
			c0,0.9-0.2,1.9-0.7,2.7c-0.5,0.9-1.1,1.6-1.9,2l-1.3-1.3c1.4-0.7,2.1-2.1,2.1-4v-2.5h6.7v4.8c0,0.1,0.1,0.3,0.2,0.4
			c0.1,0.1,0.3,0.2,0.4,0.2h1.7V435.6z M327.3,438.6H323c0.6,1.3,1.3,2.4,2.1,3.2C325.9,441,326.6,440,327.3,438.6z"/>
		<path class="st7" d="M343.1,431.6h-8.9v-1.7h3.5c-0.2-0.6-0.5-1.2-0.8-1.7l2-0.4c0.3,0.6,0.6,1.3,0.9,2.1h3.2V431.6z M342.1,434.8
			H335v-1.7h7.1V434.8z M342.1,437.9H335v-1.7h7.1V437.9z M342.2,446.4h-1.9v-1.1H337v1.4h-1.9v-7.4h7.1V446.4z M340.3,443.6v-2.6
			H337v2.6H340.3z M352.9,435.8H349v10.9h-1.9v-10.9h-3.8v-1.9h3.8V428h1.9v5.9h3.9V435.8z"/>
		<path class="st7" d="M375.2,445.1l-1,1.6c-2.1-0.9-3.9-1.9-5.4-3c-1.8,1.2-4,2.3-6.7,3.1l-1.1-1.7c2.4-0.7,4.5-1.7,6.2-2.7
			c-1.4-1.4-2.7-3.3-4-5.7c-0.8,3.9-2.6,7.2-5.5,9.8l-1.3-1.5c1.8-1.7,3.1-3.5,3.9-5.4s1.3-5,1.6-9.5H358v-1.9h14.4
			c-0.5,2-1.1,3.9-1.8,5.8h3.4c-0.6,3.7-1.9,6.5-3.8,8.4C371.6,443.5,373.2,444.3,375.2,445.1z M369.9,430.1H364
			c0,1.4-0.2,2.7-0.3,3.9h4.9C369,432.9,369.4,431.6,369.9,430.1z M371.7,435.9h-6.8c1.2,2.3,2.5,4.1,3.9,5.4
			C370.1,440,371.1,438.2,371.7,435.9z"/>
		<path class="st7" d="M384.1,428.1c-0.2,0.6-0.4,1.5-0.7,2.5l-0.5,1.5v14.6h-1.9v-10.4c-0.3,0.6-0.7,1.1-1.2,1.8l-1-2.1
			c1.3-1.9,2.5-4.6,3.5-8.1L384.1,428.1z M397.8,444.9l-0.8,1.8c-3.3-0.3-5.9-1-7.9-2.1c-1.4,1.1-2.9,1.9-4.7,2.3l-0.9-1.7
			c1.7-0.5,3-1,4-1.7c-0.9-0.8-1.6-1.6-2.3-2.5l1.6-0.9c0.5,0.8,1.2,1.4,1.9,2.1c0.5-0.6,0.7-1.5,0.8-2.6h-4.9v-5.9h5v-1.8H384V430
			h5.7v-2h1.9v2h5.7v1.8h-5.7v1.8h5v5.9h-5c-0.1,1.4-0.5,2.6-1.1,3.6C392.6,444.1,395.1,444.7,397.8,444.9z M389.7,435.3h-3v2.4h3
			C389.7,436.9,389.7,436.1,389.7,435.3z M394.6,437.7v-2.4h-3c0,0.8,0,1.6,0,2.4H394.6z"/>
		<path class="st7" d="M418.8,444.5c0,0.6-0.2,1.1-0.6,1.5c-0.4,0.4-1.2,0.6-2.2,0.6c-0.5,0-1,0-1.5-0.1l-0.6-1.9l2.4,0
			c0.2,0,0.3-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5v-3.9h-5.3v6h-1.8v-6h-5.2c-0.2,2.8-0.9,5-1.9,6.6l-1.7-1.1
			c0.6-0.9,1.1-2.1,1.4-3.5c0.3-1.5,0.5-2.9,0.5-4.2v-9.5h15.9V444.5z M409.9,433.4v-3.1h-5v3.1H409.9z M409.9,438.4v-3.2h-5v3.2
			H409.9z M417,433.4v-3.1h-5.3v3.1H417z M417,438.4v-3.2h-5.3v3.2H417z"/>
		<path class="st7" d="M442.2,435.6h-6.7c-1,0.8-1.9,1.4-2.7,1.9h7.3v9.2H438v-0.9h-8.3v0.9h-2V440c-1.1,0.5-2.3,0.9-3.5,1.3
			l-0.9-1.7c4-1.2,7-2.5,9-3.9h-8.9v-1.8h7.8v-2.1h-5.9v-1.7h5.9v-2h2v2h3.9v1.6c1-1,1.8-2,2.5-3l1.5,1.2c-1.1,1.5-2.3,2.8-3.6,4.1
			h4.6V435.6z M438,440.8v-1.5h-8.3v1.5H438z M438,444v-1.6h-8.3v1.6H438z M437.1,431.7h-3.8v2.1h1.5
			C435.7,433,436.4,432.3,437.1,431.7z"/>
		<path class="st7" d="M448.3,441.8c-0.2,1.6-0.5,3.3-1,5l-1.6-0.8c0.6-1.9,0.9-3.4,1-4.6L448.3,441.8z M453.3,431.4
			c-1.3,2.8-2.9,5.3-4.7,7.3c0.8-0.1,1.6-0.3,2.4-0.6c-0.1-0.4-0.2-0.7-0.4-1l1.5-0.6c0.5,1.1,0.9,2.4,1.1,3.7l-1.5,0.5
			c-0.1-0.3-0.1-0.6-0.2-0.9c-1.9,0.5-3.6,0.8-5.2,1.1l-0.5-1.6c1.2-1.1,2.4-2.6,3.6-4.5c-1,0.2-2,0.4-3,0.5l-0.5-1.7
			c1.3-1.5,2.4-3.5,3.2-5.9l1.8,0.7c-0.8,1.9-1.6,3.5-2.6,4.8c0.6-0.1,1.3-0.2,2.3-0.5c0.3-0.5,0.7-1.2,1.1-2.2L453.3,431.4z
			 M450.9,445.7l-1.7,0.4c0-0.9-0.1-2.3-0.4-4.2l1.5-0.4C450.7,443,450.8,444.4,450.9,445.7z M453,443.8l-1.5,0.4
			c-0.2-1.2-0.4-2-0.6-2.6l1.4-0.4C452.7,441.9,452.9,442.8,453,443.8z M457,438.1l-1.6,0.7c-0.7-1.3-1.5-2.7-2.6-4.2
			c1-1.3,1.7-2.6,2.3-3.8l1.7,0.7c-0.4,0.9-1,1.9-1.9,3.2C456,436.2,456.6,437.4,457,438.1z M464.5,446.3h-11.5v-1.8h4.8v-3.3h-4.2
			v-1.8h10.2v1.8h-4.1v3.3h4.7V446.3z M464.2,430.1h-10.8v-1.8h10.8V430.1z M460.7,438.1l-1.7,0.8c-0.7-1.5-1.6-3-2.6-4.3
			c0.8-1,1.6-2.3,2.3-3.8l1.8,0.7c-0.6,1.2-1.3,2.2-1.9,3.1C459.7,436.3,460.4,437.5,460.7,438.1z M464.3,438.1l-1.7,0.8
			c-0.7-1.4-1.5-2.7-2.5-4.2c1-1.4,1.7-2.7,2.3-3.9l1.6,0.7c-0.3,0.7-0.9,1.8-1.8,3.2C463.1,435.9,463.8,437,464.3,438.1z"/>
		<path class="st7" d="M469.5,439.9c0,1.7-0.2,3.5-0.6,5.3l-0.9-0.3c0.4-1.6,0.6-3.3,0.6-5.1L469.5,439.9z M475.7,430h-2.1v1.3h1.5
			v1.5h-1.5v1.4h1.5v1.5h-1.5v1.5h1.9c0,4.6-0.1,7.3-0.4,8.1c-0.3,0.8-1,1.2-2.3,1.2c-0.2,0-0.4,0-0.8,0l-0.6-1.7l1.8,0
			c0.2,0,0.3-0.1,0.4-0.3s0.2-2.1,0.3-5.6h-5v-10.6h6.8V430z M470.9,444.6l-1,0.2c0-0.7,0.1-1.3,0.1-2.1c0-0.9,0-1.8-0.1-2.8
			l0.9-0.1c0.1,1.1,0.2,2.2,0.2,3.4C470.9,443.7,470.9,444.2,470.9,444.6z M472,431.3V430h-1.4v1.3H472z M472,434.3v-1.4h-1.4v1.4
			H472z M472,437.3v-1.5h-1.4v1.5H472z M472.3,443.8l-0.9,0.2c0-1.5-0.1-2.9-0.3-4.1l0.8-0.1C472.2,440.8,472.3,442.2,472.3,443.8z
			 M473.5,443l-0.8,0.3c0-1.1-0.1-2.2-0.4-3.6l0.7-0.2C473.3,440.5,473.4,441.6,473.5,443z M481,444.2l-1.2,1.3
			c-0.4-0.5-0.9-1-1.2-1.3c-0.5,1-1.1,1.8-1.7,2.5l-1.6-1c1.1-1.2,2-2.8,2.7-4.9l1.8,0.4c-0.1,0.5-0.3,0.9-0.5,1.3
			C480,443.2,480.6,443.7,481,444.2z M487.1,432.7l-0.8,1.7c-0.9-0.5-1.6-1-2.3-1.5v1.1h-5.7v-1.1c-0.9,0.7-1.5,1.2-2,1.5l-0.9-1.8
			c2.5-1.5,4.2-3.2,5.2-4.9l1.9,0.4c-0.1,0.1-0.2,0.3-0.3,0.5C483.3,430,485,431.4,487.1,432.7z M480.6,440.5h-4.3V435h4.3V440.5z
			 M479.1,438.8v-2.2h-1.3v2.2H479.1z M483.3,432.4c-0.7-0.6-1.5-1.4-2.2-2.3c-0.7,0.8-1.4,1.6-2.3,2.3H483.3z M487,445.3l-1.3,1.4
			c-0.5-0.7-1.2-1.4-2.1-2.1c-0.5,0.8-1,1.5-1.6,2.3l-1.7-1c1.2-1.4,2.2-3,2.8-5l1.8,0.3c-0.1,0.5-0.3,1-0.6,1.7
			C485.5,443.7,486.3,444.5,487,445.3z M486,440.5h-4.4V435h4.4V440.5z M484.4,438.8v-2.2h-1.3v2.2H484.4z"/>
		<path class="st7" d="M503.4,438.9c0,4.7-2.1,7-6.4,7c-4.1,0-6.1-2.3-6.1-6.8v-9.7h2.5v9.7c0,3.1,1.3,4.6,3.8,4.6
			c2.5,0,3.7-1.5,3.7-4.5v-9.8h2.5V438.9z"/>
		<path class="st7" d="M520,445.6h-3l-3.4-5.9c-0.1-0.2-0.2-0.5-0.4-0.9h-0.1c-0.1,0.2-0.2,0.5-0.4,0.9l-3.5,5.9h-3l5.3-8.1
			l-4.9-8.1h3.1l3,5.4c0.2,0.4,0.4,0.8,0.5,1.2h0c0.1-0.1,0.3-0.6,0.6-1.3l3.1-5.4h2.8l-5,8L520,445.6z"/>
		<path class="st7" d="M530.8,437.3h-3.1c-0.1,3.5-1.3,6.6-3.5,9.4l-1.5-1.4c1.9-2.2,3-4.9,3.1-8h-3.2v-1.7h3.3v-3h-2.8v-1.8h2.8
			v-2.9h1.9v2.9h2.6v1.8h-2.6v3h3V437.3z M530.9,442.9l-1.5,1.3c-0.5-1.2-1.2-2.4-2-3.5l1.4-1.2
			C529.8,440.8,530.5,441.9,530.9,442.9z M541.5,442.4c0,1.2-0.1,2.1-0.3,2.7c-0.2,0.6-0.4,1-0.8,1.1s-1.2,0.2-2.5,0.2
			c-1.1,0-1.7-0.6-1.7-1.7v-4.4H535c0,2.7-1.3,4.8-3.7,6.5l-1.4-1.5c1-0.5,1.8-1.2,2.4-2.1c0.6-0.9,0.9-1.8,0.9-2.8h-1.8v-11.9h9.1
			v11.9h-2.3v3.8c0,0.3,0.2,0.5,0.6,0.5c0.3,0,0.5-0.1,0.6-0.2c0.1-0.2,0.2-0.6,0.2-1.4c0-0.8,0-1.2,0-1.3L541.5,442.4z
			 M538.6,431.8v-1.7h-5.3v1.7H538.6z M538.6,435.1v-1.6h-5.3v1.6H538.6z M538.6,438.6v-1.7h-5.3v1.7H538.6z"/>
		<path class="st7" d="M557.6,432.2h-1.4v2H552v0.9h4.5v1.2H552v1h5v1.2h-11.9v-1.2h5.1v-1h-4.5v-1.3h4.5v-0.8h-4.1v-1.1h4.1v-0.9
			h-5.3V431h5.3v-0.9h-4.1v-1.2h4.1v-1h1.8v1h4.2v2h1.4V432.2z M557.3,446.4h-12.4V445h12.4V446.4z M556.3,444.1h-10.3v-4.7h10.3
			V444.1z M550.3,441.2v-0.7h-2.5v0.7H550.3z M550.2,443v-0.7h-2.5v0.7H550.2z M554.5,441.2v-0.7H552v0.7H554.5z M554.5,443v-0.7
			H552v0.7H554.5z M554.4,431v-0.9H552v0.9H554.4z M554.4,433.1v-0.9H552v0.9H554.4z M559.9,442.7h-1.8v-13.3h1.8V442.7z
			 M563.3,444.9c0,0.6-0.2,1.1-0.6,1.4c-0.4,0.3-1.6,0.4-3.5,0.4l-0.4-1.9c0.6,0,1.1,0.1,1.6,0.1c0.5,0,0.8,0,0.9-0.1
			c0.1-0.1,0.2-0.2,0.2-0.4v-16.4h1.8V444.9z"/>
	</g>
</g>
<path class="st6" d="M845.4,530.1H469.9c-6.3,0-11.6-5.6-11.6-12.2v-22.3c0-6.7,5.2-12.2,11.6-12.2h375.5c6.3,0,11.6,5.6,11.6,12.2
	v22.3C857,524.6,851.8,530.1,845.4,530.1z"/>
<g>
	<g class="st4">
		<path class="st7" d="M497.4,512.3c0,0.6-0.1,1.4-0.3,2.2c-0.2,0.8-0.5,1.3-0.9,1.6s-0.7,0.3-1,0.4c-0.2,0-1,0-2.3,0
			s-2.1,0-2.5-0.1c-0.3-0.1-0.6-0.2-0.9-0.5s-0.4-0.8-0.4-1.4v-5.1h-2.8c-0.2,4.1-2.5,6.6-7,7.5l-0.9-1.8c1.6-0.3,3-0.8,4.1-1.7
			c1.1-0.8,1.7-2.2,1.8-4h-5.8v-1.8h18.7v1.8h-6.2v4.9c0,0.1,0,0.2,0.1,0.3s0.2,0.1,0.3,0.1h3c0.2,0,0.4-0.1,0.5-0.3
			s0.3-0.5,0.3-0.7l0.2-1.9L497.4,512.3z M496.8,505.2h-1.9v-3.5h-13.7v3.5h-1.9V500h7.6c-0.1-0.4-0.4-1-0.8-1.7l2.1-0.6
			c0.4,0.7,0.7,1.5,0.9,2.3h7.7V505.2z M494,505.4h-11.8v-1.8H494V505.4z"/>
		<path class="st7" d="M511.1,500.4H507v0.8h3.4v3.9H507v0.2c1.3,0.4,2.4,0.8,3.4,1.3l-0.8,1.4c-1-0.5-1.9-0.9-2.7-1.2v2.2h-1.7
			v-2.4c-1.1,1.1-2.2,1.9-3.4,2.4l-1-1.4c1.4-0.6,2.6-1.5,3.7-2.6h-2.8v-3.9h3.5v-0.8H501v-1.5h4.3V498h1.7v0.9h4.1V500.4z
			 M519.8,516.3h-18.7v-1.6h3.3V512h1.9v2.7h3.2v-3.6h-8.1v-1.5h18v1.5h-8v1.1h7.1v1.5h-7.1v1.1h8.4V516.3z M505.3,503.7v-1.1h-1.7
			v1.1H505.3z M508.7,503.7v-1.1H507v1.1H508.7z M519.9,507.6l-0.9,1.5c-1.4-0.4-2.7-1-3.8-1.9c-1.2,0.8-2.6,1.4-4.1,1.8l-0.9-1.5
			c1.3-0.3,2.6-0.8,3.7-1.5c-0.5-0.7-0.9-1.4-1.1-1.9l1.5-0.7c0.2,0.5,0.5,1,1,1.6c0.7-0.7,1.2-1.8,1.4-3.3h-2.6
			c-0.7,1.2-1.5,2.3-2.4,3.1l-1.1-1.3c1.4-1.5,2.5-3.4,3.3-5.6l1.7,0.5c-0.2,0.6-0.4,1.2-0.7,1.8h4.7v1.6h-1.2
			c-0.2,1.8-0.9,3.3-1.9,4.4C517.4,506.7,518.5,507.2,519.9,507.6z"/>
		<path class="st7" d="M530.4,512.4c-1.8,1.4-3.8,2.5-5.9,3.3l-1.1-1.5c2.1-0.8,3.9-1.8,5.6-3L530.4,512.4z M541.8,510.4l-1.6,1.1
			c-0.5-0.7-0.9-1.2-1.2-1.6c-2,0.3-3.8,0.4-5.3,0.5v4.4c0,0.6-0.1,1-0.5,1.4c-0.4,0.3-1.5,0.5-3.4,0.5l-0.8-1.9
			c0.8,0.1,1.5,0.1,2.2,0.1c0.4,0,0.6-0.2,0.6-0.6v-3.7c-2.9,0.1-5.1,0.2-6.5,0.3l-0.5-1.6c1.8-0.5,3.9-1.4,6.3-2.7
			c-2.5,0.1-4.3,0.2-5.3,0.2l-0.3-1.4c2.9-0.9,5.6-2.4,7.9-4.5l1.8,1c-1.4,1.3-3.1,2.3-5.2,3.2l1.7-0.1l2.6-0.2
			c0.1,0,0.6-0.3,1.4-0.9l1.5-1.1l1.7,1.2c-3.4,2.4-6.5,4.1-9.5,5.1c2.5-0.1,5.3-0.3,8.3-0.7c-0.4-0.4-0.8-0.8-1.3-1.2l1.7-1
			C539.7,507.7,541,509.1,541.8,510.4z M535.3,498.9c-2.1,1.7-5.2,3.1-9,4.2l-1.1-1.6c3.4-0.7,6.3-1.9,8.5-3.6L535.3,498.9z
			 M542.3,514.5l-1.3,1.4c-1.4-1.2-3.3-2.4-5.6-3.5l1.3-1.3C539,512.2,540.9,513.3,542.3,514.5z"/>
		<path class="st7" d="M548.4,511.7c-0.3,2.1-0.7,3.8-1.4,5.2l-1.6-0.7c0.6-1.2,1.1-2.8,1.4-4.7L548.4,511.7z M553.6,510.3l-1.7,0.6
			c0-0.1-0.1-0.5-0.2-1.1c-1.9,0.6-3.8,1-5.7,1.3l-0.3-1.5c1.2-1,2.5-2.5,3.9-4.4c-1.1,0.2-2.2,0.3-3.3,0.4l-0.2-1.3
			c1.6-1.8,2.9-3.9,3.8-6.4l1.8,0.6c-0.9,2.1-1.9,3.8-3.1,5.3c0.7-0.1,1.4-0.1,2.1-0.3c0.4-0.6,0.8-1.3,1-1.9l1.7,0.9
			c-1.5,2.7-3,4.8-4.5,6.4c0.9-0.2,1.7-0.4,2.5-0.7c-0.1-0.4-0.3-0.7-0.3-0.9l1.5-0.6C552.9,507.9,553.4,509.1,553.6,510.3z
			 M551.2,516l-1.6,0.4c0-1.4-0.1-2.9-0.5-4.6l1.5-0.4C550.9,513.1,551.1,514.6,551.2,516z M553.7,514.2l-1.4,0.6
			c-0.2-1.2-0.5-2.2-0.8-3l1.4-0.5C553.2,512,553.5,513,553.7,514.2z M564.6,511.7c-0.1,2.2-0.3,3.5-0.4,3.7
			c-0.1,0.2-0.3,0.5-0.6,0.8c-0.3,0.3-1,0.4-2.2,0.4c-1,0-1.6-0.1-1.9-0.4c-0.3-0.3-0.5-0.9-0.5-1.8v-7.2c-0.7,0.1-1.2,0.2-1.6,0.2
			c0,1.5-0.1,2.9-0.2,3.9c-0.1,1.1-0.5,2.1-1.1,3.1c-0.6,1-1.3,1.9-2.3,2.6l-1.4-1.3c0.6-0.4,1.2-1,1.7-1.8c0.5-0.7,0.9-1.6,1.1-2.4
			c0.2-0.9,0.3-2.2,0.3-3.9l-1.9,0.2l-0.3-1.4c1.2-1.5,2.2-3,3-4.7h-3.2V500h3.9c0.2-0.4,0.4-1.1,0.7-2l2,0.3
			c-0.2,0.7-0.5,1.2-0.6,1.7h5.3v1.8h-6.1c-0.6,1.4-1.4,2.7-2.5,4.2c1.8-0.1,3.5-0.3,5-0.6c-0.3-0.4-0.6-0.9-1.1-1.4l1.5-1
			c1.5,1.8,2.5,3.3,3.2,4.5l-1.6,1.1c-0.4-0.7-0.7-1.3-1-1.7l-1.1,0.2v7.5c0,0.3,0.3,0.5,1,0.5c0.5,0,0.8-0.2,0.9-0.6
			s0.2-1.5,0.3-3.3L564.6,511.7z"/>
		<path class="st7" d="M576.2,511.7c-2.7,1.4-5.3,2.5-7.8,3.2l-0.4-1.8c1.2-0.4,2.2-0.7,3.2-1.1V502h-2.7v-1.8h7.2v1.8H573v9.2
			c0.9-0.3,1.8-0.8,2.9-1.3L576.2,511.7z M586.3,502c0,3.5-0.2,6.5-0.3,9c-0.2,2.6-0.4,4.1-0.6,4.5s-0.5,0.8-1,1
			c-0.4,0.2-1.2,0.3-2.3,0.3c-0.6,0-1.1,0-1.4-0.1l-0.7-1.9c0.7,0.1,1.4,0.1,2,0.1c0.7,0,1.1,0,1.3-0.1s0.3-0.1,0.3-0.3
			c0.1-0.1,0.2-0.8,0.3-2s0.2-2.6,0.3-4.2c0.1-1.5,0.1-3,0.2-4.5h-3.4c-0.2,3.9-0.8,6.7-1.9,8.5s-2.3,3.3-3.7,4.6l-1.4-1.4
			c1.5-1.3,2.7-2.7,3.6-4.5s1.4-4.1,1.5-7.2h-2.9V502h3l0.1-4h1.9c0,0.7,0,2-0.1,4H586.3z"/>
		<path class="st7" d="M599.3,515.1c0,0.4-0.1,0.8-0.3,1c-0.2,0.3-0.4,0.4-0.7,0.5c-0.3,0.1-0.8,0.1-1.4,0.1c-0.3,0-0.7,0-1.3,0
			l-0.5-1.8c0.3,0,0.7,0,1.1,0c0.6,0,0.9,0,1-0.1c0.1-0.1,0.2-0.2,0.2-0.3v-2.3c-2.5,1.1-3.8,1.6-3.9,1.6L593,513
			c-0.2,1.5-0.6,2.8-1.2,3.8l-1.5-1.3c0.7-1.2,1-3.1,1-5.8v-4.6h8V515.1z M600.7,503.3l-1.5,1c-0.3-0.5-0.5-0.9-0.8-1.1
			c-2.3,0.4-4.8,0.7-7.5,0.9l-0.5-1.5c1.5-1.4,2.7-3,3.5-4.7l1.8,0.6c-0.9,1.6-1.8,2.8-2.7,3.7c1.1-0.1,2.5-0.2,4.1-0.5
			c-0.5-0.6-1-1.2-1.5-1.6l1.6-1.1C598.7,500.4,599.8,501.8,600.7,503.3z M597.5,510.4v-3.5h-4.2v1.2c1.4,0.2,2.6,0.4,3.7,0.8
			l-0.6,1.6c-0.6-0.2-1.6-0.4-3.1-0.7c0,0.8,0,1.6-0.1,2.4C594,512,595.5,511.4,597.5,510.4z M609.1,513c0,1.4-0.2,2.3-0.5,2.6
			s-0.5,0.6-0.8,0.8c-0.3,0.2-1.3,0.2-3.2,0.2c-1.4,0-2.3-0.1-2.6-0.2c-0.3-0.1-0.6-0.4-0.8-0.7c-0.2-0.3-0.3-0.7-0.3-1.1v-7.5h1.9
			v2.8h5.6v1.7h-5.6v2.8c0,0.3,0.1,0.4,0.5,0.4h3.2c0.3,0,0.5-0.1,0.6-0.4s0.2-0.9,0.3-2.1L609.1,513z M608.9,506.4h-5.9
			c-0.6,0-1-0.2-1.3-0.5c-0.3-0.4-0.5-0.8-0.5-1.4v-6.4h1.9v2.4h5.6v1.7h-5.6v2.2c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.1,0.3,0.1
			h5.5V506.4z"/>
		<path class="st7" d="M622.7,500.4h-4.1v0.8h3.4v3.9h-3.4v0.2c1.3,0.4,2.4,0.8,3.4,1.3l-0.8,1.4c-1-0.5-1.9-0.9-2.7-1.2v2.2h-1.7
			v-2.4c-1.1,1.1-2.2,1.9-3.4,2.4l-1-1.4c1.4-0.6,2.6-1.5,3.7-2.6h-2.8v-3.9h3.5v-0.8h-4.3v-1.5h4.3V498h1.7v0.9h4.1V500.4z
			 M631.4,516.3h-18.7v-1.6h3.3V512h1.9v2.7h3.2v-3.6H613v-1.5h18v1.5h-8v1.1h7.1v1.5H623v1.1h8.4V516.3z M616.9,503.7v-1.1h-1.7
			v1.1H616.9z M620.3,503.7v-1.1h-1.8v1.1H620.3z M631.5,507.6l-0.9,1.5c-1.4-0.4-2.7-1-3.8-1.9c-1.2,0.8-2.6,1.4-4.1,1.8l-0.9-1.5
			c1.3-0.3,2.6-0.8,3.7-1.5c-0.5-0.7-0.9-1.4-1.1-1.9l1.5-0.7c0.2,0.5,0.5,1,1,1.6c0.7-0.7,1.2-1.8,1.4-3.3h-2.6
			c-0.7,1.2-1.5,2.3-2.4,3.1l-1.1-1.3c1.4-1.5,2.5-3.4,3.3-5.6l1.7,0.5c-0.2,0.6-0.4,1.2-0.7,1.8h4.7v1.6h-1.2
			c-0.2,1.8-0.9,3.3-1.9,4.4C629,506.7,630.1,507.2,631.5,507.6z"/>
		<path class="st7" d="M654.1,504l-1.1,1.6c-3.4-1.6-6.2-3.3-8.6-5.3c-2.1,1.7-4.9,3.5-8.2,5.2l-1.3-1.6c4-1.8,7.1-3.9,9.2-6.1
			l2,0.7c-0.1,0.1-0.2,0.3-0.3,0.4C647.8,500.7,650.6,502.4,654.1,504z M651.4,516.8h-2v-1.1h-10v1.1h-2v-8.7h14.1V516.8z
			 M649.4,513.8v-3.9h-10v3.9H649.4z M649.4,505.8h-9.8V504h9.8V505.8z"/>
		<path class="st7" d="M661.6,509.8c-0.6,2.2-1.5,4.6-2.7,7.1l-1.6-1.2c1.1-2.3,1.9-4.7,2.6-7.1L661.6,509.8z M661.8,506l-1.2,1.5
			c-0.8-0.8-1.9-1.7-3.3-2.6l1.2-1.5C659.7,504.2,660.8,505,661.8,506z M661.9,500.9l-1.3,1.5c-0.8-1.1-1.9-2.1-3.1-3.1l1.4-1.5
			C659.9,498.7,660.9,499.7,661.9,500.9z M669.1,511.8h-4.9l1.4,0.8c-0.8,1.7-1.8,3.1-2.9,4.4l-1.5-1.1c1.2-1.3,2.1-2.7,2.8-4.1
			h-1.5v-13.2h6.6V511.8z M667.2,502.4v-2.1h-3v2.1H667.2z M667.2,506.3v-2.2h-3v2.2H667.2z M667.2,510v-2.1h-3v2.1H667.2z
			 M669.7,515.8l-1.7,0.7c-0.3-1-1-2.1-1.8-3.5l1.7-0.8C668.7,513.4,669.3,514.6,669.7,515.8z M672.3,512.9h-1.8v-13.3h1.8V512.9z
			 M675.6,514.7c0,0.8-0.2,1.4-0.7,1.7c-0.5,0.3-1.2,0.4-2.3,0.4c-0.3,0-0.8,0-1.4,0l-0.5-1.8c1.6,0,2.5,0,2.7-0.1
			c0.2-0.1,0.3-0.3,0.3-0.6v-16.3h1.8V514.7z"/>
		<path class="st7" d="M686.5,501.7h-6.9V500h2.6c-0.1-0.3-0.3-0.9-0.7-1.6l1.9-0.5c0.3,0.6,0.6,1.3,0.9,2h2.2V501.7z M686.1,505
			h-6.1v-1.7h6.1V505z M686.1,508h-6.1v-1.7h6.1V508z M686.2,516.4h-1.9v-1h-2.1v1.4h-1.9v-7.4h6V516.4z M684.3,513.7v-2.5h-2.1v2.5
			H684.3z M693,513.4c-2.2,1-4.2,1.8-6.1,2.3l-0.3-1.8c0.7-0.2,1.4-0.4,2.1-0.7v-6h-1.9v-1.7h5.4v1.7h-1.7v5.3
			c0.5-0.2,1.3-0.5,2.3-1L693,513.4z M698.6,512.5c-0.1,1.3-0.4,2.4-0.7,3.2s-0.9,1.2-1.5,1.2c-1.2,0-2-1.2-2.5-3.5
			c-0.5-2.4-0.7-5.7-0.8-9.9H687v-1.8h6l0-3.6h1.8c0,1.7,0,2.9,0.1,3.6h3.4v1.8h-3.4c0.2,4.1,0.4,7.1,0.7,8.8
			c0.3,1.7,0.6,2.6,0.7,2.6c0.1,0,0.2-0.2,0.3-0.7c0.1-0.5,0.3-1.3,0.4-2.4L698.6,512.5z M698.1,500.3l-1.3,0.9
			c-0.4-0.6-0.9-1.3-1.7-2.1l1.3-0.9C697.1,498.9,697.6,499.6,698.1,500.3z"/>
		<path class="st7" d="M720.7,515.2l-1,1.6c-2.1-0.9-3.9-1.9-5.4-3c-1.8,1.2-4,2.3-6.7,3.1l-1.1-1.7c2.4-0.7,4.5-1.7,6.2-2.7
			c-1.4-1.4-2.7-3.3-4-5.7c-0.8,3.9-2.6,7.2-5.5,9.8l-1.3-1.5c1.8-1.7,3.1-3.5,3.9-5.4s1.3-5,1.6-9.5h-3.9v-1.9h14.4
			c-0.5,2-1.1,3.9-1.8,5.8h3.4c-0.6,3.7-1.9,6.5-3.8,8.4C717,513.5,718.7,514.4,720.7,515.2z M715.3,500.2h-5.9
			c0,1.4-0.2,2.7-0.3,3.9h4.9C714.4,503,714.9,501.7,715.3,500.2z M717.2,506h-6.8c1.2,2.3,2.5,4.1,3.9,5.4
			C715.5,510,716.5,508.3,717.2,506z"/>
		<path class="st7" d="M729.6,498.2c-0.2,0.6-0.4,1.5-0.7,2.5l-0.5,1.5v14.6h-1.9v-10.4c-0.3,0.6-0.7,1.1-1.2,1.8l-1-2.1
			c1.3-1.9,2.5-4.6,3.5-8.1L729.6,498.2z M743.3,514.9l-0.8,1.8c-3.3-0.3-5.9-1-7.9-2.1c-1.4,1.1-2.9,1.9-4.7,2.3l-0.9-1.7
			c1.7-0.5,3-1,4-1.7c-0.9-0.8-1.6-1.6-2.3-2.5l1.6-0.9c0.5,0.8,1.2,1.4,1.9,2.1c0.5-0.6,0.7-1.5,0.8-2.6h-4.9v-5.9h5v-1.8h-5.7V500
			h5.7v-2h1.9v2h5.7v1.8H737v1.8h5v5.9h-5c-0.1,1.4-0.5,2.6-1.1,3.6C738,514.1,740.5,514.7,743.3,514.9z M735.1,505.3h-3v2.4h3
			C735.1,507,735.1,506.2,735.1,505.3z M740.1,507.8v-2.4h-3c0,0.8,0,1.6,0,2.4H740.1z"/>
		<path class="st7" d="M764.3,514.6c0,0.6-0.2,1.1-0.6,1.5s-1.2,0.6-2.2,0.6c-0.5,0-1,0-1.5-0.1l-0.6-1.9l2.4,0
			c0.2,0,0.3-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5v-3.9h-5.3v6h-1.8v-6h-5.2c-0.2,2.8-0.9,5-1.9,6.6l-1.7-1.1
			c0.6-0.9,1.1-2.1,1.4-3.5c0.3-1.5,0.5-2.9,0.5-4.2v-9.5h15.9V514.6z M755.3,503.5v-3.1h-5v3.1H755.3z M755.3,508.5v-3.2h-5v3.2
			H755.3z M762.4,503.5v-3.1h-5.3v3.1H762.4z M762.4,508.5v-3.2h-5.3v3.2H762.4z"/>
		<path class="st7" d="M787.6,505.7h-6.7c-1,0.8-1.9,1.4-2.7,1.9h7.3v9.2h-1.9v-0.9h-8.3v0.9h-2V510c-1.1,0.5-2.3,0.9-3.5,1.3
			l-0.9-1.7c4-1.2,7-2.5,9-3.9H769v-1.8h7.8v-2.1h-5.9V500h5.9v-2h2v2h3.9v1.6c1-1,1.8-2,2.4-3l1.5,1.2c-1.1,1.5-2.3,2.8-3.6,4.1
			h4.6V505.7z M783.5,510.8v-1.5h-8.3v1.5H783.5z M783.5,514.1v-1.6h-8.3v1.6H783.5z M782.5,501.7h-3.8v2.1h1.5
			C781.2,503.1,781.9,502.4,782.5,501.7z"/>
		<path class="st7" d="M795.5,509.8c-0.6,2.2-1.5,4.6-2.7,7.1l-1.6-1.2c1.1-2.3,1.9-4.7,2.6-7.1L795.5,509.8z M795.8,506l-1.2,1.5
			c-0.8-0.8-1.9-1.7-3.3-2.6l1.2-1.5C793.7,504.2,794.8,505,795.8,506z M795.9,500.9l-1.3,1.5c-0.8-1.1-1.9-2.1-3.1-3.1l1.4-1.5
			C793.9,498.7,794.9,499.7,795.9,500.9z M803,511.8h-4.9l1.4,0.8c-0.8,1.7-1.8,3.1-2.9,4.4l-1.5-1.1c1.2-1.3,2.1-2.7,2.8-4.1h-1.5
			v-13.2h6.6V511.8z M801.2,502.4v-2.1h-3v2.1H801.2z M801.2,506.3v-2.2h-3v2.2H801.2z M801.2,510v-2.1h-3v2.1H801.2z M803.7,515.8
			l-1.7,0.7c-0.3-1-1-2.1-1.8-3.5l1.7-0.8C802.6,513.4,803.2,514.6,803.7,515.8z M806.2,512.9h-1.8v-13.3h1.8V512.9z M809.6,514.7
			c0,0.8-0.2,1.4-0.7,1.7s-1.2,0.4-2.3,0.4c-0.3,0-0.8,0-1.4,0l-0.5-1.8c1.6,0,2.5,0,2.7-0.1s0.3-0.3,0.3-0.6v-16.3h1.8V514.7z"/>
		<path class="st7" d="M820.4,501.7h-6.9V500h2.6c-0.1-0.3-0.3-0.9-0.7-1.6l1.9-0.5c0.3,0.6,0.6,1.3,0.9,2h2.2V501.7z M820.1,505
			H814v-1.7h6.1V505z M820.1,508H814v-1.7h6.1V508z M820.1,516.4h-1.9v-1h-2.1v1.4h-1.9v-7.4h6V516.4z M818.2,513.7v-2.5h-2.1v2.5
			H818.2z M827,513.4c-2.2,1-4.2,1.8-6.1,2.3l-0.3-1.8c0.7-0.2,1.4-0.4,2.1-0.7v-6h-1.9v-1.7h5.4v1.7h-1.7v5.3
			c0.5-0.2,1.3-0.5,2.3-1L827,513.4z M832.5,512.5c-0.1,1.3-0.4,2.4-0.7,3.2c-0.4,0.8-0.9,1.2-1.5,1.2c-1.2,0-2-1.2-2.5-3.5
			c-0.5-2.4-0.7-5.7-0.8-9.9h-6.1v-1.8h6l0-3.6h1.8c0,1.7,0,2.9,0.1,3.6h3.4v1.8h-3.4c0.2,4.1,0.4,7.1,0.7,8.8
			c0.3,1.7,0.6,2.6,0.7,2.6c0.1,0,0.2-0.2,0.3-0.7s0.3-1.3,0.4-2.4L832.5,512.5z M832,500.3l-1.3,0.9c-0.4-0.6-0.9-1.3-1.7-2.1
			l1.3-0.9C831,498.9,831.6,499.6,832,500.3z"/>
	</g>
</g>
<polygon class="st7" points="312.3,188.5 303.4,199.3 294.5,188.5 "/>
<polygon class="st7" points="312.3,257.8 303.4,268.4 294.5,257.8 "/>
<polygon class="st7" points="312.3,327 303.4,337.6 294.5,327 "/>
<path class="st6" d="M774.6,670H549.7c-6.7,0-12.2-5.3-12.2-11.6v-21.2c0-6.4,5.6-11.6,12.2-11.6h224.9c6.7,0,12.2,5.3,12.2,11.6
	v21.2C786.9,664.7,781.3,670,774.6,670z"/>
<path class="st6" d="M774.6,736H549.7c-6.7,0-12.2-5.3-12.2-11.6v-21.2c0-6.4,5.6-11.6,12.2-11.6h224.9c6.7,0,12.2,5.3,12.2,11.6
	v21.2C786.9,730.7,781.3,736,774.6,736z"/>
<g>
	<g class="st4">
		<path class="st7" d="M639.5,649.3l-15.3-0.2l0,3.3c0,2.5-0.7,4.5-2,5.8l-1.5-1.3c0.5-0.4,0.9-1,1.2-1.8c0.3-0.8,0.5-2.1,0.5-3.9
			l0-3.7l17.1,0.2L639.5,649.3z M639.1,643.5l-3.2,0c-0.6,0.6-1.2,1-1.9,1.5c1.7,0.4,3.2,0.8,4.6,1.2l-0.7,1.2
			c-1.8-0.6-3.7-1.1-5.7-1.5c-2.7,0.9-5.4,1.3-8.2,1.2l-0.5-1.4c1.8,0,3.6-0.1,5.3-0.4c-1.2-0.2-2.6-0.4-4.2-0.5l0.9-1.2
			c2,0.2,4.1,0.5,6.4,0.9c0.7-0.4,1.2-0.7,1.7-1.1l-12-0.1l0-1.6l8,0.1c-0.2-0.3-0.4-0.7-0.6-1l2-0.4c0.3,0.4,0.6,0.9,0.8,1.4
			l7.5,0.1L639.1,643.5z M639.5,658l-15.4-0.2l0-1.5l6.9,0.1l0-1.3l-4.3,0l0-1.5l4.3,0l0-1.2l-3.8,0c-0.6,0.8-1.1,1.4-1.8,1.9
			l-1.2-1.4c1-0.8,1.8-1.8,2.4-3.2l1.8,0.5c-0.1,0.3-0.2,0.6-0.3,0.7l2.8,0l0-1.2l1.9,0l0,1.2l5.6,0.1l0,1.5l-5.6-0.1l0,1.2l5.1,0.1
			l0,1.5l-5.1-0.1l0,1.3l6.6,0.1L639.5,658z"/>
		<path class="st7" d="M651.5,658.5l-1.9,0l0-1l-4,0l0,1l-1.9,0l0.1-9.2l7.7,0.1L651.5,658.5z M649.6,655.8l0.1-4.8l-4,0l-0.1,4.8
			L649.6,655.8z M659.2,647.6l-13.2-0.1l0.1-6.4l13.2,0.1L659.2,647.6z M657.3,646l0-3.2l-9.3-0.1l0,3.2L657.3,646z M661.1,658.6
			l-1.9,0l0-1l-4,0l0,1l-1.9,0l0.1-9.2l7.8,0.1L661.1,658.6z M659.3,655.9l0.1-4.8l-4,0l-0.1,4.8L659.3,655.9z"/>
		<path class="st7" d="M666.8,652.3c0,1.7-0.2,3.3-0.7,5l-0.9-0.3c0.4-1.5,0.6-3.1,0.7-4.8L666.8,652.3z M673.1,642.9l-2.1,0l0,1.3
			l1.5,0l0,1.5l-1.5,0l0,1.3l1.5,0l0,1.4l-1.5,0l0,1.4l1.9,0c0,4.4-0.2,7-0.5,7.7c-0.3,0.8-1.1,1.1-2.3,1.1c-0.2,0-0.4,0-0.8,0
			l-0.6-1.7l1.8,0c0.2,0,0.3-0.1,0.4-0.3c0.1-0.2,0.2-2,0.3-5.3l-5,0l0.1-10.1l6.8,0.1L673.1,642.9z M668.2,656.7l-1,0.2
			c0.1-0.6,0.1-1.3,0.1-1.9c0-0.9,0-1.7-0.1-2.6l0.9-0.1c0.1,1,0.2,2.1,0.1,3.2C668.2,655.9,668.2,656.3,668.2,656.7z M669.4,649.8
			l0-1.4l-1.3,0l0,1.4L669.4,649.8z M669.4,646.9l0-1.3l-1.3,0l0,1.3L669.4,646.9z M669.4,644.1l0-1.3l-1.3,0l0,1.3L669.4,644.1z
			 M669.6,656l-0.9,0.2c0-1.5-0.1-2.8-0.3-3.9l0.8-0.1C669.5,653.1,669.6,654.4,669.6,656z M670.8,655.2l-0.8,0.2
			c0-1-0.1-2.1-0.4-3.4l0.7-0.1C670.6,652.8,670.8,653.9,670.8,655.2z M678.3,656.5l-1.2,1.2c-0.4-0.5-0.8-0.9-1.2-1.2
			c-0.5,0.9-1.1,1.7-1.7,2.4l-1.6-1c1.1-1.1,2-2.7,2.8-4.6l1.8,0.4c-0.1,0.4-0.3,0.9-0.5,1.2C677.3,655.4,677.9,656,678.3,656.5z
			 M684.6,645.6l-0.9,1.6c-0.8-0.5-1.6-1-2.3-1.5l0,1l-5.7-0.1l0-1c-0.9,0.6-1.5,1.1-2,1.4l-0.9-1.7c2.5-1.4,4.2-3,5.2-4.6l1.9,0.4
			c-0.1,0.1-0.2,0.3-0.3,0.4C680.8,643,682.4,644.3,684.6,645.6z M678,652.9l-4.3,0l0.1-5.2l4.3,0L678,652.9z M676.5,651.3l0-2.1
			l-1.3,0l0,2.1L676.5,651.3z M680.8,645.2c-0.7-0.6-1.5-1.4-2.2-2.2c-0.7,0.8-1.4,1.5-2.3,2.2L680.8,645.2z M684.3,657.5l-1.3,1.3
			c-0.5-0.7-1.2-1.4-2.1-2c-0.5,0.7-1,1.4-1.6,2.1l-1.7-1c1.2-1.3,2.2-2.9,2.9-4.7l1.8,0.3c-0.1,0.4-0.3,1-0.6,1.6
			C682.8,656,683.6,656.8,684.3,657.5z M683.3,653l-4.4,0l0.1-5.2l4.4,0L683.3,653z M681.8,651.4l0-2.1l-1.3,0l0,2.1L681.8,651.4z"
			/>
		<path class="st7" d="M695.1,659l-1.9,0l0.1-5c-1.5,0.8-3.1,1.5-4.7,2.1l-0.5-1.6c0.2-0.2,0.4-0.5,0.4-0.7l0.1-11.2l1.8,0
			l-0.1,10.9c1.1-0.4,2.1-0.9,3.1-1.5l0.1-10.7l1.9,0L695.1,659z M706.7,657.6l-1.1,1.6c-1.8-0.8-3.3-1.8-4.7-3
			c-1.4,1.3-2.9,2.3-4.4,2.9l-1.1-1.6c1.9-0.8,3.3-1.7,4.2-2.7c-0.9-1.3-1.6-2.8-2-4.4l1.7-0.8c0.3,1.5,0.8,2.8,1.5,3.7
			c1.1-1.7,1.8-4.2,2.1-7.5l-3.5,0c-0.7,2-1.5,3.7-2.6,5.2l-1.3-1.5c1.4-2.2,2.4-5,3.1-8.4l1.9,0.2c-0.1,0.7-0.3,1.6-0.7,2.7
			l6.5,0.1l0,1.8l-1.6,0c-0.4,4.1-1.3,7-2.8,8.9C703.5,656,705,656.9,706.7,657.6z"/>
	</g>
</g>
<g>
	<g class="st4">
		<path class="st7" d="M568.4,719.7c-1.8,1.3-3.8,2.4-5.9,3.2l-1.1-1.5c2.1-0.7,3.9-1.7,5.6-2.9L568.4,719.7z M579.9,717.7l-1.6,1.1
			c-0.5-0.7-0.9-1.2-1.2-1.5c-2,0.2-3.8,0.4-5.3,0.5v4.2c0,0.5-0.1,1-0.5,1.3c-0.4,0.3-1.5,0.5-3.4,0.5l-0.8-1.8
			c0.8,0.1,1.5,0.1,2.2,0.1c0.4,0,0.6-0.2,0.6-0.5v-3.5c-2.9,0.1-5.1,0.2-6.5,0.2l-0.5-1.6c1.8-0.5,3.9-1.3,6.3-2.5
			c-2.5,0.1-4.3,0.2-5.3,0.2l-0.3-1.3c2.9-0.9,5.6-2.3,7.9-4.2l1.8,1c-1.4,1.2-3.1,2.2-5.2,3l1.7-0.1l2.6-0.2c0.1,0,0.6-0.3,1.4-0.9
			l1.5-1l1.7,1.1c-3.4,2.3-6.5,3.9-9.5,4.9c2.5-0.1,5.3-0.3,8.3-0.7c-0.4-0.4-0.8-0.8-1.3-1.2l1.7-0.9
			C577.7,715.1,579,716.5,579.9,717.7z M573.3,706.8c-2.1,1.7-5.2,3-9,4l-1.1-1.5c3.4-0.7,6.3-1.8,8.5-3.4L573.3,706.8z
			 M580.3,721.6l-1.3,1.4c-1.4-1.2-3.3-2.3-5.6-3.4l1.3-1.3C577,719.4,578.9,720.5,580.3,721.6z"/>
		<path class="st7" d="M586.4,719c-0.3,2-0.7,3.7-1.4,4.9l-1.6-0.7c0.6-1.2,1.1-2.6,1.4-4.5L586.4,719z M591.6,717.6l-1.7,0.5
			c0-0.1-0.1-0.4-0.2-1.1c-1.9,0.5-3.8,0.9-5.7,1.2l-0.3-1.4c1.2-0.9,2.5-2.3,3.9-4.2c-1.1,0.2-2.2,0.3-3.3,0.4l-0.2-1.2
			c1.6-1.7,2.9-3.7,3.8-6.1l1.8,0.6c-0.9,2-1.9,3.7-3.1,5c0.7-0.1,1.4-0.1,2.1-0.2c0.4-0.6,0.8-1.2,1-1.8l1.7,0.8
			c-1.5,2.5-3,4.6-4.5,6.1c0.9-0.2,1.7-0.4,2.5-0.6c-0.1-0.4-0.3-0.7-0.3-0.9l1.5-0.6C591,715.3,591.4,716.5,591.6,717.6z
			 M589.2,723l-1.6,0.3c0-1.3-0.1-2.8-0.5-4.4l1.5-0.4C588.9,720.2,589.2,721.7,589.2,723z M591.7,721.3l-1.4,0.5
			c-0.2-1.2-0.5-2.1-0.8-2.9l1.4-0.5C591.2,719.2,591.5,720.1,591.7,721.3z M602.6,718.9c-0.1,2.1-0.3,3.3-0.4,3.5
			c-0.1,0.2-0.3,0.5-0.6,0.7c-0.3,0.3-1,0.4-2.2,0.4c-1,0-1.6-0.1-1.9-0.4c-0.3-0.3-0.5-0.8-0.5-1.7v-6.9c-0.7,0.1-1.2,0.2-1.6,0.2
			c0,1.5-0.1,2.7-0.2,3.7c-0.1,1-0.5,2-1.1,2.9s-1.3,1.8-2.3,2.5l-1.4-1.2c0.6-0.4,1.2-1,1.7-1.7c0.5-0.7,0.9-1.5,1.1-2.3
			c0.2-0.8,0.3-2.1,0.3-3.7l-1.9,0.2l-0.3-1.3c1.2-1.4,2.2-2.9,3-4.5h-3.2v-1.7h3.9c0.2-0.4,0.4-1,0.7-1.9l2,0.3
			c-0.2,0.7-0.5,1.2-0.6,1.6h5.3v1.7h-6.1c-0.6,1.3-1.4,2.6-2.5,4c1.8-0.1,3.5-0.3,5-0.6c-0.3-0.4-0.6-0.8-1.1-1.3l1.5-0.9
			c1.5,1.7,2.5,3.1,3.2,4.2l-1.6,1c-0.4-0.7-0.7-1.2-1-1.6l-1.1,0.2v7.1c0,0.3,0.3,0.5,1,0.5c0.5,0,0.8-0.2,0.9-0.5
			c0.1-0.4,0.2-1.4,0.3-3.1L602.6,718.9z"/>
		<path class="st7" d="M624.7,723.2H606v-1.8h7.7v-15.5h2v6.1h8.1v1.8h-8.1v7.6h9V723.2z"/>
		<path class="st7" d="M631.1,718.9c-0.3,1.9-0.7,3.5-1.3,4.8l-1.5-0.6c0.6-1.5,1-3,1.3-4.5L631.1,718.9z M636.2,709.7
			c-1.6,2.7-3.2,4.9-4.9,6.6c0.6-0.1,1.3-0.3,2.3-0.5c-0.1-0.4-0.3-0.7-0.4-0.9l1.4-0.5c0.6,1.1,1,2.2,1.2,3.2l-1.4,0.5
			c-0.1-0.4-0.2-0.7-0.2-0.9c-1.6,0.4-3.3,0.8-5.3,1.1l-0.4-1.4c1.3-1.1,2.5-2.4,3.7-4c-0.9,0.2-2,0.3-3.2,0.4l-0.3-1.3
			c1.5-1.6,2.7-3.7,3.8-6.2l1.7,0.6c-0.8,1.7-1.8,3.4-3,5c0.8-0.1,1.5-0.2,2.1-0.3c0.4-0.6,0.8-1.2,1.2-2L636.2,709.7z M634,722.9
			l-1.7,0.3c0-1.4-0.2-2.8-0.5-4.3l1.6-0.3C633.7,720.1,633.9,721.5,634,722.9z M636.4,720.4l-1.4,0.5c-0.2-0.8-0.5-1.6-0.9-2.3
			l1.4-0.4C635.8,718.9,636.1,719.7,636.4,720.4z M640,715.6c0.1,2.7-1.2,5.2-3.9,7.7l-1.3-1.1c1.9-1.5,3-3.1,3.4-4.9h-2.2v-1.6H640
			z M647.4,721.7l-1.3,1.5c-1.3-1-2.5-2.4-3.7-4.2v3c0,0.6-0.3,1-0.8,1.3c-0.5,0.3-1.5,0.4-3,0.4l-0.5-1.8c1,0,1.6,0,1.9,0
			c0.3,0,0.5-0.1,0.5-0.3v-7.3h-4v-6.7h2.7c0.4-0.5,0.7-1.2,1-1.8l1.9,0.4c-0.2,0.5-0.4,1-0.7,1.4h4.7v6.7h-3.6v1.6
			c0.2,0.3,0.4,0.7,0.7,1.1c0.6-0.4,1.4-1.1,2.3-2l1.3,1.1c-0.7,0.7-1.6,1.5-2.6,2.3C644.8,719.5,646,720.6,647.4,721.7z
			 M644.2,710.3v-1.2h-5.8v1.2H644.2z M644.2,712.9v-1.2h-5.8v1.2H644.2z"/>
		<path class="st7" d="M669.4,722.2l-1,1.5c-2.1-0.9-3.9-1.8-5.4-2.9c-1.8,1.2-4,2.1-6.7,3l-1.1-1.6c2.4-0.7,4.5-1.6,6.2-2.6
			c-1.4-1.3-2.7-3.1-4-5.4c-0.8,3.7-2.6,6.8-5.5,9.3l-1.3-1.4c1.8-1.6,3.1-3.3,3.9-5.1c0.8-1.8,1.3-4.8,1.6-9h-3.9v-1.8h14.4
			c-0.5,1.9-1.1,3.7-1.8,5.5h3.4c-0.6,3.5-1.9,6.2-3.8,8C665.8,720.7,667.4,721.5,669.4,722.2z M664.1,708h-5.9
			c0,1.3-0.2,2.5-0.3,3.7h4.9C663.2,710.7,663.6,709.4,664.1,708z M665.9,713.5h-6.8c1.2,2.2,2.5,3.9,3.9,5.1
			C664.3,717.4,665.2,715.7,665.9,713.5z"/>
		<path class="st7" d="M692,721.8l-0.5,1.6c-1.5,0.1-2.9,0.1-4.2,0.1c-3.8,0-6.3-0.2-7.5-0.5c-1.2-0.3-2.4-0.9-3.4-1.7
			c-0.6,0.9-1.3,1.8-2,2.5l-1.5-1.1c1.9-2,3.1-4,3.6-5.9h-3.2v-1.7c1.2-1.1,2.1-2.3,2.8-3.5h-3V710h5.1v1.7
			c-0.6,1.2-1.4,2.4-2.5,3.5h2.7v1.7c-0.3,1.1-0.7,2.1-1.2,3.1c0.5,0.5,1.3,1,2.2,1.3s2,0.5,3.4,0.6c1.4,0,2.9,0.1,4.4,0.1
			C688.8,721.9,690.4,721.9,692,721.8z M678.3,708.4l-1.3,1.2c-1-1-2.2-1.8-3.4-2.6l1.4-1.3C676.3,706.5,677.4,707.4,678.3,708.4z
			 M691.8,719.8h-5.5v1.6h-1.8v-1.6h-5.6v-1.4h5.6v-1h-4.7v-5.9h4.7v-0.9h-2.7v-1.4h2.7v-0.8h1.8v0.8h2.7v1.4h-2.7v0.9h4.6v5.9h-4.6
			v1h5.5V719.8z M691.4,709.7h-1.8v-1.9h-8.4v1.9h-1.9v-3.4h12.2V709.7z M684.4,713.8v-1h-2.8v1H684.4z M684.4,716v-1h-2.8v1H684.4z
			 M689,713.8v-1h-2.8v1H689z M689,716v-1h-2.8v1H689z"/>
		<path class="st7" d="M701.9,706.6c-1.6,2-3.5,3.6-5.7,4.8l-0.9-1.7c1.7-0.8,3.3-2.1,4.8-3.9L701.9,706.6z M702.3,710.4
			c-0.7,1.1-1.4,2.1-2.1,3v10.4h-2v-8.2c-0.8,0.7-1.5,1.3-2.1,1.7l-0.8-1.8c1.8-1.1,3.6-3.1,5.3-5.8L702.3,710.4z M714,713.5h-2.9
			v8.2c0,1.4-0.8,2-2.3,2c-1,0-2,0-2.9-0.1l-0.7-1.8c0.6,0,1.7,0,3.3,0c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.2-0.4v-7.8h-7v-1.8
			H714V713.5z M713.5,708.3h-10.8v-1.8h10.8V708.3z"/>
		<path class="st7" d="M736.4,721.6l-0.5,1.8c-1.3,0.1-2.7,0.1-4,0.1c-3.8,0-6.3-0.1-7.5-0.4c-1.2-0.3-2.3-0.8-3.3-1.6
			c-0.6,0.9-1.3,1.8-2,2.4l-1.5-1.1c1.7-1.7,2.9-3.6,3.5-5.8H718v-1.7c1.2-1.3,2.1-2.4,2.7-3.5h-3v-1.7h5.3v1.7
			c-0.8,1.2-1.6,2.4-2.6,3.5h2.7v1.7c-0.3,1.1-0.7,2.1-1.1,2.9c0.8,0.8,1.8,1.3,2.9,1.5c1.1,0.2,3.2,0.3,6.3,0.3
			C733,721.8,734.8,721.7,736.4,721.6z M723.1,708.2l-1.3,1.2c-1.1-0.9-2.3-1.6-3.6-2.3l1.2-1.3
			C720.7,706.4,721.9,707.2,723.1,708.2z M735.5,720.2h-10.9v-12.4h3.3c0.5-0.6,0.9-1.3,1.2-2l2,0.4c-0.2,0.4-0.5,1-0.9,1.6h4.9v5.2
			h-8.6v1.6h9.1V720.2z M733.2,711.4v-2h-6.8v2H733.2z M733.7,718.6v-2.4h-7.2v2.4H733.7z"/>
		<path class="st7" d="M746.8,721.3c-2.2,1-4.4,1.8-6.5,2.5l-0.5-1.7c0.3-0.1,0.6-0.2,0.8-0.3v-7.9h1.6v7.4c0.3,0,0.5-0.1,0.8-0.3
			v-8.5h-2.5v-6.1h5.4v6.1h-1.1v2.1h1.2v1.7h-1.2v4c0.9-0.4,1.5-0.6,1.8-0.8L746.8,721.3z M744.2,710.8v-2.7h-1.8v2.7H744.2z
			 M750.3,712.6l-1,2.2v9h-1.7v-6c-0.4,0.5-0.8,0.9-1,1.1l-0.9-1.7c1.2-1.3,2.2-3,3-5.2L750.3,712.6z M751,706.4
			c-1.3,2.3-2.6,4-3.8,5l-0.9-1.6c1.2-1.1,2.2-2.5,3-4L751,706.4z M754.1,709.4l-0.9,1.4c-0.2-0.2-0.5-0.5-0.8-0.9
			c-0.5,0.9-1.1,1.8-1.7,2.5l-1.3-1.1c1.1-1.3,2-3.1,2.7-5.4l1.7,0.3c-0.2,0.8-0.5,1.5-0.8,2.3C753.3,708.6,753.7,708.9,754.1,709.4
			z M759,721.8l-0.6,1.8c-3.3,0.1-5.4-0.9-6.3-2.9c-0.2,1.1-0.5,2.1-0.9,3.1l-1.5-1.1c0.8-2.2,1.2-5.1,1.2-8.8h1.8
			c0,0.5,0,1.2-0.1,2.2l-0.1,1.3c0,0.2,0.1,0.7,0.4,1.4s0.7,1.4,1.2,1.9v-7.6h1.8v2.8h2.4v1.7h-2.4v4.1
			C756.5,721.8,757.6,721.8,759,721.8z M758.9,711.4l-1.2,1.4c-0.5-0.9-1.1-1.6-1.7-2.3c-0.4,0.9-0.9,1.6-1.4,2.2l-1.4-0.9
			c1.2-1.6,2.1-3.6,2.6-5.8l1.7,0.3c-0.2,0.9-0.5,1.8-0.9,2.7C757.6,709.7,758.4,710.6,758.9,711.4z"/>
	</g>
</g>
<polygon class="st7" points="670.5,611.3 661.6,621.4 652.7,611.3 "/>
<path class="st6" d="M774.6,600.5H549.7c-6.7,0-12.2-5.6-12.2-12.2v-22.3c0-6.7,5.6-12.2,12.2-12.2h224.9c6.7,0,12.2,5.6,12.2,12.2
	v22.3C786.9,594.9,781.3,600.5,774.6,600.5z"/>
<g>
	<g class="st4">
		<path class="st7" d="M617.2,588.1l-1.9,0l0-1.1l-4,0l0,1.1l-1.9,0l0.1-9.6l7.7,0.1L617.2,588.1z M615.4,585.3l0.1-5l-4,0l-0.1,5
			L615.4,585.3z M624.9,576.7l-13.2-0.1l0.1-6.8l13.2,0.1L624.9,576.7z M623,575l0-3.3l-9.3-0.1l0,3.3L623,575z M626.8,588.2l-1.9,0
			l0-1.1l-4,0l0,1.1l-1.9,0l0.1-9.6l7.8,0.1L626.8,588.2z M625,585.4l0.1-5.1l-4,0l-0.1,5.1L625,585.4z"/>
		<path class="st7" d="M640.5,572.5l-5.7-0.1c-0.7,0.7-1.5,1.5-2.4,2.2l-1.2-1.6c1.7-1.1,2.8-2.3,3.3-3.6l1.9,0.5
			c-0.1,0.3-0.2,0.5-0.4,0.9l4.5,0L640.5,572.5z M649.4,579l-1.9,0l0-2.1l-13.9-0.1l0,2.1l-1.9,0l0-3.6l7.7,0.1
			c-0.1-0.2-0.2-0.5-0.5-1l2.2-0.3c0.2,0.3,0.4,0.7,0.7,1.3l7.6,0.1L649.4,579z M647.4,587.6l-11.4-0.1l0,0.9l-1.9,0l0.1-10.5
			l12.5,0.1l0,4.1l-10.7-0.1l0,1.1l11.4,0.1L647.4,587.6z M638.5,574.4l-2.1,0.4c-0.2-0.4-0.5-0.9-0.9-1.5l2-0.4
			C637.8,573.1,638.1,573.6,638.5,574.4z M645.5,586.1l0-1.5l-9.5-0.1l0,1.5L645.5,586.1z M644.8,580.7l0-1.2l-8.8-0.1l0,1.2
			L644.8,580.7z M650,572.6l-6.6-0.1c-0.3,0.5-0.7,1-1.2,1.5l-1.7-0.9c1.2-1.2,2-2.4,2.5-3.6l1.9,0.6c-0.2,0.4-0.3,0.7-0.5,0.9
			l5.6,0.1L650,572.6z M647.1,574.6l-2.1,0.3c-0.2-0.5-0.4-1.1-0.8-1.7l2.1-0.2C646.6,573.4,646.9,574,647.1,574.6z"/>
		<path class="st7" d="M660.9,585.7c-1.7,1.2-3.8,2.2-6.4,3.1l-1.1-1.6c2.4-0.8,4.3-1.6,5.9-2.7L660.9,585.7z M672.2,584.4
			l-18.7-0.2l0-1.8l1.4,0l-0.1-11.2c1.4-0.3,2.8-0.8,4.1-1.4l0.9,1.6c-0.9,0.4-2,0.8-3.2,1.1l0,1.9l2.4,0l0,1.7l-2.4,0l0,2.2l2.4,0
			l0,1.7l-2.4,0l0,2.3l11.9,0.1l0.1-2.3l-2.2,0l0-1.7l2.3,0l0.1-2.2l-2.3,0l0-1.7l2.4,0l0.1-2.1l-2.4,0l0-1.8l4.4,0l-0.4,11.8l1.5,0
			L672.2,584.4z M662.4,577.1c-0.3,1.8-0.9,3.4-1.6,4.7l-1.5-1c0.7-1.4,1.2-2.8,1.4-4.1L662.4,577.1z M665.9,572.9l-3.5,0l-0.2,1.5
			l3.7,0c0,1.2-0.1,2.5-0.2,4c-0.1,1.4-0.2,2.3-0.5,2.6c-0.2,0.3-0.5,0.5-0.8,0.7c-0.3,0.1-0.9,0.2-1.9,0.1L662,580
			c0.3,0.1,0.6,0.1,0.9,0.1c0.3,0,0.6,0,0.7-0.1c0.1-0.1,0.2-0.1,0.2-0.2c0-0.1,0.1-0.5,0.2-1.4c0.1-0.8,0.1-1.6,0.1-2.4l-3.9,0
			l0.8-6l1.8,0l-0.2,1.2l3.2,0L665.9,572.9z M672.3,587.4l-1.2,1.6c-2-1.2-4.1-2.1-6.1-2.8l1.2-1.6
			C668.3,585.4,670.4,586.3,672.3,587.4z"/>
		<path class="st7" d="M694.8,576.8l-0.9,1.7c-0.6-0.1-1.2-0.3-1.9-0.6l-0.1,8.1l-9-0.1l0.9,1.1c-2,0.9-4.4,1.6-7.2,2l-0.9-1.7
			c2-0.2,4-0.7,5.9-1.4l-3.2,0l0.1-8.1l4.1,0l-0.8-1.2c2.5-0.6,4.4-1.7,5.6-3.3l1.9,0.5c-0.2,0.4-0.4,0.6-0.5,0.7
			C690.4,575.5,692.3,576.3,694.8,576.8z M682.2,576.1L676,576l0-1.8l6.2,0.1L682.2,576.1z M682.1,572.7l-5.8-0.1l0-1.7l5.8,0.1
			L682.1,572.7z M690,584.6l0-0.8l-9.6-0.1l0,0.8L690,584.6z M690,582.4l0-0.8l-9.6-0.1l0,0.8L690,582.4z M690,580.2l0-0.8l-9.6-0.1
			l0,0.8L690,580.2z M694.4,573c-0.7,0.9-1.3,1.7-1.9,2.4l-1.6-1c0.2-0.2,0.6-0.7,1.2-1.4l-6.8-0.1c-0.7,0.7-1.3,1.4-1.9,1.9
			l-1.4-1.3c1.1-0.8,2.2-2,3.2-3.6l1.8,0.6c-0.1,0.2-0.2,0.5-0.4,0.7l7.9,0.1L694.4,573z M691.8,577.9c-1.4-0.5-2.8-1.2-4.2-2.1
			c-0.9,0.8-2.1,1.5-3.5,2L691.8,577.9z M694.5,587.6l-1,1.6c-1.9-0.6-4-1.1-6.3-1.4l1-1.6C690.5,586.5,692.6,586.9,694.5,587.6z"/>
		<path class="st7" d="M716.9,580l-4.2,0c-0.8,2-1.7,3.5-2.7,4.6c2.8,1.2,5,2.3,6.8,3.2l-1.1,1.6c-1.9-1-4.2-2.1-7-3.3
			c-2.2,1.7-5.5,2.8-9.9,3.1l-0.7-1.8c3.9-0.3,6.6-1,8.4-2.1c-1.9-0.7-3.8-1.4-5.8-2c0.8-1.1,1.6-2.2,2.5-3.4l-4.9-0.1l0-1.8
			l6.1,0.1c0.7-1,1.3-2.1,2-3.3l2,0.3c-0.7,1.2-1.2,2.2-1.7,3l4.7,0.1c0.2-0.6,0.4-1,0.5-1.5l1.9,0.2c-0.1,0.4-0.2,0.8-0.4,1.3
			l3.6,0L716.9,580z M716.4,576.8l-1.9,0l0-2.5l-13.6-0.1l0,2.5l-2,0l0-4.3l7.7,0.1c-0.2-0.6-0.5-1.2-0.9-1.7l1.9-0.5
			c0.4,0.6,0.8,1.3,1.1,2.2l7.7,0.1L716.4,576.8z M710.6,580l-5.1-0.1c-0.4,0.7-0.9,1.5-1.6,2.4c1.3,0.5,2.7,1,4.3,1.6
			C709.1,582.9,709.9,581.6,710.6,580z"/>
	</g>
</g>
<polygon class="st7" points="670.5,538.6 661.6,549.3 652.7,538.6 "/>
<polygon class="st7" points="670.5,677.1 661.6,687.2 652.7,677.1 "/>
<polygon class="st7" points="312.3,396 303.4,406.8 294.5,396 "/>
<polygon class="st7" points="312.3,465.9 303.4,476.7 294.5,465.9 "/>
<polygon class="st7" points="436.4,497.9 447.1,506.8 436.4,515.7 "/>
<path class="st8" d="M1257.1,427.9H924.7c-6.2,0-11.3-5.1-11.3-11.3V254.9c0-6.2,5.1-11.3,11.3-11.3h332.4c6.2,0,11.3,5.1,11.3,11.3
	v161.7C1268.4,422.8,1263.3,427.9,1257.1,427.9z"/>
<g>
	<g>
		<path class="st7" d="M957.6,273.7h-7.5c-0.2,0.5-0.4,1.1-0.7,1.7h6.6v8.1h-5.1v4.8c0,0.4-0.1,0.8-0.4,1c-0.2,0.3-0.5,0.5-0.8,0.6
			c-0.3,0.1-0.9,0.1-1.8,0.1c-0.4,0-0.8,0-1.1,0l-0.6-1.8c1.5,0,2.3,0,2.5-0.1c0.2-0.1,0.3-0.3,0.3-0.6v-4.1h-5.2v-8.1h3.6
			c0.2-0.5,0.5-1,0.7-1.7h-5.9v6.9c0,4.1-0.7,7.3-2.1,9.7l-1.6-1.1c1.2-2.2,1.8-5.2,1.8-8.9v-8.3h17.2L957.6,273.7z M947.3,284.9
			c-1,1.6-2.3,3.1-4.1,4.5l-1.5-1.2c1.9-1.5,3.2-2.9,4-4.1L947.3,284.9z M954.1,278.7v-1.5h-8.3v1.5H954.1z M954.1,281.8v-1.5h-8.3
			v1.5H954.1z M957.8,288.3l-1.5,1.2c-1-1.5-2.2-2.9-3.8-4.4l1.5-1.1C955.4,285.3,956.7,286.7,957.8,288.3z"/>
		<path class="st7" d="M967.7,273.9h-2.8c-0.4,1.6-0.8,2.9-1.3,4.1h3.8v9.4h-1.8v-0.7H964v1.4h-1.8v-7.2c-0.3,0.5-0.7,1-1.1,1.5
			l-0.8-2c1.1-1.5,2-3.7,2.7-6.4h-2.4v-1.8h7.2V273.9z M965.6,284.9V280H964v4.9H965.6z M970.1,284.3c-0.1,1.8-0.6,3.6-1.3,5.4
			l-1.5-0.8c0.6-1.5,1-3.1,1.2-4.8L970.1,284.3z M979.1,281.3c0,1.6-0.1,3.1-0.2,4.5s-0.2,2.4-0.3,2.7c-0.1,0.4-0.3,0.7-0.5,1
			s-0.6,0.4-1,0.5c-0.4,0.1-1.2,0.2-2.3,0.2l-0.6-1.8c0.5,0,0.9,0,1.2,0c0.6,0,1.1,0,1.2-0.1c0.2-0.1,0.3-0.2,0.3-0.4
			c0.1-0.2,0.1-0.8,0.2-1.8c0.1-1,0.1-2.1,0.1-3.1h-8.7v-11.1H979v1.7h-3.9v1.4h3.2v1.7h-3.2v1.5h3.2v1.6h-3.2v1.5H979.1z
			 M973.2,275v-1.4h-2.7v1.4H973.2z M973.2,278.1v-1.5h-2.7v1.5H973.2z M973.2,281.3v-1.5h-2.7v1.5H973.2z M972.5,288.7l-1.6,0.3
			c0-1.7-0.1-3.3-0.4-4.6l1.4-0.3C972.3,285.6,972.4,287.2,972.5,288.7z M974.8,287.7l-1.5,0.4c-0.1-1.3-0.3-2.6-0.8-3.8l1.4-0.4
			C974.3,285,974.6,286.2,974.8,287.7z M976.8,286.8l-1.4,0.4c-0.1-0.9-0.4-1.9-0.7-3l1.3-0.4C976.4,284.7,976.7,285.7,976.8,286.8z
			"/>
		<path class="st7" d="M993.6,285.5H991c-0.5,0.8-1,1.3-1.4,1.8c1.1,0.5,2.1,0.9,3,1.3l-1.1,1.4c-1.4-0.7-2.5-1.1-3.3-1.4
			c-1.9,1-3.7,1.6-5.4,1.8l-0.8-1.6c1.5-0.2,2.8-0.5,3.9-1c-1.1-0.4-2-0.7-2.8-0.8c0.7-0.7,1.1-1.2,1.2-1.4h-2.2V284h3.4l0.8-1.2
			h-3.3v-3.5h3.7v-1h-3.8v-2.3h-1v-1.4h1v-2.3h3.8v-0.8h1.8v0.8h3.7v2.3h1.1v1.4h-1.1v2.3h-3.7v1h3.8v3.5h-3.9l-0.8,1.2h1.9
			c0.1-0.2,0.2-0.4,0.3-0.7l1.7,0.4c0,0.1,0,0.2-0.1,0.3h1.9V285.5z M986.9,274.6v-1h-2.1v1H986.9z M986.9,277v-1h-2.1v1H986.9z
			 M986.9,281.4v-0.8h-2v0.8H986.9z M988.9,285.5h-2.1c0,0-0.2,0.3-0.5,0.6l1.7,0.5C988.3,286.2,988.6,285.9,988.9,285.5z
			 M990.7,274.6v-1h-2v1H990.7z M990.7,276.9v-1h-2v1H990.7z M990.8,281.4v-0.8h-2.1v0.8H990.8z M1001.1,288.7l-0.9,1.6
			c-1.5-1.1-2.5-2.2-3.2-3.2c-0.9,1.2-1.8,2.2-2.9,3.1l-1.2-1.4c1.3-1.1,2.4-2.2,3.1-3.4c-0.7-1.2-1.2-2.7-1.6-4.3l1.6-0.9
			c0.3,1.3,0.6,2.3,0.9,3c0.5-1.7,0.8-4.1,0.9-7h-1.6c-0.7,2-1.3,3.5-2,4.5l-1.2-1.5c1.2-2.2,2-4.8,2.5-8l1.8,0.3
			c-0.1,0.6-0.3,1.6-0.6,2.9h4.1v1.8h-1.2c-0.2,3.9-0.7,7-1.6,9.2C998.9,286.7,999.9,287.8,1001.1,288.7z"/>
		<path class="st7" d="M1009.8,271.8c-0.2,0.7-0.6,1.7-1.1,2.7l-0.7,1.6v14.1h-1.9v-10.8c-0.4,0.6-1,1.3-1.6,1.9l-0.9-2
			c1.7-2.1,3.1-4.7,4.3-8L1009.8,271.8z M1022.5,289.6h-13.9v-1.9h3.5c-0.3-3-0.8-6.3-1.6-9.9l1.3-0.9h-2.8v-1.9h5.6
			c-0.3-0.9-0.6-2-1.2-3.2l1.9-0.5c0.5,1.2,0.9,2.4,1.2,3.7h5.2v1.9h-2.8l1.5,0.9c-0.4,3.3-0.9,6.6-1.6,9.9h3.4V289.6z
			 M1018.9,276.9h-6.5c0.6,2.6,1.2,6.2,1.8,10.8h3C1018,283.8,1018.6,280.2,1018.9,276.9z"/>
		<path class="st7" d="M1034.3,272.9c-1,0.3-2,0.6-3.2,0.8v2.8h2.7v1.8h-2.7v0.6c1.1,1.2,2.1,2.4,2.9,3.6l-1.3,1.4
			c-0.5-1-1-1.9-1.5-2.6v8.9h-1.9v-8.5c-1,1.9-2,3.4-2.9,4.3l-1.1-1.7c1.5-1.5,2.8-3.5,3.9-6h-3.7v-1.8h3.8v-2.5
			c-0.9,0.1-1.9,0.2-2.9,0.3l-0.8-1.7c2.7-0.1,5.3-0.5,7.8-1.2L1034.3,272.9z M1044.2,284.7l-2,0.3v5.1h-1.9v-4.9l-6.4,0.9l-0.2-1.9
			l6.6-0.9v-11.9h1.9v11.6l1.9-0.2L1044.2,284.7z M1039.3,280.9l-1.3,1.4c-0.9-1.2-2.2-2.4-4-3.7l1.2-1.4
			C1037,278.5,1038.4,279.8,1039.3,280.9z M1039.6,275.7l-1.3,1.4c-1.2-1.4-2.5-2.5-3.8-3.5l1.3-1.4
			C1037.3,273.4,1038.6,274.5,1039.6,275.7z"/>
		<path class="st7" d="M1054.1,281c-0.8,0.4-1.6,0.7-2.2,0.9v6.2c0,0.6-0.2,1.1-0.5,1.4s-0.8,0.5-1.5,0.5c-0.7,0-1.3,0-1.8,0
			l-0.6-1.9c0.8,0,1.4,0,1.9,0c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4v-5c-0.8,0.3-1.6,0.5-2.5,0.7l-0.5-1.8
			c1.3-0.4,2.3-0.7,3-1v-4h-2.7v-1.8h2.7v-3.5h1.9v3.5h2v1.8h-2v3.2c0.6-0.2,1.2-0.4,1.8-0.8L1054.1,281z M1066,288.7l-1.1,1.6
			c-2-0.9-3.7-1.9-5-3c-1.5,1.2-3.4,2.1-5.5,3l-1.1-1.7c2.1-0.7,3.8-1.5,5.2-2.5c-1.2-1.3-2.2-3.2-3-5.5h-0.7v-1.8h4.2V276h-4.5
			v-1.8h4.5v-2.7h1.9v2.7h4.5v1.8h-4.5v2.8h4v1.8c-1,2.4-2.2,4.3-3.6,5.6C1062.5,287.1,1064.1,288,1066,288.7z M1062.8,280.6h-5.3
			c0.5,1.6,1.4,3,2.5,4.3C1061.2,283.7,1062.1,282.3,1062.8,280.6z"/>
		<path class="st7" d="M1087.5,289.4h-13.6v0.7h-1.9v-8.3c-0.7,0.7-1.4,1.3-2,1.8l-1.3-1.6c2.3-1.7,4-3.6,5.3-5.7h-4.7v-1.8h5.7
			c0.4-0.8,0.8-1.9,1.3-3.2l1.9,0.5c-0.3,1-0.7,1.9-1,2.7h10v1.8h-11c-0.7,1.2-1.4,2.3-2.3,3.4v7.8h5.4v-5h-4.4v-1.8h4.4v-3.4h2v3.4
			h4.7v1.8h-4.7v5h6.1V289.4z"/>
		<path class="st7" d="M1104.2,289.1h-2.8l-1.5-4.2h-6.5l-1.4,4.2h-2.8l6.1-16.2h2.8L1104.2,289.1z M1099.2,282.8l-2.3-6.4
			c-0.1-0.2-0.2-0.6-0.3-1.2h-0.1c-0.1,0.5-0.2,0.9-0.3,1.2l-2.3,6.4H1099.2z"/>
		<path class="st7" d="M1108.8,287.5v6.9h-2.5v-16.9h2.5v1.9h0.1c0.9-1.5,2.2-2.2,3.9-2.2c1.5,0,2.6,0.5,3.5,1.5
			c0.8,1,1.3,2.4,1.3,4.2c0,1.9-0.5,3.5-1.4,4.6s-2.2,1.7-3.8,1.7C1110.8,289.3,1109.6,288.7,1108.8,287.5L1108.8,287.5z
			 M1108.8,284.2c0,0.9,0.3,1.7,0.8,2.3c0.6,0.6,1.3,0.9,2.1,0.9c1,0,1.8-0.4,2.4-1.2c0.6-0.8,0.8-1.9,0.8-3.3
			c0-1.2-0.3-2.1-0.8-2.8c-0.5-0.7-1.3-1-2.2-1s-1.7,0.3-2.3,1s-0.9,1.5-0.9,2.6V284.2z"/>
		<path class="st7" d="M1122.9,287.5v6.9h-2.5v-16.9h2.5v1.9h0.1c0.9-1.5,2.2-2.2,3.9-2.2c1.5,0,2.6,0.5,3.5,1.5
			c0.8,1,1.3,2.4,1.3,4.2c0,1.9-0.5,3.5-1.4,4.6s-2.2,1.7-3.8,1.7C1124.8,289.3,1123.7,288.7,1122.9,287.5L1122.9,287.5z
			 M1122.9,284.2c0,0.9,0.3,1.7,0.8,2.3c0.6,0.6,1.3,0.9,2.1,0.9c1,0,1.8-0.4,2.4-1.2c0.6-0.8,0.8-1.9,0.8-3.3
			c0-1.2-0.3-2.1-0.8-2.8c-0.5-0.7-1.3-1-2.2-1s-1.7,0.3-2.3,1s-0.9,1.5-0.9,2.6V284.2z"/>
		<path class="st7" d="M1136.8,286.4l-1.8,5.6h-1.8l1.3-5.6H1136.8z"/>
		<path class="st7" d="M1164.3,278.2l-1,1.7c-3.8-1.5-6.6-3.6-8.5-6.2c-0.9,1-1.9,2-3.1,2.9c-1.2,0.9-3,2-5.5,3.1l-1-1.8
			c4.2-1.8,7.2-4,9-6.7l2.1,0.4l-0.4,0.5C1157.9,274.9,1160.7,276.9,1164.3,278.2z M1164,289.8h-18.6v-1.9h3.2v-7.6h2v7.6h3.6v-11
			l1.9,0v4.5h6.2v1.9h-6.2v4.7h8V289.8z"/>
		<path class="st7" d="M1185.8,285h-8.4v5.1h-1.9V285h-8.4v-1.6h8.4v-1.1h-7.4v-1.6h7.4v-1h-7.9V278h4.9c-0.2-0.3-0.5-0.6-0.7-0.9
			l1.4-0.8h-6.1v-1.6h6.6v-3.2h1.9v3.2h1.9v-3.2h1.9v3.2h6.5v1.6h-5.9l1.3,0.7c-0.3,0.5-0.5,0.8-0.7,1h4.9v1.6h-7.9v1h7.4v1.6h-7.4
			v1.1h8.4V285z M1174,286.8c-2,1.4-3.8,2.5-5.6,3.2l-1.2-1.5c1.7-0.6,3.5-1.6,5.3-3L1174,286.8z M1172.1,273.5l-1.4,1
			c-0.6-0.7-1.3-1.4-1.9-1.9l1.4-1.1C1171,272.2,1171.6,272.9,1172.1,273.5z M1179.2,276.3h-5.6c0.4,0.5,0.8,1.1,1.2,1.7h3.2
			C1178.5,277.5,1178.9,276.9,1179.2,276.3z M1186.2,288.4l-1,1.5c-2.1-1.2-4.1-2.2-6.1-2.9l1.1-1.5
			C1182.1,286.3,1184.1,287.2,1186.2,288.4z M1184.2,272.4c-0.4,0.6-1,1.2-1.8,2l-1.7-1c0.7-0.5,1.3-1.2,1.9-1.9L1184.2,272.4z"/>
		<path class="st7" d="M1207.5,274.2h-15.5v4.5c1.1-1,2.1-2.4,2.9-4.2l1.8,0.4c-0.4,0.9-0.9,1.8-1.4,2.7v6.7h-1.8v-4.4l-0.8,0.8
			l-0.6-1.8v4.5c0,2.5-0.5,4.8-1.6,6.8l-1.5-1.2c0.9-1.7,1.3-3.9,1.3-6.5v-9.9h7.5c-0.1-0.3-0.3-0.6-0.6-1l2.1-0.4
			c0.3,0.5,0.6,0.9,0.8,1.4h7.6V274.2z M1195.4,285.6c-0.5,1.5-1.2,2.9-2.2,4.1l-1.5-1c0.9-1.2,1.6-2.5,2-3.7L1195.4,285.6z
			 M1207.3,284.3h-10.6v-4.3c-0.1,0.1-0.3,0.3-0.6,0.5l-0.8-1.7c1.2-1.2,2.2-2.6,2.9-4.3l1.7,0.5c-0.2,0.4-0.3,0.7-0.5,1.1h2.2
			c-0.2-0.4-0.4-0.7-0.6-1.1l1.9-0.3c0.2,0.4,0.5,0.8,0.7,1.4h3.4v1.5h-3.8v0.9h3.6v1.3h-3.6v0.9h3.6v1.4h-3.6v0.9h4V284.3z
			 M1204.3,287.4c-0.1,1.7-0.8,2.5-2.1,2.5l-4.3,0c-1.2,0-1.7-0.5-1.7-1.6v-3.3h1.9v2.7c0,0.2,0.1,0.3,0.2,0.4s0.7,0.1,1.6,0.1
			c1.4,0,2.2-0.1,2.3-0.2c0.1-0.1,0.2-0.3,0.3-0.6c0.1-0.3,0.1-0.5,0.1-0.8L1204.3,287.4z M1201.5,278.3v-0.9h-2.9l-0.1,0.1v0.7
			H1201.5z M1201.5,280.5v-0.9h-3v0.9H1201.5z M1201.5,282.8v-0.9h-3v0.9H1201.5z M1202.1,286.8l-1.6,0.8c-0.4-0.7-0.9-1.5-1.6-2.3
			l1.6-0.8C1201.1,285.2,1201.6,286,1202.1,286.8z M1207.6,288.4l-1.6,0.9c-0.6-1.2-1.5-2.4-2.5-3.5l1.6-0.8
			C1205.8,285.7,1206.7,286.9,1207.6,288.4z"/>
		<path class="st7" d="M1228.1,288c0,0.6-0.2,1.1-0.6,1.5s-1.2,0.6-2.2,0.6c-0.5,0-1,0-1.5-0.1l-0.6-1.9l2.4,0
			c0.2,0,0.3-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5v-3.9h-5.3v6h-1.8v-6h-5.2c-0.2,2.8-0.9,5-1.9,6.6l-1.7-1.1
			c0.6-0.9,1.1-2.1,1.4-3.5s0.5-2.9,0.5-4.2v-9.5h15.9V288z M1219.1,276.9v-3.1h-5v3.1H1219.1z M1219.1,281.9v-3.2h-5v3.2H1219.1z
			 M1226.2,276.9v-3.1h-5.3v3.1H1226.2z M1226.2,281.9v-3.2h-5.3v3.2H1226.2z"/>
		<path class="st7" d="M945.8,319.8c-1.8,1.4-3.8,2.5-5.9,3.3l-1.1-1.5c2.1-0.8,3.9-1.8,5.6-3L945.8,319.8z M957.3,317.7l-1.6,1.1
			c-0.5-0.7-0.9-1.2-1.2-1.6c-2,0.3-3.8,0.4-5.3,0.5v4.4c0,0.6-0.1,1-0.5,1.4s-1.5,0.5-3.4,0.5l-0.8-1.9c0.8,0.1,1.5,0.1,2.2,0.1
			c0.4,0,0.6-0.2,0.6-0.6V318c-2.9,0.1-5.1,0.2-6.5,0.3l-0.5-1.6c1.8-0.5,3.9-1.4,6.3-2.7c-2.5,0.1-4.3,0.2-5.3,0.2l-0.3-1.4
			c2.9-0.9,5.6-2.4,7.9-4.5l1.8,1c-1.4,1.3-3.1,2.3-5.2,3.2l1.7-0.1l2.6-0.2c0.1,0,0.6-0.3,1.4-0.9l1.5-1.1l1.7,1.2
			c-3.4,2.4-6.5,4.1-9.5,5.1c2.5-0.1,5.3-0.3,8.3-0.7c-0.4-0.4-0.8-0.8-1.3-1.2l1.7-1C955.1,315,956.4,316.4,957.3,317.7z
			 M950.7,306.2c-2.1,1.7-5.2,3.1-9,4.2l-1.1-1.6c3.4-0.7,6.3-1.9,8.5-3.6L950.7,306.2z M957.7,321.8l-1.3,1.4
			c-1.4-1.2-3.3-2.4-5.6-3.5l1.3-1.3C954.4,319.5,956.3,320.7,957.7,321.8z"/>
		<path class="st7" d="M963.1,319.1c-0.3,2.1-0.7,3.8-1.4,5.2l-1.6-0.7c0.6-1.2,1.1-2.8,1.4-4.7L963.1,319.1z M968.4,317.7l-1.7,0.6
			c0-0.1-0.1-0.5-0.2-1.1c-1.9,0.6-3.8,1-5.7,1.3l-0.3-1.5c1.2-1,2.5-2.5,3.9-4.4c-1.1,0.2-2.2,0.3-3.3,0.4l-0.2-1.3
			c1.6-1.8,2.9-3.9,3.8-6.4l1.8,0.6c-0.9,2.1-1.9,3.8-3.1,5.3c0.7-0.1,1.4-0.1,2.1-0.3c0.4-0.6,0.8-1.3,1-1.9l1.7,0.9
			c-1.5,2.7-3,4.8-4.5,6.4c0.9-0.2,1.7-0.4,2.5-0.7c-0.1-0.4-0.3-0.7-0.3-0.9l1.5-0.6C967.7,315.2,968.1,316.5,968.4,317.7z
			 M966,323.3l-1.6,0.4c0-1.4-0.1-2.9-0.5-4.6l1.5-0.4C965.7,320.4,965.9,322,966,323.3z M968.4,321.5l-1.4,0.6
			c-0.2-1.2-0.5-2.2-0.8-3l1.4-0.5C968,319.3,968.2,320.3,968.4,321.5z M979.4,319c-0.1,2.2-0.3,3.5-0.4,3.7
			c-0.1,0.2-0.3,0.5-0.6,0.8s-1,0.4-2.2,0.4c-1,0-1.6-0.1-1.9-0.4c-0.3-0.3-0.5-0.9-0.5-1.8v-7.2c-0.7,0.1-1.2,0.2-1.6,0.2
			c0,1.5-0.1,2.9-0.2,3.9c-0.1,1.1-0.5,2.1-1.1,3.1c-0.6,1-1.3,1.9-2.3,2.6l-1.4-1.3c0.6-0.4,1.2-1,1.7-1.8c0.5-0.7,0.9-1.6,1.1-2.4
			c0.2-0.9,0.3-2.2,0.3-3.9l-1.9,0.2l-0.3-1.4c1.2-1.5,2.2-3,3-4.7h-3.2v-1.8h3.9c0.2-0.4,0.4-1.1,0.7-2l2,0.3
			c-0.2,0.7-0.5,1.2-0.6,1.7h5.3v1.8h-6.1c-0.6,1.4-1.4,2.7-2.5,4.2c1.8-0.1,3.5-0.3,5-0.6c-0.3-0.4-0.6-0.9-1.1-1.4l1.5-1
			c1.5,1.8,2.5,3.3,3.2,4.5l-1.6,1.1c-0.4-0.7-0.7-1.3-1-1.7l-1.1,0.2v7.5c0,0.3,0.3,0.5,1,0.5c0.5,0,0.8-0.2,0.9-0.6
			c0.1-0.4,0.2-1.5,0.3-3.3L979.4,319z"/>
		<path class="st7" d="M991.1,308.4h-5.6c-0.5,0.8-1.2,1.6-2.1,2.5l-1.5-1.2c1.4-1.3,2.6-2.8,3.5-4.5l1.9,0.4
			c-0.1,0.3-0.3,0.6-0.5,1.1h4.4V308.4z M1000.8,319.1h-3.5v3.1c0,0.6-0.2,1-0.5,1.3s-0.8,0.5-1.2,0.5c-0.5,0-1,0-1.6,0
			c-0.6,0-1.1,0-1.5,0l-0.7-1.8c0.6,0,1.6,0,3,0c0.4,0,0.6-0.2,0.6-0.5v-2.7h-13.2v-1.6h13.2v-1.3h-11.7v-1.6h6.9v-1.3h-7.9v-1.6
			h7.9v-1.2h1.9v1.2h7.9v1.6h-7.9v1.3h6.9v1.6h-2v1.3h3.5V319.1z M990.7,322.1l-1.5,1.2c-0.8-0.9-1.9-1.8-3.3-2.6l1.5-1.2
			C988.5,320.2,989.6,321.1,990.7,322.1z M989.3,310.4l-1.9,0.4c-0.2-0.5-0.6-1-1-1.7l2-0.5C988.6,309.1,988.9,309.7,989.3,310.4z
			 M1000.8,308.4h-6.6c-0.5,0.7-1,1.3-1.5,1.8l-1.8-0.8c1.3-1.4,2.4-2.8,3.1-4.2l1.8,0.5c-0.2,0.4-0.3,0.8-0.5,1.1h5.6V308.4z
			 M998.3,310.4l-2,0.5c-0.3-0.7-0.7-1.2-1.1-1.7l2-0.5C997.7,309.4,998,310,998.3,310.4z"/>
		<path class="st7" d="M1022.5,314.5h-15.3v3.5c0,2.6-0.7,4.7-2,6.2l-1.5-1.4c0.5-0.4,0.8-1.1,1.2-1.9c0.3-0.9,0.5-2.2,0.5-4.1v-3.9
			h17.1V314.5z M1021.9,308.4h-3.2c-0.6,0.6-1.2,1.1-1.9,1.5c1.7,0.4,3.2,0.8,4.6,1.2l-0.7,1.3c-1.8-0.6-3.7-1.1-5.7-1.5
			c-2.7,1-5.4,1.5-8.2,1.4l-0.6-1.5c1.8,0,3.6-0.1,5.3-0.5c-1.2-0.2-2.7-0.4-4.2-0.5l0.9-1.3c2,0.2,4.1,0.5,6.4,0.9
			c0.7-0.4,1.2-0.8,1.7-1.2h-12v-1.7h8c-0.2-0.3-0.4-0.7-0.6-1.1l1.9-0.4c0.3,0.5,0.6,1,0.8,1.5h7.5V308.4z M1022.5,323.7h-15.4
			v-1.6h6.9v-1.4h-4.3v-1.5h4.3v-1.2h-3.8c-0.6,0.8-1.1,1.5-1.7,2l-1.2-1.5c1-0.8,1.8-2,2.4-3.4l1.8,0.5c-0.1,0.3-0.2,0.6-0.3,0.8
			h2.8V315h1.9v1.3h5.6v1.6h-5.6v1.2h5.1v1.5h-5.1v1.4h6.6V323.7z"/>
		<path class="st7" d="M1033.9,324h-1.9V323h-4v1.1h-1.9v-9.6h7.7V324z M1032,321.2v-5h-4v5H1032z M1041.4,312.5h-13.2v-6.8h13.2
			V312.5z M1039.5,310.8v-3.3h-9.3v3.3H1039.5z M1043.5,324h-1.9V323h-4v1.1h-1.9v-9.6h7.8V324z M1041.6,321.2v-5.1h-4v5.1H1041.6z"
			/>
		<path class="st7" d="M1055.8,312.7h-6.1v11.4h-1.9v-18.2h8.1V312.7z M1053.8,308.5v-0.9h-4.2v0.9H1053.8z M1053.8,311v-0.9h-4.2
			v0.9H1053.8z M1062.6,319h-3v4.6h-1.9V319h-2.5c-0.2,1.9-1.1,3.5-2.8,4.7l-1.6-1.3c1.4-0.9,2.2-2.1,2.4-3.4h-3v-1.8h3.2v-1.8h-2.5
			v-1.8h11.1v1.8h-2.4v1.8h3V319z M1057.7,317.2v-1.8h-2.4v1.8H1057.7z M1065.2,322.5c0,0.5-0.2,0.9-0.5,1.2
			c-0.3,0.3-1.3,0.4-2.9,0.4c-0.3,0-0.6,0-0.8,0l-0.5-2.1c0.5,0.1,1.1,0.2,1.8,0.2c0.4,0,0.6,0,0.7-0.1s0.2-0.2,0.2-0.4v-9h-6.1
			v-6.8h8V322.5z M1063.3,308.5v-0.9h-4.2v0.9H1063.3z M1063.3,311v-0.9h-4.2v0.9H1063.3z"/>
		<path class="st7" d="M1077.7,307.3c-1.1,1.6-2.7,2.9-4.8,4.1h3.9v4.9h-4.2l-0.2,1.9h4.7c0,1.9-0.2,3.3-0.4,4.1
			c-0.2,0.8-0.5,1.3-1,1.5c-0.5,0.2-1.4,0.3-2.8,0.3c-0.5,0-1,0-1.7,0l-0.6-1.8c0.7,0,1.9,0.1,3.6,0.1c0.7,0,1-0.9,1-2.6h-4.8
			l0.6-5.1h4.1v-1.6h-3.6v-0.9c-0.5,0.2-1,0.5-1.8,0.7l-0.9-1.6c1.1-0.3,2.1-0.8,3.2-1.3c-0.5-0.3-1.1-0.6-1.8-1l1.3-1.3
			c0.6,0.2,1.4,0.7,2.3,1.2c0.7-0.5,1.3-1,1.8-1.6h-4.8v-1.7h7.1V307.3z M1087.7,311.4l-1,1.5c-0.7-0.3-1.4-0.7-2-1v2.2
			c0,0.2,0.1,0.3,0.4,0.3h1.9v1.6h-2.5c-1,0-1.5-0.5-1.5-1.6v-1.3h-2.1c0,1.5-0.8,2.6-2.5,3.5l-1.2-1.4c0.6-0.2,1-0.6,1.3-1
			s0.5-1.1,0.5-2v-0.8h5c-2.7-1.6-4.5-3.5-5.5-5.7l1.7-0.5c0.2,0.5,0.6,1.1,1.1,1.7c0.6-0.5,1.2-1.1,1.6-1.7l1.5,1
			c-0.7,0.8-1.3,1.5-2,2c0.2,0.2,0.5,0.5,1,0.8c0.8-0.7,1.5-1.3,2.1-2l1.5,0.9c-0.7,0.8-1.4,1.5-2.1,2
			C1085.6,310.4,1086.5,310.9,1087.7,311.4z M1087.3,323l-1.3,1.2c-0.9-0.7-2-1.4-3.2-2.1c-1.2,0.9-2.7,1.6-4.6,2.1l-0.9-1.6
			c1.7-0.4,2.9-1,3.8-1.5c-1.1-0.7-2.1-1.2-2.9-1.6l1.1-1.1h-1.3v-1.7h8v1.7c-0.6,0.9-1.2,1.7-1.9,2.4
			C1085.2,321.6,1086.3,322.3,1087.3,323z M1083.8,318.5h-4c1,0.5,1.9,1,2.8,1.5C1083.1,319.4,1083.6,318.9,1083.8,318.5z"/>
		<path class="st7" d="M1109.1,323.5h-18.7v-1.9h7.7v-16.3h2v6.4h8.1v1.9h-8.1v8h9V323.5z"/>
		<path class="st7" d="M1130.8,314.9h-6.1c-0.7,0.5-1.7,1-2.8,1.5h6.6v7.7h-1.9v-0.8h-8.8v0.8h-1.9v-5.4c-1.4,0.4-2.4,0.6-3.2,0.8
			l-0.8-1.6c3.6-0.7,6.9-1.7,9.7-2.9h-9.5v-1.5h7.8v-1.3h-5.9v-1.5h5.9v-1.4h1.9v1.4h4v1.5h-4v1.3h2.3c1.3-0.7,2.7-1.9,4.3-3.6
			l1.6,0.8c-0.8,0.9-1.8,1.9-3.1,2.8h3.8V314.9z M1121,308.2h-2.4v1.5h-1.9v-1.5h-4.6v-1.5h4.6v-1.3h1.9v1.3h2.4V308.2z M1126.7,319
			v-1.2h-8.3c-0.1,0.1-0.2,0.1-0.4,0.2v1H1126.7z M1126.7,321.7v-1.3h-8.8v1.3H1126.7z M1130.7,308.2h-4.5v1.5h-1.9v-1.5h-2.4v-1.5
			h2.4v-1.3h1.9v1.3h4.5V308.2z"/>
		<path class="st7" d="M1138.1,317.2c-0.9,1-2,1.9-3.4,2.7l-1.3-1.4c1.1-0.6,2.1-1.3,2.8-2.1L1138.1,317.2z M1152.4,316.3h-18.7
			v-1.5h8.5v-1h-7.5v-1.4h7.5v-0.9h-6.8v-5.9h15.4v5.9h-6.7v0.9h7.4v1.4h-7.4v1h8.4V316.3z M1152.4,323.8h-18.7v-1.6h8.4v-1.1h-6.4
			v-1.5h6.4v-1.3h2v1.3h6.4v1.5h-6.4v1.1h8.3V323.8z M1142.2,310v-2.8h-4.9v2.8H1142.2z M1141.3,308.7l-1,1c-0.8-0.5-1.5-1-2.2-1.3
			l1-1.1C1140,307.8,1140.8,308.3,1141.3,308.7z M1141.8,318.8l-1.8,0.4c-0.4-1.2-0.7-1.9-0.8-2.3l1.7-0.3
			C1141.3,317.2,1141.5,317.9,1141.8,318.8z M1146.9,318.8l-1.8,0.5c-0.3-0.8-0.7-1.5-1.1-2.1l1.8-0.5
			C1146.2,317.2,1146.6,317.9,1146.9,318.8z M1148.9,310v-2.8h-4.9v2.8H1148.9z M1148.1,308.3c-0.7,0.6-1.4,1.1-2.2,1.5l-1-1.1
			c0.7-0.4,1.4-0.8,2.1-1.3L1148.1,308.3z M1152.7,318.9l-1.4,1.1c-0.7-0.8-1.7-1.7-2.9-2.7l1.7-0.9
			C1151.1,317.2,1152,318.1,1152.7,318.9z"/>
		<path class="st7" d="M1171.5,308.5c-3.5,3.7-8.5,6.1-15.2,7.4l-0.9-1.7c3.2-0.5,5.7-1.2,7.6-2c-0.6-0.7-1.6-1.6-3-2.5
			c-1.3,0.7-2.4,1.2-3.3,1.5l-1.1-1.4c3-1.2,5.5-2.8,7.5-4.7l1.8,0.7c-0.2,0.3-0.5,0.6-0.9,1h7.6V308.5z M1173.9,316.2
			c0,0.2-0.4,0.8-1.3,1.8c-0.9,1-1.8,1.9-2.8,2.5s-2.2,1.3-3.5,1.8c-1.3,0.5-2.7,1-4.3,1.3s-3.3,0.5-5,0.5l-0.8-1.8
			c3.5,0.1,6.7-0.5,9.3-1.6c-0.6-0.8-1.6-1.7-3-2.7c-1.5,0.8-3,1.3-4.5,1.7l-1.2-1.5c4-1.1,7.4-2.9,10.1-5.7l1.8,0.7
			c-0.3,0.4-0.7,0.8-1.1,1.2h6.2V316.2z M1168.9,308.5h-6.9c-0.1,0.1-0.2,0.1-0.4,0.3c1.2,0.9,2.3,1.7,3.2,2.6
			C1166.4,310.5,1167.8,309.6,1168.9,308.5z M1171.9,316.2h-6.2c-0.2,0.1-0.6,0.5-1.5,1c1.1,0.8,2.1,1.7,3.1,2.8
			C1169.3,318.9,1170.8,317.6,1171.9,316.2z"/>
		<path class="st7" d="M1195.7,319.7h-7.3v4.4h-2v-4.4h-9.4v-1.9h2.8v-5.5h6.5v-3.2h-4.9c-1.2,1.4-2.3,2.6-3.3,3.6l-1.4-1.4
			c2-1.7,3.7-3.8,5-6.2l2,0.4c-0.2,0.6-0.5,1.2-0.9,1.7h12.1v1.8h-6.6v3.2h6.1v1.8h-6.1v3.6h7.3V319.7z M1186.4,317.8v-3.6h-4.6v3.6
			H1186.4z"/>
		<path class="st7" d="M1207.4,317.1c-0.6-0.2-0.9-0.7-0.9-1.3c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.6-0.4,1-0.4c0.4,0,0.7,0.1,1,0.4
			c0.3,0.3,0.4,0.7,0.4,1.1c0,0.8-0.6,1.7-1.8,2.6l-0.6-0.5C1207.1,317.7,1207.3,317.4,1207.4,317.1z"/>
		<path class="st7" d="M957.6,357.7h-18.7v-1.4h5.1c-0.2-0.6-0.4-1.1-0.7-1.5H941v-3.7h14.4v3.7h-2.3c-0.3,0.6-0.5,1.1-0.8,1.5h5.2
			V357.7z M956.6,347.9h-16.7v-8.1h1.7v6.8h2.1v-0.9h-1.4v-1.2h1.4v-0.9h-1.2v-1.1h1.2v-0.9h-1.4v-1.2h1.4v-1h1.6v1h1.4v1.2h-1.4
			v0.9h1.2v1.1h-1.2v0.9h1.4v1.2h-1.4v0.9h2.1v-7.2h1.7v7.2h2.1v-0.9h-1.5v-1.2h1.5v-0.9h-1.2v-1.1h1.2v-0.9h-1.5v-1.2h1.5v-1h1.5v1
			h1.4v1.2h-1.4v0.9h1.2l0,1.1h-1.2v0.9h1.4v1.2h-1.4v0.9h2.2v-6.7h1.7V347.9z M956.1,350.2h-15.7v-1.4h15.7V350.2z M953.5,353.5
			v-1.1h-10.6v1.1H953.5z M951.1,354.8h-5.7c0.2,0.5,0.5,1,0.7,1.5h4.2C950.6,355.8,950.9,355.3,951.1,354.8z"/>
		<path class="st7" d="M978.8,344.7h-1.8v-2.6h-14.2v2.6h-1.9v-4.2h8c-0.1-0.3-0.3-0.7-0.6-1.1l2.1-0.4l0.7,1.5h7.8V344.7z
			 M978.2,357.9h-1.9v-0.6h-12.9v0.6h-1.9v-7.8h16.6V357.9z M976.6,349.2H963v-3.7h13.5V349.2z M968.9,353v-1.3h-5.5v1.3H968.9z
			 M968.9,355.9v-1.4h-5.5v1.4H968.9z M976,344.6h-12.4v-1.5H976V344.6z M974.6,347.8v-0.9H965v0.9H974.6z M976.3,353v-1.3h-5.5v1.3
			H976.3z M976.3,355.9v-1.4h-5.5v1.4H976.3z"/>
		<path class="st7" d="M990.3,357.8h-1.8v-1.4h-3.9v1.6h-1.9v-15.6h1.9c0.5-1,1-2.1,1.3-3.3l2,0.3c-0.1,0.7-0.4,1.7-1,2.9h3.5V357.8
			z M988.4,348.3v-4h-3.9v4H988.4z M988.4,354.6v-4.5h-3.9v4.5H988.4z M1000.6,342.5c0,2.1-0.1,4.7-0.2,8c-0.1,3.3-0.3,5.2-0.6,5.8
			c-0.3,0.6-0.7,1-1.2,1.3c-0.5,0.3-1.8,0.4-3.8,0.4l-0.7-1.9l2.9,0c0.3,0,0.6-0.1,0.9-0.3c0.3-0.2,0.5-1.8,0.7-4.7s0.2-5.1,0.2-6.7
			h-4.3c-0.7,1.7-1.4,3.2-2.3,4.4l-1.5-1.3c1.5-2.5,2.6-5.3,3.2-8.3l1.9,0.4c-0.1,0.7-0.4,1.7-0.8,3H1000.6z M997.5,352.1l-1.7,1.1
			c-0.9-1.8-1.9-3.4-3-4.6l1.7-1C996,349.3,997,350.8,997.5,352.1z"/>
		<path class="st7" d="M1006.3,353.1c-0.2,1.6-0.5,3.3-1,5l-1.6-0.8c0.6-1.9,0.9-3.4,1-4.6L1006.3,353.1z M1011.3,342.7
			c-1.3,2.8-2.9,5.3-4.7,7.3c0.8-0.1,1.6-0.3,2.4-0.6c-0.1-0.4-0.2-0.7-0.4-1l1.5-0.6c0.5,1.1,0.9,2.4,1.1,3.7l-1.5,0.5
			c-0.1-0.3-0.1-0.6-0.2-0.9c-1.9,0.5-3.6,0.8-5.2,1.1l-0.5-1.6c1.2-1.1,2.4-2.6,3.6-4.5c-1,0.2-2,0.4-3,0.5l-0.5-1.7
			c1.3-1.5,2.4-3.5,3.2-5.9l1.8,0.7c-0.8,1.9-1.6,3.5-2.6,4.8c0.6-0.1,1.3-0.2,2.3-0.5c0.3-0.5,0.7-1.2,1.1-2.2L1011.3,342.7z
			 M1008.9,357l-1.7,0.4c0-0.9-0.1-2.3-0.4-4.2l1.5-0.4C1008.7,354.3,1008.8,355.7,1008.9,357z M1011,355.2l-1.5,0.4
			c-0.2-1.2-0.4-2-0.6-2.6l1.4-0.4C1010.7,353.2,1010.9,354.1,1011,355.2z M1015,349.4l-1.6,0.7c-0.7-1.3-1.5-2.7-2.6-4.2
			c1-1.3,1.7-2.6,2.3-3.8l1.7,0.7c-0.4,0.9-1,1.9-1.9,3.2C1013.9,347.5,1014.6,348.7,1015,349.4z M1022.5,357.6h-11.5v-1.8h4.8v-3.3
			h-4.2v-1.8h10.2v1.8h-4.1v3.3h4.7V357.6z M1022.2,341.4h-10.8v-1.8h10.8V341.4z M1018.7,349.4l-1.7,0.8c-0.7-1.5-1.6-3-2.6-4.3
			c0.8-1,1.6-2.3,2.3-3.8l1.8,0.7c-0.6,1.2-1.3,2.2-1.9,3.1C1017.7,347.6,1018.4,348.8,1018.7,349.4z M1022.3,349.4l-1.7,0.8
			c-0.7-1.4-1.5-2.7-2.5-4.2c1-1.4,1.7-2.7,2.3-3.9l1.6,0.7c-0.3,0.7-0.9,1.8-1.8,3.2C1021.1,347.2,1021.8,348.3,1022.3,349.4z"/>
		<path class="st7" d="M1026.8,351.2c0,1.7-0.2,3.5-0.6,5.3l-0.9-0.3c0.4-1.6,0.6-3.3,0.6-5.1L1026.8,351.2z M1033,341.3h-2.1v1.3
			h1.5v1.5h-1.5v1.4h1.5v1.5h-1.5v1.5h1.9c0,4.6-0.1,7.3-0.4,8.1c-0.3,0.8-1,1.2-2.3,1.2c-0.2,0-0.4,0-0.8,0l-0.6-1.7l1.8,0
			c0.2,0,0.3-0.1,0.4-0.3c0.1-0.2,0.2-2.1,0.3-5.6h-5v-10.6h6.8V341.3z M1028.2,355.9l-1,0.2c0-0.7,0.1-1.3,0.1-2.1
			c0-0.9,0-1.8-0.1-2.8l0.9-0.1c0.1,1.1,0.2,2.2,0.2,3.4C1028.2,355.1,1028.2,355.5,1028.2,355.9z M1029.3,342.7v-1.3h-1.4v1.3
			H1029.3z M1029.3,345.6v-1.4h-1.4v1.4H1029.3z M1029.3,348.6v-1.5h-1.4v1.5H1029.3z M1029.7,355.2l-0.9,0.2c0-1.5-0.1-2.9-0.3-4.1
			l0.8-0.1C1029.5,352.2,1029.6,353.5,1029.7,355.2z M1030.8,354.3l-0.8,0.3c0-1.1-0.1-2.2-0.4-3.6l0.7-0.2
			C1030.6,351.8,1030.8,353,1030.8,354.3z M1038.3,355.6l-1.2,1.3c-0.4-0.5-0.9-1-1.2-1.3c-0.5,1-1.1,1.8-1.7,2.5l-1.6-1
			c1.1-1.2,2-2.8,2.7-4.9l1.8,0.4c-0.1,0.5-0.3,0.9-0.5,1.3C1037.3,354.5,1037.9,355,1038.3,355.6z M1044.5,344l-0.8,1.7
			c-0.9-0.5-1.6-1-2.3-1.5v1.1h-5.7v-1.1c-0.9,0.7-1.5,1.2-2,1.5l-0.9-1.8c2.5-1.5,4.2-3.2,5.2-4.9l1.9,0.4
			c-0.1,0.1-0.2,0.3-0.3,0.5C1040.7,341.4,1042.3,342.8,1044.5,344z M1038,351.8h-4.3v-5.5h4.3V351.8z M1036.5,350.2v-2.2h-1.3v2.2
			H1036.5z M1040.7,343.7c-0.7-0.6-1.5-1.4-2.2-2.3c-0.7,0.8-1.4,1.6-2.3,2.3H1040.7z M1044.3,356.6l-1.3,1.4
			c-0.5-0.7-1.2-1.4-2.1-2.1c-0.5,0.8-1,1.5-1.6,2.3l-1.7-1c1.2-1.4,2.2-3,2.8-5l1.8,0.3c-0.1,0.5-0.3,1-0.6,1.7
			C1042.8,355,1043.7,355.8,1044.3,356.6z M1043.3,351.8h-4.4v-5.5h4.4V351.8z M1041.8,350.2v-2.2h-1.3v2.2H1041.8z"/>
		<path class="st7" d="M1054.6,355.1c-1.7,1.2-3.8,2.2-6.4,3.2l-1.1-1.6c2.4-0.8,4.3-1.7,5.9-2.7L1054.6,355.1z M1065.8,353.6h-18.7
			v-1.8h1.4l-0.2-11.2c1.4-0.3,2.8-0.8,4.1-1.4l1,1.6c-0.9,0.4-2,0.8-3.2,1.2l0,1.9h2.4v1.7h-2.4l0,2.2h2.4v1.7h-2.4l0,2.3h11.9
			l0.1-2.3h-2.2v-1.7h2.3l0.1-2.2h-2.3v-1.7h2.4l0-2.1h-2.4V340h4.4l-0.3,11.8h1.5V353.6z M1056,346.4c-0.3,1.8-0.9,3.4-1.6,4.7
			l-1.5-1c0.7-1.4,1.2-2.8,1.4-4.1L1056,346.4z M1059.4,342.2h-3.5l-0.2,1.5h3.7c0,1.2,0,2.5-0.1,4c-0.1,1.4-0.2,2.3-0.4,2.6
			c-0.2,0.3-0.5,0.5-0.8,0.7s-0.9,0.2-1.9,0.1l-0.6-1.8c0.3,0,0.6,0.1,0.9,0.1c0.3,0,0.6,0,0.7-0.1c0.1-0.1,0.2-0.1,0.2-0.2
			c0-0.1,0.1-0.5,0.1-1.4c0.1-0.8,0.1-1.6,0.1-2.4h-3.9l0.8-6h1.8l-0.1,1.2h3.2V342.2z M1065.9,356.6l-1.2,1.6
			c-2.1-1.2-4.1-2.1-6.2-2.7l1.2-1.6C1061.9,354.7,1064,355.6,1065.9,356.6z"/>
		<path class="st7" d="M1087.5,354.2h-4.2v1.8c0,0.6-0.2,1-0.5,1.4s-1,0.6-2,0.6c-0.4,0-1.1,0-2-0.1l-0.5-1.7c1.4,0,2.3,0,2.6,0
			c0.3,0,0.5-0.2,0.5-0.5v-1.6h-12.6v-1.5h12.6V352h1.9v0.7h4.2V354.2z M1087.3,351.4l-1.6,0.9c-0.4-0.5-0.7-0.9-0.9-1.1
			c-6,0.3-11,0.5-15,0.4l-0.5-1.5c1,0,2.2,0,3.6,0c1.8,0,3.2,0,4.3-0.1V349h-6.5v-6.2h6.5v-1h-8.1v-1.5h8.1v-1.1h1.8v1.1h8v1.5h-8v1
			h6.6v6.2h-0.6C1085.9,349.8,1086.6,350.6,1087.3,351.4z M1077.2,356.4l-1.4,1.3c-0.8-0.6-1.9-1.2-3.3-1.9l1.4-1.3
			C1075.2,355.1,1076.3,355.7,1077.2,356.4z M1077.2,345.3v-1h-4.7v1H1077.2z M1077.2,347.5v-1h-4.7v1H1077.2z M1083.8,345.3v-1
			h-4.8v1H1083.8z M1083.8,347.5v-1h-4.8v1H1083.8z M1083.9,349h-4.8v1.1c2-0.1,3.4-0.1,4.4-0.3c-0.2-0.2-0.3-0.3-0.4-0.4
			L1083.9,349z"/>
		<path class="st7" d="M1109.1,352.9h-8.4v5.1h-1.9v-5.1h-8.4v-1.6h8.4v-1.1h-7.4v-1.6h7.4v-1h-7.9v-1.6h4.9
			c-0.2-0.3-0.5-0.6-0.7-0.9l1.4-0.8h-6.1v-1.6h6.6v-3.2h1.9v3.2h1.9v-3.2h1.9v3.2h6.5v1.6h-5.9l1.3,0.7c-0.3,0.5-0.5,0.8-0.7,1h4.9
			v1.6h-7.9v1h7.4v1.6h-7.4v1.1h8.4V352.9z M1097.3,354.7c-2,1.4-3.8,2.5-5.6,3.2l-1.2-1.5c1.7-0.6,3.5-1.6,5.3-3L1097.3,354.7z
			 M1095.4,341.4l-1.4,1c-0.6-0.7-1.3-1.4-1.9-1.9l1.4-1.1C1094.3,340.1,1094.9,340.7,1095.4,341.4z M1102.5,344.2h-5.6
			c0.4,0.5,0.8,1.1,1.2,1.7h3.2C1101.8,345.4,1102.2,344.8,1102.5,344.2z M1109.5,356.3l-1,1.5c-2.1-1.2-4.1-2.2-6.1-2.9l1.1-1.5
			C1105.4,354.1,1107.4,355.1,1109.5,356.3z M1107.5,340.3c-0.4,0.6-1,1.2-1.8,2l-1.7-1c0.7-0.5,1.3-1.2,1.9-1.9L1107.5,340.3z"/>
		<path class="st7" d="M1120.2,357.8h-1.8v-1.4h-3.9v1.6h-1.9v-15.6h1.9c0.5-1,1-2.1,1.3-3.3l2,0.3c-0.1,0.7-0.4,1.7-1,2.9h3.5
			V357.8z M1118.4,348.3v-4h-3.9v4H1118.4z M1118.4,354.6v-4.5h-3.9v4.5H1118.4z M1130.6,342.5c0,2.1-0.1,4.7-0.2,8
			c-0.1,3.3-0.3,5.2-0.6,5.8c-0.3,0.6-0.7,1-1.2,1.3c-0.5,0.3-1.8,0.4-3.8,0.4l-0.7-1.9l2.9,0c0.3,0,0.6-0.1,0.9-0.3
			c0.3-0.2,0.5-1.8,0.7-4.7s0.2-5.1,0.2-6.7h-4.3c-0.7,1.7-1.4,3.2-2.3,4.4l-1.5-1.3c1.5-2.5,2.6-5.3,3.2-8.3l1.9,0.4
			c-0.1,0.7-0.4,1.7-0.8,3H1130.6z M1127.5,352.1l-1.7,1.1c-0.9-1.8-1.9-3.4-3-4.6l1.7-1C1125.9,349.3,1126.9,350.8,1127.5,352.1z"
			/>
		<path class="st7" d="M1140.7,348.9c-0.8,0.4-1.6,0.7-2.2,0.9v6.2c0,0.6-0.2,1.1-0.5,1.4s-0.8,0.5-1.5,0.5c-0.7,0-1.3,0-1.8,0
			l-0.6-1.9c0.8,0,1.4,0,1.9,0c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4v-5c-0.8,0.3-1.6,0.5-2.5,0.7l-0.5-1.8
			c1.3-0.4,2.3-0.7,3-1v-4h-2.7v-1.8h2.7v-3.5h1.9v3.5h2v1.8h-2v3.2c0.6-0.2,1.2-0.4,1.8-0.8L1140.7,348.9z M1152.6,356.6l-1.1,1.6
			c-2-0.9-3.7-1.9-5-3c-1.5,1.2-3.4,2.1-5.5,3l-1.1-1.7c2.1-0.7,3.8-1.5,5.2-2.5c-1.2-1.3-2.2-3.2-3-5.5h-0.7v-1.8h4.2v-2.8h-4.5
			v-1.8h4.5v-2.7h1.9v2.7h4.5v1.8h-4.5v2.8h4v1.8c-1,2.4-2.2,4.3-3.6,5.6C1149.1,355,1150.7,355.9,1152.6,356.6z M1149.4,348.4h-5.3
			c0.5,1.6,1.4,3,2.5,4.3C1147.8,351.6,1148.7,350.1,1149.4,348.4z"/>
		<path class="st7" d="M1160.5,340c-1.5,2.3-3,4-4.4,5.1l-0.9-1.8c1.2-0.8,2.4-2.2,3.7-4.2L1160.5,340z M1160.2,345.2
			c-0.5,0.9-0.9,1.6-1.3,2.3v10.5h-1.8v-7.8c-0.3,0.3-0.6,0.7-1.1,1.2l-0.9-1.9c1.4-1.6,2.6-3.4,3.6-5.2L1160.2,345.2z
			 M1162.4,346.4c0,4.3-0.4,7.6-1.2,9.7l-1.6-0.7c0.9-2.2,1.4-5.2,1.3-9H1162.4z M1168.7,344.6h-3.5v13.3h-1.9v-13.3h-3.1v-1.8h3.1
			v-3.6h1.9v3.6h3.5V344.6z M1168.5,341.5l-1.4,1c-0.3-0.6-0.8-1.3-1.5-2.1l1.4-0.9C1167.7,340,1168.1,340.7,1168.5,341.5z
			 M1169.7,354.7h-2.1c-0.4,0-0.7-0.1-1-0.4s-0.4-0.6-0.4-1v-7h1.7v6.1c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.4,0.2h1.3V354.7z
			 M1174.1,347.2h-1.5v8.9c0,0.4-0.1,0.7-0.3,1.1c-0.2,0.3-0.5,0.5-0.8,0.7c-0.3,0.1-0.9,0.2-1.7,0.2c-0.3,0-0.5,0-0.8,0l-0.6-1.9
			c0.4,0,0.8,0,1.1,0c0.6,0,0.9-0.1,1-0.2c0.1-0.1,0.2-0.3,0.2-0.5v-8.2h-2.1v-1.9h5.5V347.2z M1173.6,341.8h-4.4v-1.9h4.4V341.8z"
			/>
		<path class="st7" d="M1196,356l-0.6,1.7c-1.4,0-2.5,0-3.4,0c-2,0-3.6,0-5-0.1c-1.3-0.1-2.5-0.4-3.4-0.8s-1.7-1.1-2.4-1.9
			c-1.1,1.5-2.1,2.6-3,3.3l-1.3-1.5c1.7-1.1,3-3,4.1-5.6l1.8,0.5c-0.2,0.6-0.5,1.2-0.8,1.8c0.7,1.1,2,1.9,3.7,2.4v-5.4h-8.6v-1.7
			h18.7v1.7h-8.1v1.9h7v1.7h-7v2C1189,355.9,1191.7,356,1196,356z M1193.3,347.2h-13.8v-7.6h13.8V347.2z M1191.4,342.7v-1.3h-9.8
			v1.3H1191.4z M1191.4,345.5v-1.3h-9.8v1.3H1191.4z"/>
		<path class="st7" d="M1204.7,339.7c-0.5,0.9-1.1,2.1-1.9,3.4v8l-1.9,0v-5.4c-0.3,0.3-0.7,0.8-1.4,1.4l-1.1-1.6
			c1.6-1.4,3.1-3.5,4.5-6.3L1204.7,339.7z M1202.9,352.2c-0.8,2.2-1.6,3.9-2.6,5.2l-1.6-1.1c0.9-1.1,1.7-2.6,2.5-4.7L1202.9,352.2z
			 M1217,340.7c-0.4,1.7-0.8,3-1.2,3.9l-1.8-0.6c0.2-0.5,0.4-1,0.6-1.5h-7c-0.9,1.3-1.8,2.3-2.7,3l-1.4-1.3c1.3-1.1,2.7-2.9,4-5.2
			l1.7,0.7c-0.2,0.5-0.4,0.8-0.5,0.9H1217z M1213.4,354.6c-0.2,1.2-0.5,1.9-0.7,2.3c-0.3,0.4-0.6,0.6-1.2,0.8
			c-0.5,0.1-1.7,0.2-3.6,0.2c-1.7,0-2.8-0.1-3.3-0.4c-0.6-0.3-0.8-1-0.8-2v-3.9h1.9v3.8c0,0.3,0.1,0.6,0.4,0.7
			c0.3,0.1,1,0.2,2.1,0.2c1.2,0,2,0,2.3-0.1s0.6-0.2,0.7-0.5s0.3-0.8,0.4-1.6L1213.4,354.6z M1208.9,345.6c-1.2,2-2.3,3.6-3.4,4.7
			l-1.5-1.2c1.1-1,2.1-2.5,3.3-4.5L1208.9,345.6z M1210.7,353.8l-1.5,1.1c-0.7-1.1-1.5-2.2-2.5-3.2l1.4-1.1
			C1209.4,351.9,1210.2,352.9,1210.7,353.8z M1211.7,351.1h-1.9v-7.7h1.9V351.1z M1217.4,349l-1.5,1.1c-0.9-1.5-2.1-3-3.4-4.4l1.5-1
			C1215.4,346.1,1216.5,347.5,1217.4,349z M1217.5,356.5l-1.6,1c-0.8-1.8-1.8-3.4-2.9-4.9l1.6-1
			C1215.7,353.1,1216.7,354.8,1217.5,356.5z"/>
		<path class="st7" d="M1239.1,357.3h-13.6v0.7h-1.9v-8.3c-0.7,0.7-1.4,1.3-2,1.8l-1.3-1.6c2.3-1.7,4-3.6,5.3-5.7h-4.7v-1.8h5.7
			c0.4-0.8,0.8-1.9,1.3-3.2l1.9,0.5c-0.3,1-0.7,1.9-1,2.7h10v1.8h-11c-0.7,1.2-1.4,2.3-2.3,3.4v7.8h5.4v-5h-4.4v-1.8h4.4v-3.4h2v3.4
			h4.7v1.8h-4.7v5h6.1V357.3z"/>
		<path class="st7" d="M957.7,379.8l-0.9,1.7c-0.6-0.1-1.2-0.3-1.9-0.5v8.1h-9l0.9,1.1c-2,0.9-4.4,1.6-7.1,2.1l-0.9-1.7
			c2-0.2,4-0.7,5.9-1.4h-3.2v-8.1h4.1l-0.8-1.2c2.5-0.6,4.4-1.7,5.6-3.3l1.9,0.4c-0.2,0.4-0.4,0.6-0.5,0.7
			C953.3,378.5,955.3,379.3,957.7,379.8z M945.2,379.2h-6.2v-1.8h6.2V379.2z M945,375.8h-5.8V374h5.8V375.8z M953,383.2v-0.8h-9.6
			v0.8H953z M953,385.4v-0.8h-9.6v0.8H953z M953,387.6v-0.8h-9.6v0.8H953z M957.3,376c-0.7,0.9-1.3,1.7-1.9,2.4l-1.6-1
			c0.2-0.2,0.6-0.7,1.2-1.4h-6.8c-0.7,0.8-1.3,1.4-1.9,1.9l-1.5-1.2c1.1-0.8,2.2-2,3.2-3.6l1.8,0.6c-0.1,0.2-0.2,0.5-0.4,0.8h7.9
			V376z M954.7,380.9c-1.4-0.5-2.8-1.2-4.2-2.1c-0.9,0.8-2.1,1.5-3.5,2.1H954.7z M957.6,390.6l-1,1.7c-1.9-0.6-4-1-6.3-1.3l1-1.6
			C953.5,389.5,955.6,389.9,957.6,390.6z"/>
		<path class="st7" d="M967.5,376.8h-7v-1.7h2.7c-0.1-0.3-0.3-0.8-0.7-1.3l1.9-0.7c0.4,0.7,0.7,1.4,0.9,2h2.1V376.8z M967,380h-6
			v-1.7h6V380z M967,383.1h-6v-1.7h6V383.1z M967,391.4h-1.9v-1h-2.3v1.5H961v-7.4h6V391.4z M965.1,388.7v-2.4h-2.3v2.4H965.1z
			 M979.4,386.9c-0.1,1.1-0.3,2.1-0.6,3.1c-0.3,0.9-0.6,1.5-0.8,1.7s-0.6,0.3-0.9,0.3c-0.4,0-0.8-0.2-1.1-0.5
			c-0.3-0.3-0.6-1.3-0.9-2.9c-0.3-1.6-0.4-3-0.4-4.3v-8.9h-2.9v5.8h2.3v1.9h-2.3v8.9h-1.8v-8.9h-2v-1.9h2v-5.8h-1.7v-1.8h8.3v11.2
			c0,1.7,0.2,3.3,0.7,4.8c0.3-1,0.4-2.2,0.5-3.8L979.4,386.9z"/>
		<path class="st7" d="M989.6,390.4c0,0.4-0.2,0.8-0.5,1.1c-0.3,0.3-1.3,0.5-2.9,0.4l-0.6-1.9c0.5,0.1,0.9,0.1,1.2,0.1
			c0.4,0,0.6,0,0.7-0.1c0.1-0.1,0.2-0.2,0.2-0.3v-4.6h-2.4c-0.2,2.8-0.7,5.1-1.5,6.9l-1.7-1c0.9-2,1.3-4.6,1.3-7.8v-9.5h6.1V390.4z
			 M987.7,378.4v-2.8h-2.3v2.8H987.7z M987.7,383.3v-3h-2.3v3H987.7z M1001.1,390.5l-1.2,1.5c-0.9-0.5-1.8-1.2-2.8-2.1
			c-0.9,0.9-1.7,1.6-2.4,2.1l-1.3-1.4c1-0.7,1.8-1.4,2.4-2.1c-1.3-1.9-2.1-3.9-2.5-6H993v9.5h-1.9v-18.4h9.4c0,1.1-0.1,2.2-0.3,3.3
			c-0.2,1.1-0.4,1.7-0.6,2c-0.2,0.3-0.5,0.5-0.9,0.6c-0.4,0.2-1.3,0.2-2.8,0.2l-0.8-1.8c1.8,0,2.7-0.1,2.9-0.3
			c0.2-0.3,0.3-1,0.4-2.2H993v5.2h7.3c-0.3,3.3-1,5.9-2.1,7.7C998.8,389,999.8,389.7,1001.1,390.5z M998.2,382.5h-3
			c0.3,1.4,0.8,2.8,1.7,4.3C997.5,385.8,997.9,384.4,998.2,382.5z"/>
		<path class="st7" d="M1012.3,379.8c-0.2,2.1-0.6,3.7-1.2,5l-1.5-0.4c0.4-0.9,0.6-1.9,0.8-2.8h-1.2v8.5c0,0.4-0.1,0.8-0.4,1.1
			c-0.2,0.3-0.5,0.5-0.8,0.6c-0.3,0.1-0.8,0.2-1.4,0.2c-0.3,0-0.7,0-1.1,0l-0.6-1.8c0.4,0,1.1,0,1.9,0c0.3,0,0.5-0.2,0.5-0.6v-4.9
			c-0.8,1.9-1.7,3.5-2.7,4.7l-1-1.8c1.2-1.4,2.3-3.3,3.3-5.9h-3.1v-1.7h4.2c-0.6-0.7-1.6-1.5-2.9-2.4l1.3-1.2c0.5,0.3,1,0.6,1.7,1.1
			c0.5-0.5,1-1.2,1.5-1.9h-5.1v-1.8h7.3v1.6c-0.5,0.9-1.3,2-2.4,3.2c0.1,0.1,0.3,0.3,0.5,0.6l-0.9,0.8H1012.3z M1021.6,384.7
			c-0.2,3.5-0.5,5.4-0.6,5.9c-0.2,0.4-0.5,0.7-0.9,1c-0.4,0.3-1.1,0.4-2.1,0.4c-0.3,0-0.6,0-1,0l-0.6-1.8c0.4,0,0.8,0,1.3,0
			c0.7,0,1.1-0.1,1.3-0.2c0.2-0.1,0.3-0.4,0.5-0.9c0.1-0.5,0.2-1.4,0.3-2.7h-2.8c-0.3,1.2-0.9,2.3-1.9,3.3c-1,1-2.2,1.8-3.6,2.3
			l-1.2-1.5c2.6-1,4.2-2.4,4.8-4.1h-2.8v-1.8h3.2c0.1-0.7,0.2-1.3,0.2-1.7l1.8,0.1c0,0.2,0,0.8-0.1,1.6H1021.6z M1022.8,381.9
			l-0.9,1.6c-1.9-0.4-3.5-1.1-4.8-2.1c-1.3,0.9-2.5,1.5-3.8,2l-1-1.6c1.2-0.3,2.3-0.9,3.4-1.6c-0.4-0.4-0.9-1-1.5-1.7l1.4-1
			c0.7,0.8,1.1,1.3,1.5,1.6c0.7-0.8,1.3-1.6,1.7-2.5h-3.7c-0.8,1-1.6,1.8-2.4,2.6l-1.3-1.2c1.4-1.2,2.8-2.9,4-5l1.7,0.6
			c-0.2,0.3-0.4,0.8-0.8,1.3h5.9v1.7h-1.6c-0.6,1.5-1.3,2.7-2.2,3.5C1019.6,381,1021,381.6,1022.8,381.9z"/>
		<path class="st7" d="M1044.1,391.4h-18.7v-1.9h7.7v-16.3h2v6.4h8.1v1.9h-8.1v8h9V391.4z"/>
		<path class="st7" d="M1065.8,382.4h-9.6v9.6h-1.8v-1.8c-2.8,0.3-5.2,0.5-7,0.5l-0.5-1.7h1.8v-6.6h-1.6v-1.6h18.7V382.4z
			 M1063.2,380h-1.8v-4.9h-9.8v4.9h-1.8v-6.5h13.4V380z M1054.4,383.4v-1h-3.8v1H1054.4z M1054.4,386v-1.2h-3.8v1.2H1054.4z
			 M1054.4,388.5v-1.1h-3.8v1.4C1052.4,388.7,1053.7,388.6,1054.4,388.5z M1060.6,377.5h-8.4v-1.4h8.4V377.5z M1060.6,379.8h-8.4
			v-1.4h8.4V379.8z M1065.9,390.5l-1,1.7c-1.5-0.6-2.7-1.3-3.6-2c-1.2,0.9-2.5,1.5-3.8,2l-1-1.6c1.1-0.3,2.2-0.8,3.4-1.6
			c-0.9-0.9-1.6-2.2-2.3-3.7h-0.7v-1.7h8.1c-0.3,1.9-1.1,3.7-2.4,5.4C1063.6,389.6,1064.7,390.1,1065.9,390.5z M1062.7,385.2h-3.2
			c0.4,1,1,1.8,1.7,2.6C1061.8,387,1062.3,386.2,1062.7,385.2z"/>
		<path class="st7" d="M1074.8,373.5c-0.3,0.8-0.7,1.8-1.1,2.8l-0.7,1.5v14.1h-1.9v-10.6c-0.4,0.6-0.9,1.2-1.4,1.8l-1-2
			c1.6-1.8,3-4.5,4.3-8.1L1074.8,373.5z M1087.6,391.4H1074v-1.9h5.8v-3.1h-5v-1.8h5v-2.3h1.9v2.3h5v1.8h-5v3.1h5.9V391.4z
			 M1087.2,381.6h-13v-1.9h5.4v-2.5h-4.8v-1.9h4.8v-2.2h2v2.2h4.8v1.9h-4.8v2.5h5.6V381.6z"/>
		<path class="st7" d="M1098.5,391.8h-1.8v-1.4h-3.9v1.6h-1.9v-15.6h1.9c0.5-1,1-2.1,1.3-3.3l2,0.3c-0.1,0.7-0.4,1.7-1,2.9h3.5
			V391.8z M1096.7,382.2v-4h-3.9v4H1096.7z M1096.7,388.5V384h-3.9v4.5H1096.7z M1108.9,376.5c0,2.1-0.1,4.7-0.2,8
			c-0.1,3.3-0.3,5.2-0.6,5.8c-0.3,0.6-0.7,1-1.2,1.3c-0.5,0.3-1.8,0.4-3.8,0.4l-0.7-1.9l2.9,0c0.3,0,0.6-0.1,0.9-0.3
			c0.3-0.2,0.5-1.8,0.7-4.7s0.2-5.1,0.2-6.7h-4.3c-0.7,1.7-1.4,3.2-2.3,4.4l-1.5-1.3c1.5-2.5,2.6-5.3,3.2-8.3l1.9,0.4
			c-0.1,0.7-0.4,1.7-0.8,3H1108.9z M1105.8,386.1l-1.7,1.1c-0.9-1.8-1.9-3.4-3-4.6l1.7-1C1104.3,383.3,1105.2,384.8,1105.8,386.1z"
			/>
		<path class="st7" d="M1130.8,388.1h-4.2v1.8c0,0.6-0.2,1-0.5,1.4s-1,0.6-2,0.6c-0.4,0-1.1,0-2-0.1l-0.5-1.7c1.4,0,2.3,0,2.6,0
			c0.3,0,0.5-0.2,0.5-0.5v-1.6h-12.6v-1.5h12.6v-0.7h1.9v0.7h4.2V388.1z M1130.6,385.3l-1.6,0.9c-0.4-0.5-0.7-0.9-0.9-1.1
			c-6,0.3-11,0.5-15,0.4l-0.5-1.5c1,0,2.2,0,3.6,0c1.8,0,3.2,0,4.3-0.1V383h-6.5v-6.2h6.5v-1h-8.1v-1.5h8.1v-1.1h1.8v1.1h8v1.5h-8v1
			h6.6v6.2h-0.6C1129.2,383.8,1129.9,384.6,1130.6,385.3z M1120.5,390.3l-1.4,1.3c-0.8-0.6-1.9-1.2-3.3-1.9l1.4-1.3
			C1118.5,389,1119.6,389.6,1120.5,390.3z M1120.5,379.2v-1h-4.7v1H1120.5z M1120.5,381.5v-1h-4.7v1H1120.5z M1127.1,379.2v-1h-4.8
			v1H1127.1z M1127.1,381.5v-1h-4.8v1H1127.1z M1127.2,383h-4.8v1.1c2-0.1,3.4-0.1,4.4-0.3c-0.2-0.2-0.3-0.3-0.4-0.4L1127.2,383z"/>
		<path class="st7" d="M1152.6,389.5l-1.1,1.6c-2.5-1.4-4.3-2.9-5.5-4.4c0,0.3,0,0.6,0,1c0,1.6-0.2,2.8-0.7,3.4
			c-0.5,0.6-1.4,0.9-2.8,0.9c-0.4,0-0.8,0-1.4,0l-0.6-1.8c1,0,1.7,0.1,2.1,0.1c0.5,0,0.9-0.1,1-0.2s0.3-0.3,0.4-0.5s0.1-0.9,0.1-2
			c0-0.3,0-0.7,0-1c-2.7,2.3-5.9,3.9-9.7,4.8l-0.8-1.6c3.9-0.8,7.3-2.5,10.2-5.2c-0.1-0.4-0.3-0.8-0.5-1.1c-2.6,2-5.5,3.3-8.8,4
			l-0.8-1.6c3.1-0.5,6-1.8,8.8-3.8c0-0.1-0.3-0.3-0.7-0.8c-2,1-4.3,1.8-6.9,2.2l-0.9-1.6c2.9-0.3,5.6-1.1,8.1-2.5h-5.2v-1.7h12.1
			v1.7h-4.4c-0.3,0.3-0.8,0.7-1.3,1c0.9,1,1.5,2,1.9,3c1.5-0.7,2.9-1.8,4.2-3.1l1.4,1.2c-1.3,1.2-2.8,2.3-4.5,3.2
			C1147.8,386.5,1149.8,388.1,1152.6,389.5z M1151.9,378.7h-1.9v-2.2h-13.9v2.2h-1.9v-3.9h7.7c-0.2-0.4-0.5-0.8-0.8-1.3l2-0.4
			c0.3,0.5,0.7,1.1,1,1.7h7.8V378.7z"/>
		<path class="st7" d="M1164.6,384.7h-3.6v0.5c1.4,1,2.6,1.9,3.5,2.8l-1.1,1.4c-0.8-0.8-1.6-1.6-2.3-2.3v4.7h-1.8v-4.6
			c-0.9,1.3-1.9,2.4-2.9,3.2l-1.1-1.5c1.7-1.2,2.9-2.7,3.6-4.3h-3.4V383h3.8v-1.5h-3v-8h7.8v8h-3v1.5h3.6V384.7z M1159.2,376.7v-1.5
			h-1.3v1.5H1159.2z M1159.2,379.9v-1.5h-1.3v1.5H1159.2z M1162.3,376.7v-1.5h-1.3v1.5H1162.3z M1162.3,379.9v-1.5h-1.3v1.5H1162.3z
			 M1174,384.1c0,0.3-0.3,0.9-1,1.8c-0.7,0.9-1.3,1.7-1.9,2.3s-1.2,1.1-1.9,1.6c-0.7,0.5-1.4,0.9-2.2,1.3c-0.8,0.4-1.6,0.7-2.4,1
			l-1.1-1.5c2-0.7,3.7-1.6,5-2.5c-0.5-0.5-1-1.1-1.7-1.6l-1.5,1l-1.3-1.3c2.5-1.5,4.2-3.2,5.3-5.1l1.6,0.6c-0.1,0.2-0.2,0.5-0.4,0.7
			h3.5V384.1z M1173.2,376.3c0,0.2-0.3,0.7-0.9,1.4c-0.6,0.8-1.2,1.4-1.7,1.9c-0.5,0.5-1,0.9-1.6,1.4c-0.6,0.4-1.1,0.8-1.7,1.2
			s-1.2,0.7-1.8,1l-1.1-1.5c1-0.4,2.2-1.1,3.6-2.1c-0.5-0.5-1-1-1.7-1.5c-0.1,0.1-0.4,0.3-0.7,0.5l-1.2-1.4c1.8-1.3,3.2-2.7,4.1-4.2
			l1.7,0.6c-0.2,0.3-0.4,0.6-0.6,0.9h3.7V376.3z M1171.1,376.3h-2.8c-0.1,0.1-0.3,0.4-0.7,0.7c0.7,0.5,1.2,1,1.8,1.5
			C1169.8,378,1170.4,377.2,1171.1,376.3z M1171.9,384.1h-2.7c-0.3,0.4-0.7,0.7-1.1,1.1c0.7,0.6,1.3,1.1,1.8,1.7
			C1170.7,386.1,1171.4,385.1,1171.9,384.1z"/>
		<path class="st7" d="M1182.9,373.5c-0.2,0.7-0.5,1.5-1,2.6l-0.7,1.5v14.3h-1.9v-10.8c-0.6,0.8-1,1.5-1.5,1.9l-0.9-2
			c1.6-2.1,2.9-4.7,4.1-7.9L1182.9,373.5z M1195.7,386.8h-5.9v5.2h-1.9v-5.2h-6v-1.8h6v-2.7h-5.2v-1.9h5.2v-7.2h1.9v7.2h5.1v1.9
			h-5.1v2.7h5.9V386.8z M1186.7,379l-1.8,0.7c-0.5-1.8-1.2-3.4-1.9-4.9l1.8-0.7C1185.5,375.4,1186.1,377.1,1186.7,379z M1195,374.8
			c-0.6,1.7-1.3,3.3-2.2,4.9l-1.7-0.9c0.7-1.2,1.4-2.7,2.1-4.6L1195,374.8z"/>
		<path class="st7" d="M1208,388.2c0.4,0,0.8,0.1,1.1,0.4s0.5,0.6,0.5,1c0,0.4-0.1,0.7-0.4,1s-0.7,0.4-1.1,0.4
			c-0.4,0-0.8-0.1-1.1-0.4c-0.3-0.3-0.4-0.6-0.4-1c0-0.4,0.1-0.8,0.4-1C1207.2,388.4,1207.6,388.2,1208,388.2z M1209.2,374.7
			l-0.3,11.5h-1.9l-0.3-11.5H1209.2z"/>
	</g>
</g>
<g>
	<path class="st9" d="M1204.8,410.5c0.4,0.9,0.5,2,0.2,2.9l-0.6,3.8l-0.7,0.5l-2.5,1c0.2-0.5,0.2-1,0-1.5l-1-4c0,0-1.7-6.6,2.8-6.3
		c0-5.4,1.5-4.5,1.5-4.5l0.1,4.6L1204.8,410.5z"/>
	<path class="st10" d="M1215.9,423.6c0,0-3.1,12-5.8,12.2c-2.7,0.2-5.5-18.9-5.5-18.9l-3.7,0.5c0,0-1.4,27.6,6.8,28.1
		c8.2,0.5,9.2-5.6,9.2-5.6L1215.9,423.6z"/>
	<path class="st11" d="M1216.3,456.3h21.4v-32.4c-6.1-9.3-13.7-6.8-13.7-6.8s-6.9,0.1-8,6.5C1215.9,428.2,1216.3,456.3,1216.3,456.3
		z"/>
	<path class="st12" d="M1226.5,412.8l0.3,4.6l-0.2,0.1c-1.5,1.4-3.9,1.5-5.4,0.1l0,0l-0.1-3.5L1226.5,412.8z"/>
	<path class="st9" d="M1228.1,403.2l-0.4,7.5c-0.7,0-1.1,0.6-1.1,1.2c0,0.1,0,0.2,0,0.2c-0.1,0.2-0.1,0.4-0.1,0.6
		c-2,1.4-4.4,2-6.8,1.6c-4.8-0.9-1.9-10.2-1.9-10.2L1228.1,403.2z"/>
	<path class="st13" d="M1230.5,408.9c0.2-0.8,0.7-1.5,1.2-2.1c2.7-2.4,0.3-4.1-0.6-4.6s-0.8-2.4-2-3.3c-1.9-1.5-3.7,0.1-4.9-0.2
		c-1.2-0.3-2-1.8-3.7-0.9c-1.7,0.8-1.6,2.3-2.2,2.8c-0.6,0.6-1.6-1-3.3,0.8c-2.6,2.8,2.5,4,2.5,4c2.2,0.7,4.4,0.6,6.5-0.3
		c0.5-0.2,1.1-0.1,1.4,0.4c0.8,1.2,0.9,7.5,0.9,7.5l1.2-1C1227.7,412,1230.4,411.4,1230.5,408.9z"/>
	<path class="st9" d="M1227.6,409.5c-0.3,0.6-0.9,0.8-1.5,0.7c-0.6-0.3-0.8-0.9-0.7-1.5c0.3-0.6,0.9-0.8,1.5-0.7
		C1227.6,408.2,1227.9,409,1227.6,409.5L1227.6,409.5z"/>
	<path class="st13" d="M1221.1,517.5c0,0-1.2,1.7-6.9,3.1c-1.2,0.3-2.6,0.6-2.6,2.6h5.7l5.8-0.9v0.9h3.1c0,0,0.8-5.1-0.9-5.8H1221.1
		z"/>
	<path class="st9" d="M1220.8,513.9l0.3,3.6l0,0c1.1,0.8,2.6,0.8,3.8,0.1l0.2-0.1v-3.6H1220.8z"/>
	<path class="st13" d="M1237,517.5c0,0-1.2,1.7-6.9,3.1c-1.2,0.3-2.6,0.6-2.6,2.6h5.6l5.8-0.9v0.9h3.1c0,0,0.8-5.1-0.9-5.8H1237z"/>
	<path class="st9" d="M1236.8,513.9l0.3,3.6l0,0c1.1,0.8,2.6,0.8,3.8,0.1l0.2-0.1v-3.6H1236.8z"/>
	<path class="st11" d="M1237.6,423.8c0,0,6.4,14.8-1.8,22.3s-19.2,6.2-19.2,6.2v-4.2c0,0,10-0.8,13.2-7.5c3.2-6.7-1.2-12.4-1.2-12.4
		"/>
	<path class="st9" d="M1217.3,451.8l-5.1,0.1c-2.1-0.1-3.5-3.2-3.8-5.6c0-0.3,0.2-0.5,0.4-0.6c0.1,0,0.2,0,0.2,0
		c2.5,0.8,5,1.8,7.4,2.9L1217.3,451.8z"/>
	<path class="st14" d="M1216.3,456.3c-6.4,18,3.6,57.9,3.6,57.9h5.7c0,0-2.6-56.5,4.7-57.9L1216.3,456.3L1216.3,456.3z"/>
	<path class="st10" d="M1231,436.2c-0.1,1.5-0.5,3.1-1.1,4.4c-3.2,6.7-13.8,7.6-13.8,7.6v4.3c0,0,11.6,1.2,19.8-6.3
		c3-2.7,4-6.4,4.1-10L1231,436.2L1231,436.2z"/>
	<path class="st15" d="M1230.9,436.9l0.7-0.3c0.2-3.1-0.8-6.2-2.5-8.8l-0.5,0.4C1230.4,430.7,1231.2,433.7,1230.9,436.9z"/>
	<path class="st9" d="M1203.8,411c0,0,1.1-0.4,1.3-2.5c0.2-2.2,1.2-1.5,1.2-1.5s-0.6,8.5-1.9,8.8S1203.8,411,1203.8,411z"/>
	<path class="st16" d="M1222.6,456.3c-1.7,22.1,12.3,57.9,12.3,57.9h6.8c0,0-4.6-22.9-4.7-44.3c0-4.5,0.2-9.1,0.7-13.5L1222.6,456.3
		z"/>
</g>
</svg>

</div>
  <!--團隊介紹-->
  <div class="features-1">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto text-center">
          <h3 class="display-3 mt-2">HOW WE DO IT</h3>
          <p class="mt-4"> 與客戶需求結合，才能讓專精技術更強大<br  />原碼數位科技本位即在資訊領域系統的專案管理與開發。<br  />始終提供完整商用APP及商用客製系統解決方案。<br  />特別在：旅遊應用、Beacon應用、開放資料應用、Line應用、<br  />影像監控應用，以及金融(銀行與保險公司)應用等領域上。</p>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-4">
          <div class="info">
            <div class="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle">
              <i class="ni ni-settings-gear-65"></i>
            </div>
            <h6 class="info-title text-uppercase text-primary">管理整合系統</h6>
          </div>
        </div>
        <div class="col-md-4">
          <div class="info">
            <div class="icon icon-lg icon-shape icon-shape-success shadow rounded-circle">
              <i class="ni ni-atom"></i>
            </div>
            <h6 class="info-title text-uppercase text-success">即時回饋技術</h6>
          </div>
        </div>
        <div class="col-md-4">
          <div class="info">
            <div class="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle">
              <i class="ni ni-world"></i>
            </div>
            <h6 class="info-title text-uppercase text-warning">物聯與大數據分析</h6>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-4">
          <div class="info">
            <div class="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle">
              <i class="ni ni-lock-circle-open"></i>
            </div>
            <h6 class="info-title text-uppercase text-primary">系統安全</h6>
          </div>
        </div>
        <div class="col-md-4">
          <div class="info">
            <div class="icon icon-lg icon-shape icon-shape-success shadow rounded-circle">
              <i class="ni ni-pin-3"></i>
            </div>
            <h6 class="info-title text-uppercase text-success">定位技術</h6>
          </div>
        </div>
        <div class="col-md-4">
          <div class="info">
            <div class="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle">
              <i class="ni ni-settings"></i>
            </div>
            <h6 class="info-title text-uppercase text-warning">多種語言開發</h6>
          </div>
        </div>
      </div>
    </div>
  </div>  
</main>
