<div class="section section-hero section-shaped">
  <div class="shape shape-style-3"><img class="bg-image" src="assets/img/pages/contact.jpg" style="width: 100%;">
  </div>
  <div class="container pt-lg-5 wow fadeInUp">
    <div class="row">
      <div class="col-lg-6 mt-4">
        <div class="card bg-secondary shadow border-0" style="opacity: 90%; height: 570pt;">
          <div class="card-header bg-white pb-3">
            <div class="text-muted text-center mb-2"><h4 style="color: #666666;">聯 絡 我 們</h4></div>
          </div>
          <div class="card-body px-lg-5 py-lg-4">
            <div class="text-center text-muted mb-4">
              <small>點此留下資料，讓我們能提供更好的協助。</small>
            </div>
            <form role="form" [formGroup]="messageForm" (ngSubmit)="onSubmit()">
              <p class="mx-2 text-dark alert-link">01 / 您的資料</p>
              <div class="form-group mb-3">
                <div class="input-group input-group-alternative">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="ni ni-email-83"></i></span>
                  </div>
                  <input type="email" class="form-control form-control-alternative" id="exampleFormControlInput1"
                    placeholder="name@example.com" required="" formControlName="messageEmail">
                </div>
              </div>
              <div class="form-group mb-3">
                <div class="input-group input-group-alternative">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-phone"></i></span>
                  </div>
                  <input type="email" class="form-control form-control-alternative" id="exampleFormControlInput1"
                    placeholder="聯絡電話" required="" formControlName="messagePhone">
                </div>
              </div>
              <div class="form-group mb-3">
                <div class="input-group input-group-alternative">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="ni ni-circle-08"></i></span>
                  </div>
                  <input type="text" class="form-control form-control-alternative" id="exampleFormControlInput1"
                    placeholder="姓名" required="" formControlName="messagerName">
                </div>
              </div>
              <p class="mx-2 text-dark alert-link">02 / 您需要的服務類別？</p>
              <div class="row mx-2">
                <div class="custom-control custom-checkbox mb-3 col-sm-6">
                  <input class="custom-control-input" id="customCheck1" type="checkbox" formControlName="app">
                  <label class="custom-control-label" for="customCheck1">
                    <p>APP 應用程式開發</p>
                  </label>
                </div>
                <div class="custom-control custom-checkbox mb-3 col-sm-6">
                  <input class="custom-control-input" id="customCheck2" type="checkbox" formControlName="web">
                  <label class="custom-control-label" for="customCheck2" >
                    <p>Web 網頁開發</p>
                  </label>
                </div>
              </div>
              <div class="row mx-2">
                <div class="custom-control custom-checkbox mb-3 col-sm-6">
                  <input class="custom-control-input" id="customCheck3" type="checkbox" formControlName="customization">
                  <label class="custom-control-label" for="customCheck3" >
                    <p>客製化系統開發</p>
                  </label>
                </div>
                <div class="custom-control custom-checkbox mb-3 col-sm-6">
                  <input class="custom-control-input" id="customCheck4" type="checkbox" formControlName="bussiness">
                  <label class="custom-control-label" for="customCheck4" >
                    <p>企業級應用ERP</p>
                  </label>
                </div>
              </div>
              <p class="mx-2 text-dark alert-link">03 / 您的詳細需求</p>
              <div class="form-group focused">
                <div class="input-group input-group-alternative">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-file"></i></span>
                  </div>
                  <!-- <form> -->
                  <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="" formControlName="messageDetail"></textarea>
                  <!-- </form> -->
                </div>
              </div>
              <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="alert">
                <span class="alert-inner--text">您的留言我們收到了，我們將會盡快與您聯絡!</span>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true" (click)="back()">×</span>
                </button>
              </div>
              <div class="text-center">
                <button type="submit" class="btn btn-default my-4" *ngIf="!alert" [disabled]="messageForm.invalid">確認送出</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- google map -->
      <div class="col-lg-6 iframe-rwd mt-4">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3616.054004325118!2d121.48397831459673!3d24.99828084587731!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346803bc3ce40875%3A0x48ae1dd34aed34de!2z5Y6f56K85pW45L2N56eR5oqAIFNvdXJjZWNvZGUgSW5jLg!5e0!3m2!1szh-TW!2stw!4v1598410122644!5m2!1szh-TW!2stw"
          width="468" height="790" frameborder="0" style="border-radius:3px; opacity: 90%;" allowfullscreen=""
          aria-hidden="false" tabindex="0"></iframe>
      </div>
    </div>
  </div>
</div>