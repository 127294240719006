<!-- page header -->
<main class=" ">
    <div class="position-relative">
        <!-- Hero for FREE version -->
        <section class="section section-lg section-hero section-shaped">
          <!-- Background circles -->
          <div class="shape shape-style-1 shape-primary">
            <!-- <span class="span-150"></span>
            <span class="span-50"></span>
            <span class="span-50"></span>
            <span class="span-75"></span>
            <span class="span-100"></span>
            <span class="span-75"></span>
            <span class="span-50"></span>
            <span class="span-100"></span>
            <span class="span-50"></span>
            <span class="span-100"></span> -->
          </div>
          <div class="page-header">
            <div class="container shape-container d-flex align-items-center py-lg">
              <div class="col px-0">
                <div class="row align-items-center justify-content-center">
                  <div class="col-lg-6 text-center">
                    <h1 class="text-white display-2 wow fadeIn" style="padding-bottom: 5%;">原碼札記</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- SVG separator -->
          <div class="separator separator-bottom separator-skew zindex-100">
            <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <polygon class="fill-white" points="2560 0 2560 100 0 100"></polygon>
            </svg>
          </div>
        </section>
      </div>
    <!-- main body -->
    <!--外部新聞-->
    <section class="mt-5">
      <div *ngFor="let item of list | reverse ;let i = index">
      <a href={{item.newsUrl}} *ngIf="item.newsCat==2 && item.apply==1">
      <hr style="width: 90%;">
      <div class="features-6">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 mx-md-auto">
              <img class=" newspic" src="{{ApiPath}}/api_backend/imageFile/files/news/{{item.photo}}" width="400px">
            </div>
            <div class="col-lg-6">
              <div class="news">{{item.newsCat |newsCats}}</div>
                <div class="info info-horizontal info-hover-primary">
                  <div class="description">
                    <h5 class="title">{{item.newsTitle}}</h5>
                    <h6>{{item.newsDate|date}}</h6>
                    <p style="max-height: 100px;overflow: hidden;text-overflow: ellipsis;">
                      <span  style="font-size: 40px;" [innerHTML]="item.newsContent"></span>
                    </p>             
                        <a href={{item.newsUrl}} class="text-info">See more</a>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      </a>
      <!--Blog-->
      <a href="https://www.sourcecode.tw/Blog/#/info/{{item.newsId}}"  *ngIf="item.newsCat!=2 && item.apply==1">
        <hr style="width: 90%;">
        <div class="features-6">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-6 mx-md-auto">
                <img class=" newspic" src="{{ApiPath}}/api_backend/imageFile/files/news/{{item.photo}}" width="400px">
              </div>
              <div class="col-lg-6">
                <div class="news">{{item.newsCat |newsCats}}</div>
                  <div class="info info-horizontal info-hover-primary">
                    <div class="description">
                      <h5 class="title">{{item.newsTitle}}</h5>
                      <h6>{{item.newsDate|date}}</h6>
                      <p style="max-height: 100px;overflow: hidden;text-overflow: ellipsis;">
                        <span  style="font-size: 40px;" [innerHTML]="item.newsContent"></span>
                      </p>             
                          <a href="https://www.sourcecode.tw/Blog/#/info/{{item.newsId}}" class="text-info">See more</a>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
        </a>
    </div>
      <hr style="width: 90%;">
    </section>
  </main>