import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
   name: 'reverse' 
  })

export class ReversePipe implements PipeTransform {
  transform(value) {
    return value.slice().reverse();
  }
}

@Pipe({
  name: 'newsCats'
})
export class NewsCatsPipe implements PipeTransform {
  transform(value: number, ...args: any[]): string {
    if (value === 0) {
      return '資訊安全';
    } else if(value === 1){
      return '學長姐說';
    }
    else if(value ===2){
      return '科技新知';
    }

  }
}