import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


const API_URL = environment.serviceUrl;
@Injectable({
  providedIn: 'root'
})
export class MessageService {

  private _listners = new Subject<any>();
  filter(filterBy: string) {
    this._listners.next(filterBy);
  }
  constructor(private http:HttpClient) { }

  sMessage(param: any){
    return this.http.post<any>(`${API_URL}/api_backend/saveMessage`, param).pipe(map(data => {
      return data;
    }));
  }

  sendMail(param: any){
    return this.http.post<any>(`${API_URL}/api_backend/sendmail`, param).pipe(map(data => {
      return data;
    }));
  }
}
