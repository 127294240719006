import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { SignupComponent } from './signup/signup.component';
// import { LandingComponent } from './landing/landing.component';
// import { LoginComponent } from './login/login.component';
import { ApplicationComponent } from './application/application.component';
import { WebsiteComponent } from './website/website.component';
import { SystemComponent } from './system/system.component';
import { AboutComponent } from './about/about.component';
import { BoardComponent } from './board/board.component';
import { SuccessComponent } from './success/success.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { NewsComponent } from './news/news.component';
import { P2pComponent } from './p2p/p2p.component';

const routes: Routes =[
    { path: 'home',             component: HomeComponent },
    { path: 'user-profile',     component: ProfileComponent },
    { path: 'register',         component: SignupComponent },
    // { path: 'landing',       component: LandingComponent },
    // { path: 'login',         component: LoginComponent },
    { path: 'application',      component: ApplicationComponent },
    { path: 'website',          component: WebsiteComponent },
    { path: 'system',           component: SystemComponent },
    { path: 'about',            component: AboutComponent },
    { path: 'board',            component: BoardComponent },
    { path: 'success',          component: SuccessComponent },
    { path: 'privacy',          component: PrivacyComponent },
    { path: 'news',          component: NewsComponent },
    { path: 'p2p',          component: P2pComponent },
    { path: '', redirectTo: 'home', pathMatch: 'full' },

];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
})
export class AppRoutingModule { }
