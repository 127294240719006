import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {

  constructor(private http: HttpClient) { }

  getAlertList(){

    let news = {
      "time": 1500000000
    };

    let url = "http://localhost:4200/info_center/get_alert_list";

    this.http.post<any>(url, news).subscribe(res =>{

      console.log(res);

    })
  }

  ngOnInit(): void {

    this.getAlertList();

  }

}
