<main class=" ">
    <section class="section-profile section-shaped my-0" style="background:  #2e2a2a;">
        <div class="pricing-wrapper clearfix mt-7 mb-5">
            <!-- Titulo -->
            <h1 class="pricing-table-title"> 企業獨立視訊通話App  <a href="http://sourcecode.tw">Sourcecode.tw</a></h1>

            <div class="pricing-table">
                <h4 class="pricing-title">Basic</h4>
                <div class="price">$?????<sup>/ year </sup></div>
                <!-- Lista de Caracteristicas / Propiedades -->
                <ul class="table-list">
                    <li>500 <span> Device </span></li>
                    <li> GCP or AWS Server 私有雲 </li>
                    <li> 獨立管理後台  </li>
                    <li> 會員及通話資料查詢及報表 </li>
                    <li> Logo 及 UI 企業客制化調整 </li>
                    <li> 獨立 App 可上架至Google play </li>
                    <li> 3個月 系統維護 </li>
                </ul>
                <!-- Contratar / Comprar -->
                <div class="table-buy">
                    <p>$?????<sup>/ year</sup></p>
                    <a href="#" class="pricing-action">Comprar</a>
                </div>
            </div>

            <div class="pricing-table recommended">
                <h3 class="pricing-title">Premium</h3>
                <div class="price">$?????<sup>/ year </sup></div>
                <!-- Lista de Caracteristicas / Propiedades -->
                <ul class="table-list">
                    <li>1000 <span> Device </span></li>
                    <li> GCP or AWS Server 私有雲 </li>
                    <li> 獨立管理後台  </li>
                    <li> 會員及通話資料查詢及報表 </li>
                    <li> Logo 及 UI 企業客制化調整 </li>
                    <li> 獨立 App 可上架至Google play </li>
                    <li> 3個月 系統維護 </li>
                </ul>
                <!-- Contratar / Comprar -->
                <div class="table-buy">
                    <p>$?????<sup>/ year</sup></p>
                    <a href="#" class="pricing-action">Comprar</a>
                </div>
            </div>


            <div class="pricing-table">
                <h3 class="pricing-title">Ultimate</h3>
                <div class="price">$?????<sup>/ year </sup></div>
                <!-- Lista de Caracteristicas / Propiedades -->
                <ul class="table-list">
                    <li> 2000 <span> Device </span></li>
                    <li> GCP or AWS Server 私有雲 </li>
                    <li> 獨立管理後台  </li>
                    <li> 會員及通話資料查詢及報表 </li>
                    <li> Logo 及 UI 企業客制化調整 </li>
                    <li> 獨立 App 可上架至Google play </li>
                    <li> 3個月 系統維護 </li>
                </ul>
                <!-- Contratar / Comprar -->
                <div class="table-buy">
                    <p>$?????<sup>/ year</sup></p>
                    <a href="#" class="pricing-action">Comprar</a>
                </div>
            </div>
        </div>
    </section>
</main>