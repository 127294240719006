import { Component, OnInit } from '@angular/core';
import { NgwWowService } from 'ngx-wow';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.css']
})
export class ApplicationComponent implements OnInit {

  constructor(private wowService: NgwWowService) {
    this.wowService.init();
   }
   
  ngOnInit(): void {
    this.wowService.init();
  }
}
