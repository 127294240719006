export const environment = {
  production: true,
  // serviceUrl: 'http://localhost:8080/ScService',
  // backendUrl: 'http://localhost:8080/ScBackend'

  serviceUrl: 'https://www.sourcecode.tw/ScService',
  backendUrl: 'https://www.sourcecode.tw/ScBackend'


};
