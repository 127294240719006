<nav id="navbar-main" class="navbar navbar-main navbar-expand-lg navbar-transparent navbar-light headroom">
  <div class="container">
    <a class="navbar-brand mr-lg-5" [routerLink]="['/home']">
      <img src="assets/img/brand/white.png">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar_global" aria-controls="navbar_global" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="navbar-collapse collapse" id="navbar_global">
      <div class="navbar-collapse-header">
        <div class="row">
          <div class="col-6 collapse-brand">
            <a [routerLink]="['/home']">
              <img src="assets/img/brand/blue.png">
            </a>
          </div>
          <div class="col-6 collapse-close">
            <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbar_global" aria-controls="navbar_global" aria-expanded="false" aria-label="Toggle navigation">
            </button>
          </div>
        </div>
      </div>
      
      <ul class="navbar-nav navbar-nav-hover align-items-lg-center ml-lg-auto">
        <li class="nav-item dropdown">
          <a href="#" class="nav-link" data-toggle="dropdown" href="#" role="button">
            <i class="ni ni-collection d-lg-none"></i>
            <span class="nav-link-inner--text">服務</span>
          </a>
          <div class="dropdown-menu">
            <a [routerLink]="['/application']" class="dropdown-item">APP開發</a>
            <a [routerLink]="['/system']" class="dropdown-item">企業應用開發</a>
            <a [routerLink]="['/website']" class="dropdown-item">Web開發</a>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a href="#" class="nav-link" data-toggle="dropdown" href="#" role="button">
            <i class="ni ni-collection d-lg-none"></i>
            <span class="nav-link-inner--text">原碼</span>
          </a>
          <div class="dropdown-menu">
            <a [routerLink]="['/about']" class="dropdown-item">關於我們</a>
            <a [routerLink]="['/user-profile']" class="dropdown-item">原碼團隊</a>
            <a [routerLink]="['/board']" class="dropdown-item">聯繫我們</a>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a [routerLink]="['/success']" class="nav-link">
            <i class="ni ni-collection d-lg-none"></i>
            <span>專案分享</span>
          </a>
        </li>
        <li class="nav-item dropdown">
          <a  class="nav-link"  data-toggle="dropdown" role="button">
            <i class="ni ni-collection d-lg-none"></i>
            <span>原碼產品</span>
          </a>
          <div class="dropdown-menu">
            <a href="https://www.sourcecode.tw/PMS/" target="_blank" class="dropdown-item">EasyDo-專案管理系統</a>
            <a href="https://www.sourcecode.tw/Salary/" target="_blank" class="dropdown-item">人事薪資管理系統</a>
            <a href="https://www.sourcecode.tw/BMS/" target="_blank" class="dropdown-item">後台管理系統</a>
            <a href="https://www.sourcecode.tw/P2P/" target="_blank" class="dropdown-item">KhaiKang-企業獨立視訊通話APP</a>
            <a href="https://www.sourcecode.tw/ISO/" target="_blank" class="dropdown-item">ISO文件管理系統</a>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a [routerLink]="['/news']" class="nav-link">
            <i class="ni ni-collection d-lg-none"></i>
            <span>原碼札記</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>