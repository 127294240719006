import { MessageService } from './../_service/massage/message.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';



@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.css']
})
export class BoardComponent implements OnInit {

  alert:boolean=false;
  message={
    messageEmail:null, messagePhone:null,messagerName:null,app:false,web:false,customization:false,bussiness:false,messageDetail:null
  }

  messageForm=new FormGroup({
    messageEmail:new FormControl(this.message.messageEmail, [Validators.required]),
    messagePhone:new FormControl(this.message.messagePhone, [Validators.required]),
    messagerName:new FormControl(this.message.messagerName, [Validators.required]),
    app:new FormControl(this.message.app),
    web:new FormControl(this.message.web),
    customization:new FormControl(this.message.customization),
    bussiness:new FormControl(this.message.bussiness),
    messageDetail:new FormControl(this.message.messageDetail)
  });


  constructor(
    private wowService: NgwWowService,
    private httpClient: HttpClient,
    private service: MessageService,
    private location:Location
    ) {
    this.wowService.init();
  }

  // exchangeAPP(){
  //   if(this.messageForm.value.app == false){
  //     this.messageForm.value.app = true;
  //   }
  //   else if(this.messageForm.value.app == true){
  //     this.messageForm.value.app = false;
  //   }
  //   console.log(this.messageForm.value.app);
  // }

  // exchangeWEB(){
  //   if(this.messageForm.value.web == false){
  //     this.messageForm.value.web = true;
  //   }
  //   else if(this.messageForm.value.web == true){
  //     this.messageForm.value.web = false;
  //   }
  //   console.log(this.messageForm.value.web);
  // }

  // exchangeCUS(){
  //   if(this.messageForm.value.customization== false){
  //     this.messageForm.value.customization = true;
  //   }
  //   else if(this.messageForm.value.customization == true){
  //     this.messageForm.value.customization = false;
  //   }
  //   console.log(this.messageForm.value.customization);
  // }

  
  // exchangeBUS(){
  //   if(this.messageForm.value.bussiness == false){
  //     this.messageForm.value.bussiness = true;
  //   }
  //   else if(this.messageForm.value.bussiness == true){
  //     this.messageForm.value.bussiness = false;
  //   }
  //   console.log(this.messageForm.value.bussiness);
  // }
  //checkbox資料型態轉換
  translate(){
    if (this.messageForm.value.app == null || this.messageForm.value.app == false) {
      this.messageForm.value.app = 0;
      
    } else if(this.messageForm.value.app == true ){
      this.messageForm.value.app = 1;
    }

    if (this.messageForm.value.web == null || this.messageForm.value.web == false) {
      this.messageForm.value.web = 0;
      
    } else if(this.messageForm.value.web == true) {
      this.messageForm.value.web = 1;
    }
    
    if (this.messageForm.value.customization == null || this.messageForm.value.customization == false) {
      this.messageForm.value.customization = 0;
      
    } else if(this.messageForm.value.customization = true){
      this.messageForm.value.customization = 1;
    }

    if (this.messageForm.value.bussiness == null || this.messageForm.value.bussiness == false) {
      this.messageForm.value.bussiness = 0;
    } else if(this.messageForm.value.bussiness == true){
      this.messageForm.value.bussiness = 1;
    }
    console.log(this.message.app,this.message.web,this.message.customization,this.message.bussiness)
    
    }

  onSubmit(){
    this.translate()
    console.log(this.messageForm.value)
        this.service.sMessage(this.messageForm.value).subscribe((result)=>{
          console.log("result is here",result)
          if (this.messageForm.value.app==1){
            this.messageForm.value.app="APP 應用程式開發";
          }
          else{
            this.messageForm.value.app="";
          }
          if (this.messageForm.value.web==1){
            this.messageForm.value.web="Web 網頁開發";
          }
          else{
            this.messageForm.value.web="";
          }
          if (this.messageForm.value.customization==1){
            this.messageForm.value.customization="客製化系統開發";
          }
          else{
            this.messageForm.value.customization="";
          }
          if (this.messageForm.value.bussiness==1){
            this.messageForm.value.bussiness="企業級應用ERP";
          }
          else{
            this.messageForm.value.bussiness="";
          }
          let msg=""
            msg=this.messageForm.value.app+"\t"+this.messageForm.value.web+"\t"+this.messageForm.value.customization+"\t"+this.messageForm.value.bussiness
            console.log(msg);  
            if(result.result){     
            let param={
              title:"您有一則來自"+this.messageForm.value.messagerName+"小姐/先生的留言",
              content:"留言自：原碼數位科技有限公司－官方網站\n"+
                      "連絡電話："+this.messageForm.value.messagePhone+"\n"+
                      "聯絡郵件："+this.messageForm.value.messageEmail+"\n"+
                      "需求服務："+msg+"\n"+
                      "需求敘述："+this.messageForm.value.messageDetail
            }
            this.service.sendMail(param).subscribe(data=>{})
            this.alert=true;
          }
        })
    }
  back(): void {
      this.location.back();
  }

  ngOnInit(): void {
    this.wowService.init();
  }
}
