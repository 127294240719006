import { ReversePipe, NewsCatsPipe } from './_pipe/area.pipe';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { NgwWowModule } from 'ngx-wow';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';


import { AppComponent } from './app.component';
import { SignupComponent } from './signup/signup.component';
// import { LandingComponent } from './landing/landing.component';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';


import { HomeModule } from './home/home.module';
// import { LoginComponent } from './login/login.component';
import { ApplicationComponent } from './application/application.component';
import { WebsiteComponent } from './website/website.component';
import { SystemComponent } from './system/system.component';
import { AboutComponent } from './about/about.component';
import { BoardComponent } from './board/board.component';
import { SuccessComponent } from './success/success.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { NewsComponent } from './news/news.component';
import { P2pComponent } from './p2p/p2p.component';
import { MessageService } from './_service/massage/message.service';
import { PathLocationStrategy, LocationStrategy } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    // LandingComponent,
    ProfileComponent,
    NavbarComponent,
    FooterComponent,
    // LoginComponent,
    ApplicationComponent,
    WebsiteComponent,
    SystemComponent,
    AboutComponent,
    BoardComponent,
    SuccessComponent,
    PrivacyComponent,
    NewsComponent,
    P2pComponent,
    //pipe
    ReversePipe,
    NewsCatsPipe
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    NgbModule,
    FormsModule,
    RouterModule,
    AppRoutingModule,
    HomeModule,
    NgwWowModule,
    HttpClientModule,
    ReactiveFormsModule,
    
  ],
  providers: [
    MessageService,
    {provide:LocationStrategy,useClass:PathLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
