<!--header-->
<section class="section-profile-cover section-shaped my-0">
    <div class="wrapper">
        <div class=" section-hero section-shaped">
          <div class="shape shape-style-3"><img class="bg-image" src="assets/img/pages/system02.jpg" style="width: 100%;">
          </div>
          <div class="page-header">
            <div class="container shape-container d-flex align-items-center py-lg">
              <div class="col px-0">
                <div class="row align-items-center justify-content-center">
                  <div class="col-lg-6 text-center">
                    <p class="text-white display-3 wow fadeInDown" style="padding-bottom: 10%;">企業應用開發</p>
                    <div class="btn-wrapper mt-6 wow fadeIn">
                      <a [routerLink]="['/board']" class="btn btn-sourcecode btn-icon mt-3 mb-sm-0">
                        <span class="btn-inner--text">與我們聯繫</span>
                        <span class="btn-inner--icon"><i class="ni ni-button-play"></i></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
</section>
    <!-- SVG separator -->
    <!-- <div class="separator separator-bottom separator-skew zindex-100">
      <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <polygon class="fill-white" points="2560 0 2560 100 0 100"></polygon>
      </svg>
    </div> -->
    <div class="section features-6 text-center">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 mx-md-auto mt-6">
              <div class="info info-horizontal info-hover-primary">
                <div class="description">
                  <h5 class="title"><b>我們的特色</b></h5>
                  <ul id="rigs" class="mt-5">
                    <li>
                      <a class="rigs-cell" >
                        <img class="rigs-img" src="assets/img/theme/communication.jpg">
                        <span class="rigs-overlay"></span>
                        <span class="rigs-text">
                          <p>社群應用串接</p>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a class="rigs-cell" >
                        <img class="rigs-img" src="assets/img/theme/report.jpg">
                        <span class="rigs-overlay"></span>
                        <span class="rigs-text">
                          <p>視覺化報表</p>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a class="rigs-cell" >
                        <img class="rigs-img" src="assets/img/theme/bigdata.jpg">
                        <span class="rigs-overlay"></span>
                        <span class="rigs-text">
                          <p>大數據統計</p>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a class="rigs-cell" >
                        <img class="rigs-img" src="assets/img/theme/rwd.jpg">
                        <span class="rigs-overlay"></span>
                        <span class="rigs-text">
                          <p>響應式網站</p>
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-6 mx-md-auto mt-6">
              <img class="ml-lg-5" src="assets/img/ill/system01.png" width="100%">
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-10 mx-auto text-center mb-5 mt-7">
        <h4>專案成果</h4>
          <ul id="rig" class="mt-5">
            <li>
              <a class="rig-cell" >
                <img class="rig-img" src="assets/img/theme/ecommerc.jpg">
                <span class="rig-overlay"></span>
                <span class="rig-text">
                  <p>電商網站</p>
                </span>
              </a>
            </li>
            <li>
              <a class="rig-cell" >
                <img class="rig-img" src="assets/img/theme/tree.jpg">
                <span class="rig-overlay"></span>
                <span class="rig-text">
                  <p>愛樹一生一世</p>
                </span>
              </a>
            </li>
            <li>
              <a class="rig-cell" >
                <img class="rig-img" src="assets/img/theme/smartcity.jpg">
                <span class="rig-overlay"></span>
                <span class="rig-text">
                  <p>Smart City</p>
                </span>
              </a>
            </li>
            <li>
              <a class="rig-cell" >
                <img class="rig-img" src="assets/img/theme/marketing.jpg">
                <span class="rig-overlay"></span>
                <span class="rig-text">
                  <p>App行銷官網</p>
                </span>
              </a>
            </li>
            <li>
              <a class="rig-cell" >
                <img class="rig-img" src="assets/img/theme/video.jpg">
                <span class="rig-overlay"></span>
                <span class="rig-text">
                  <p>專案管理系統</p>
                </span>
              </a>
            </li>
            <li>
              <a class="rig-cell" >
                <img class="rig-img" src="assets/img/theme/file.jpg">
                <span class="rig-overlay"></span>
                <span class="rig-text">
                  <p>文件管理系統</p>
                </span>
              </a>
            </li>
          </ul>  
      </div>
      <div class="section features-6 wow fadeInDown" style="padding-bottom:0;">
        <img class="img-fluid floating" alt="Responsive image" src="assets/img/svg/systemde.svg">
      </div>