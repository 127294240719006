<section class="section-profile-cover section-shaped my-0">
<div class="wrapper">
    <div class=" section-hero section-shaped">
      <div class="shape shape-style-3"><img class="bg-image" src="assets/img/pages/macbook.jpg" style="width: 100%;">
      </div>
      <div class="page-header">
        <div class="container shape-container d-flex align-items-center py-lg">
          <div class="col px-0">
            <div class="row align-items-center justify-content-center">
              <div class="col-lg-6 text-center">
                <p class="text-white display-3 wow fadeInDown" style="padding-bottom: 10%;">WEB 應用開發</p>
                <div class="btn-wrapper mt-6 wow fadeIn">
                  <a [routerLink]="['/board']" class="btn btn-sourcecode btn-icon mt-3 mb-sm-0">
                    <span class="btn-inner--text">與我們聯繫</span>
                    <span class="btn-inner--icon"><i class="ni ni-button-play"></i></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div></section>
    <div class="section features-6 text-center">
      <div class="section-web container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="info info-horizontal info-hover-primary">
              <div class="description wow fadeInLeft wow">
                <h5 class="title"><b>響應式網頁 (RWD) ? </b></h5>
                <p>響應式網頁設計(Responsive Web Design)或稱自適應網頁設計、回應式網頁設計等。 是一種網頁設計的技術做法，該設計<b>可使網站在不同的裝置上瀏覽時對應不同解析度皆有適合的呈現，減少使用者進行縮放、平移和捲動等操作行為</b>。 對於網站設計師和前端工程師來說，有別於過去需要針對各種裝置進行不同的設計，使用此種設計方式將更易於維護網頁。</p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 mx-md-auto mt-5 wow fadeInRight" >
            <img class="ml-lg-5" src="assets/img/ill/RWD-03.png" width="100%">
          </div>
        </div>
      </div>
    </div>
    <div class="section features-6">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-12">
            <div class="info info-horizontal info-hover-primary">
              <div class="description mt-5 wow fadeInUp text-center">
                <h5 class="title"><b>客製化解決你的問題</b></h5>
                <p>原碼數位科技客製化解決方案依照您的需求建置網站，網站能夠為您帶來更多的價值，其影響不容小覷。擁有一個與您的需求最佳符合的網站</p>
                <div class="row mt-5">
                <div class="col-md-3">
                  <div class="info mt-5">
                    <div class="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle">
                      <i class="ni ni-palette"></i>
                    </div>
                    <h6 class="info-title text-uppercase text-primary">專業主視覺設計</h6>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="info mt-5">
                    <div class="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle">
                      <i class="ni ni-archive-2"></i>
                    </div>
                    <h6 class="info-title text-uppercase text-primary">完善權限管理</h6>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="info mt-5">
                    <div class="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle">
                      <i class="ni ni-ruler-pencil"></i>
                    </div>
                    <h6 class="info-title text-uppercase text-primary">十多種標準功能</h6>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="info mt-5">
                    <div class="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle">
                      <i class="ni ni-paper-diploma"></i>
                    </div>
                    <h6 class="info-title text-uppercase text-primary">無限頁面數量</h6>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center features-6">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="info info-horizontal info-hover-primary">
              <div class="description wow fadeInLeft">
                <h5 class="title"><b>SEO大小事</b></h5>
                <p>架設網站只是第一步，提升曝光率才是架設網站的目的。搜尋引擎最佳化，是一種<b>透過了解搜尋引擎的運作規則來調整網站，以及提高目的網站在有關搜尋引擎內排名的方式</b>。由於不少研究發現，搜尋引擎的用戶往往只會留意搜尋結果最前面的幾個條目，所以不少網站都希望透過各種形式來影響搜尋引擎的排序，讓自己的網站可以有優秀的搜尋排名。</p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 mx-md-auto mt-5 wow fadeInRight">
            <img class="ml-lg-5" src="assets/img/ill/seo-05.png" width="100%">
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-10 mx-auto text-center mb-5 mt-7">
      <h4>專案成果</h4>
        <ul id="rig" class="mt-5">
          <li>
            <a class="rig-cell" >
              <img class="rig-img" src="assets/img/theme/EIP.jpg">
              <span class="rig-overlay"></span>
              <span class="rig-text">
                <p>EIP系統客製化開發</p>
              </span>
            </a>
          </li>
          <li>
            <a class="rig-cell" >
              <img class="rig-img" src="assets/img/theme/longcare.jpg">
              <span class="rig-overlay"></span>
              <span class="rig-text">
                <p>長期照護輔具租借系統</p>
              </span>
            </a>
          </li>
          <li>
            <a class="rig-cell" >
              <img class="rig-img" src="assets/img/theme/backend.jpg">
              <span class="rig-overlay"></span>
              <span class="rig-text">
                <p>App管理後台</p>
              </span>
            </a>
          </li>
          <li>
            <a class="rig-cell" >
              <img class="rig-img" src="assets/img/theme/video.jpg">
              <span class="rig-overlay"></span>
              <span class="rig-text">
                <p>企業視訊系統</p>
              </span>
            </a>
          </li>
          <li>
            <a class="rig-cell" >
              <img class="rig-img" src="assets/img/theme/project.jpg">
              <span class="rig-overlay"></span>
              <span class="rig-text">
                <p>專案管理系統</p>
              </span>
            </a>
          </li>
          <li>
            <a class="rig-cell" >
              <img class="rig-img" src="assets/img/theme/file.jpg">
              <span class="rig-overlay"></span>
              <span class="rig-text">
                <p>文件管理系統</p>
              </span>
            </a>
          </li>
        </ul>  
    </div>
    <div class=" features-6 wow fadeInDown" style="padding-bottom:0;">
      <img class="img-fluid floating" alt="Responsive image" src="assets/img/svg/webde.svg">
    </div>