<footer class="footer">
  <div class="container container-lg"
    *ngIf="getPath()!=='/user-profile' && getPath()!=='/register' && getPath()!=='/login'">
    <div class="row">

    </div>
  </div>
  <div class="container allfont">
    <div class="row row-grid align-items-center"
      [ngClass]="{'my-md': getPath()!=='/user-profile' && getPath()!=='/register' && getPath()!=='/login', 'mb-5':getPath()==='/user-profile' || getPath()==='/register' || getPath()==='/login'}">
      <div class="col-lg-2">
        <p class="text-sourcecode mb-2">原碼產品</p>
        <a href="https://www.sctw.app/P2P/" class="text-sourcecode font-weight-light">企業視訊獨立通話APP</a><br />
        <a href="https://www.sourcecode.tw/PMS/" class="text-sourcecode font-weight-light">專案管理系統</a><br />
        <a href="https://www.sourcecode.tw/BMS/" class="text-sourcecode font-weight-light">後台管理系統</a><br />
        <a href="https://www.sourcecode.tw/ISO/" class="text-sourcecode font-weight-light">ISO文件管理系統</a>
      </div>
      <div class="col-lg-2">
        <p class="text-sourcecode mb-2">原碼</p>
        <a [routerLink]="['/about']" class="text-sourcecode font-weight-light">關於我們</a><br />
        <a [routerLink]="['/success']" class="text-sourcecode font-weight-light">成功案例</a>
      </div>
      <div class="col-lg-2">
        <p class="text-sourcecode mb-2">協助</p>
        <!-- <a href="" class="text-sourcecode font-weight-light">常見問題</a><br /> -->
        <a [routerLink]="['/board']" class="text-sourcecode font-weight-light">聯繫我們</a><br />
      </div>
      <div class="col-lg-6 text-lg-right btn-wrapper">
        <button target="_blank" rel="nofollow" class="btn btn-icon-only rounded-circle" data-toggle="tooltip"
          data-original-title="instagram">
          <a class="btn-inner--icon" href="https://www.linkedin.com/company/sourcecodetw/"><i class="fa fa-linkedin"></i></a>
        </button>
        <button target="_blank" rel="nofollow" class="btn btn-icon-only rounded-circle" data-toggle="tooltip"
          data-original-title="facebook">
          <a class="btn-inner--icon" href="https://www.facebook.com/SourceCode.tw/"><i class="fa fa-facebook"></i></a>
        </button>
        <button target="_blank" rel="nofollow" class="btn btn-icon-only rounded-circle" data-toggle="tooltip"
          data-original-title="Line">
          <a class="btn-inner--icon" [routerLink]="['/board']"><i class="fa fa-comments"></i></a>
        </button>
      </div>
    </div>
    <hr>
    <div class="row align-items-center justify-content-md-between">
      <div class="col-md-8">
        <p class="text-sourcecode font-weight-light">&copy; 2020 原碼數位科技有限公司 | 新北市中和區建八路2號4樓之7 | Tel：(02)8228-0407</p>
      </div>
      <div class="col-md-4">
        <ul class="nav nav-footer justify-content-end">
          <li class="nav-item">
            <a [routerLink]="['/privacy']" class="nav-link" target="_blank" style="font-size: 0.75rem;">隱私權政策</a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link" target="_blank" style="font-size: 0.75rem;">服務條款</a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link" target="_blank" style="font-size: 0.75rem;">服務說明</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>