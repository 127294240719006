<!-- page header -->
<main class=" ">
  <section class="section-profile-cover section-shaped my-0">
    <div class="wrapper">
      <div class=" section-hero section-shaped">
        <div class="shape shape-style-3"><img class="bg-image" src="assets/img/pages/smartphone.jpg"
            style="width: 100%;">
        </div>
        <div class="page-header">
          <div class="container shape-container d-flex align-items-center py-lg">
            <div class="col px-0">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-6 text-center">
                  <p class="text-white display-3 wow fadeInDown" style="padding-bottom: 10%; padding-top: 6%;">APP 應用開發
                  </p>
                  <div class="btn-wrapper mt-6 wow fadeIn">
                    <a [routerLink]="['/board']" class="btn btn-sourcecode btn-icon mb-sm-0">
                      <span class="btn-inner--text">與我們聯繫</span>
                      <span class="btn-inner--icon"><i class="ni ni-button-play"></i></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- main body -->
  <section class="">
    <div class="section features-6 text-center">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="info info-horizontal info-hover-primary">
              <div class="description wow fadeInLeft">
                <h5 class="title">原碼是您APP開發的最佳選擇</h5>
                <p>專業的開發技術，加上多種App案例的經驗累積，必定能滿足您在App上的需求，提供最高品質的服務及產品。</p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 mx-md-auto wow fadeInRight">
            <img class="ml-lg-5 mt-5" src="assets/img/ill/app_1.svg" width="100%">
          </div>
        </div>
      </div>
    </div>
    <div class="section features-6 text-center">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 mt-5">
            <div class="info info-horizontal info-hover-primary">
              <div class="description">
                <h5 class="title wow fadeInLeft">App服務技術</h5>
                <p class="font-weight-bold mt-4 text-success">社群第三方系統登入</p>
                <p>Line, Google, Facebook, Apple...</p>
                <p class="font-weight-bold mt-4 text-success ">金流串接</p>
                <p>Apple pay, Google pay, Line pay, 綠界等多種主流線上多元支付</p>
                <p class="font-weight-bold mt-4 text-success ">Beacon應用</p>
                <p class="font-weight-bold mt-4 text-success "> IoT 應用</p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 mx-md-auto wow fadeInRight">
            <img class="ml-lg-5 mt-5 fluid" src="assets/img/ill/code_1.svg" width="100%">
          </div>
        </div>
      </div>
    </div>
    <h4 class="text-center">服務項目</h4>
    <div class="row mt-5 text-center" style="width: 80%; margin: 0px auto;">
      <div class="col-md-4">
          <div class="hi-icon-wrap hi-icon-effect-1 hi-icon-effect-1a">
            <a><i class="hi-icon hi-icon-earth"></i></a>
          </div>
            <div class="info">
              <h6 class="info-title text-uppercase text-dark mt-5 text-center">物聯網App</h6>
            </div>
      </div>
      <div class="col-md-4">
        <div class="info">
            <div class="hi-icon-wrap hi-icon-effect-1 hi-icon-effect-1a">
              <a ><i class="hi-icon hi-icon-user"></i></a>
            </div>
          <h6 class="info-title text-uppercase text-dark mt-5 ">企業App</h6>
        </div>
      </div>
      <div class="col-md-4">
        <div class="info">
            <div class="hi-icon-wrap hi-icon-effect-1 hi-icon-effect-1a">
              <a ><i class="hi-icon hi-icon-list"></i></a>
          </div>  
          <h6 class="info-title text-uppercase text-dark mt-5 text-center">購物App</h6>
        </div>
      </div>
      <div class="col-md-4">
        <div class="info">
            <div class="hi-icon-wrap hi-icon-effect-1 hi-icon-effect-1a">
              <a ><i class="hi-icon hi-icon-pencil"></i></a>
          </div>  
          <h6 class="info-title text-uppercase text-dark mt-5 text-center">活動行銷App</h6>
        </div>
      </div>
      <div class="col-md-4">
        <div class="info">
            <div class="hi-icon-wrap hi-icon-effect-1 hi-icon-effect-1a">
              <a ><i class="hi-icon hi-icon-cog"></i></a>
          </div>  
          <h6 class="info-title text-uppercase text-dark mt-5 text-center">客制App系統設計</h6>
        </div>
      </div>
      <div class="col-md-4">
        <div class="info">
            <div class="hi-icon-wrap hi-icon-effect-1 hi-icon-effect-1a">
              <a ><i class="hi-icon hi-icon-star"></i></a>
          </div>  
          <h6 class="info-title text-uppercase text-dark mt-5 text-center">創新服務App</h6>
        </div>
      </div>
    </div>
    <!-- <div class="section features-6">
      <div class="container mt-5">
        <div class="row align-items-center">
          <div class="col-md-4">
            <div class="card mb-4 box-shadow">
              <div class="card-header">
                <h4 class="my-0 font-weight-normal">電商用 APP</h4>
              </div>
              <div class="card-body">
                <h4><b>$50,000 - 200,000 </b></h4>
                <ul class="list-unstyled mt-3 mb-4">
                  <p>50,000 -</p>
                  <p>2 GB of storage</p>
                </ul> <button type="button" class="btn btn-lg btn-block btn-outline-info">Sign up for free</button>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card mb-4 box-shadow">
              <div class="card-header">
                <h4 class="my-0 font-weight-normal">簡易型 APP</h4>
              </div>
              <div class="card-body">
                <h4><b>$30,000 - 80,000 </b></h4>
                <ul class="list-unstyled mt-3 mb-4">
                  <p>20 users included</p>
                  <p>10 GB of storage</p>
                </ul> <button type="button" class="btn btn-lg btn-block btn-sourcecode">Get started</button>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card mb-4 box-shadow">
              <div class="card-header">
                <h4 class="my-0 font-weight-normal">專案類型 APP</h4>
              </div>
              <div class="card-body">
                <h4><b>$ 專人估價</b></h4>
                <ul class="list-unstyled mt-3 mb-4">
                  <p>30 users included</p>
                  <p>15 GB of storage</p>
                </ul> <button type="button" class="btn btn-lg btn-block btn-sourcecode">Contact us</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="col-md-10 mx-auto text-center mb-5 mt-7">
    <h4>專案成果</h4>
      <ul id="rig" class="mt-5">
        <li>
          <a class="rig-cell">
            <img class="rig-img" src="assets/img/theme/point.jpg">
            <span class="rig-overlay"></span>
            <span class="rig-text">
              <p>會員點數APP</p>
            </span>
          </a>
        </li>
        <li>
          <a class="rig-cell">
            <img class="rig-img" src="assets/img/theme/rabbit.jpg">
            <span class="rig-overlay"></span>
            <span class="rig-text">
              <p>韓語兔</p>
            </span>
            <span class="rig-text">
              <p><br />Krabbit APP</p>
            </span>
          </a>
        </li>
        <li>
          <a class="rig-cell">
            <img class="rig-img" src="assets/img/theme/trip.jpg">
            <span class="rig-overlay"></span>
            <span class="rig-text">
              <p>旅遊APP</p>
            </span>
          </a>
        </li>
        <li>
          <a class="rig-cell">
            <img class="rig-img" src="assets/img/theme/imember_2.png">
            <span class="rig-overlay"></span>
            <span class="rig-text">
              <p>iMember</p>
            </span>
            <span class="rig-text">
              <p><br />電商平台</p>
            </span>
          </a>
        </li>
        <li>
          <a class="rig-cell">
            <img class="rig-img" src="assets/img/theme/SmartHome.jpg">
            <span class="rig-overlay"></span>
            <span class="rig-text">
              <p>SmartHome</p>
            </span>
            <span class="rig-text">
              <p><br />智慧家庭</p>
            </span>
          </a>
        </li>
        <li>
          <a class="rig-cell">
            <img class="rig-img" src="assets/img/theme/exhibition.jpg">
            <span class="rig-overlay"></span>
            <span class="rig-text">
              <p>展覽會場APP</p>
            </span>
          </a>
        </li>
        <li>
          <a class="rig-cell">
            <img class="rig-img" src="assets/img/theme/cloud.jpg">
            <span class="rig-overlay"></span>
            <span class="rig-text">
              <p>雲遊學APP</p>
            </span>
          </a>
        </li>
        <li>
          <a class="rig-cell">
            <img class="rig-img" src="assets/img/theme/art.jpg">
            <span class="rig-overlay"></span>
            <span class="rig-text">
              <p>設計社群平台</p>
            </span>
          </a>
        </li>
      </ul>  
  </div>
    <div class="section features-6 wow fadeInDown" style="padding-bottom:0;">
      <img class="img-fluid floating" alt="Responsive image" src="assets/img/svg/appde.svg">
    </div>
  </section>
</main>