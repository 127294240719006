<div class="wrapper">
    <div class="section-hero section-shaped">
      <div class="shape shape-style-3"><img class="bg-image" src="assets/img/pages/about.jpg" style="width: 100%;">
      </div>
      <div class="page-header">
        <div class="container shape-container d-flex align-items-center py-lg">
          <div class="col px-0">
            <div class="row align-items-center justify-content-center">
              <div class="col-lg-6 text-center">
                <h1 class="text-white display-3" style="padding-bottom: 10%;">關於原碼</h1>
                <div class="btn-wrapper mt-3">
                  <a [routerLink]="['/board']" class="btn btn-sourcecode btn-icon mt-3 mb-sm-0">
                    <span class="btn-inner--text">與我們聯繫</span>
                    <span class="btn-inner--icon"><i class="ni ni-button-play"></i></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    <!-- End section -->
    <div class="section features-6 text-center">
      <div class="container">
        <div class="col-lg-12 info info-horizontal info-hover-primary mt-5">
          <div class="description">
            <h5 class="title">公司簡介</h5>
            <p class="mt-4">原碼數位科技於2016年成立。我們有著專業資訊系統建置技術與軟體開發實力，專注於APP、Web、ERP、EIP、公共服務、金融資訊、醫療照護、智慧城市、電信聯網、資訊管理顧問等專業領域專案管理系統的設計開發。成員包括軟硬體技術人才及業務、行銷、顧問、專案管理等各領域之精英，並擁有多項專業技術認證，具備優異的大型資訊系統規劃、建置與維運經驗。原碼秉持一貫的創新精神與誠信態度為客戶服務，研發創新產品，提供客戶具競爭力的智慧化解決方案及服務。

              2020年，有感於眾多企業客戶的需求，公司開始研發企業協作軟體，原碼現在是個聰明、精實的合作夥伴，為廣大的公司與組織客戶合作，而他們都有個共通點：希望以低資源、低成本在短時間內創造最大價值。原碼，就是您在數位科技上最好的夥伴！</p>
          </div>
        </div>
        <div class="row align-items-center" style="padding-bottom: 10%;">
          <div class="col-lg-6">
            <div class="info info-horizontal info-hover-primary mt-5">
              <div class="description wow fadeInUp">
                <h5 class="title">經營理念</h5>
                <div class="row mt-5">
                  <div class="col-md-4">
                    <div class="info">
                      <div class="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle">
                        <i class="ni ni-active-40"></i>
                      </div>
                      <h6 class="info-title text-uppercase text-sourcecode mt-3">精準解決問題</h6>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="info">
                      <div class="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle">
                        <i class="ni ni-satisfied"></i>
                      </div>
                      <h6 class="info-title text-uppercase text-sourcecode mt-3">人性化科技公司</h6>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="info">
                      <div class="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle">
                        <i class="ni ni-atom"></i>
                      </div>
                      <h6 class="info-title text-uppercase text-sourcecode mt-3">服務、創新、品質</h6>
                    </div>
                  </div>
                </div>
                <p class="mt-4">原碼數位科技擅長在問題中找出最佳平衡。專業技術是我們的核心，品質服務是我們的價值。針對客戶需求提出最佳方案，高效率的執行能力，高品質的產品是我們的驕傲與堅持。無論是APP行動裝置應用程式的開發，或是涵蓋更廣的系統開發，我們從一開始就會用盡全力，讓您的系統需求，得到一個完整概念，不論您對於系統是否了解，原碼都會提供您最佳的方向，同時在隨後整個專案過程中，也將會緊密與您的團隊隨時掌握開發進度，讓專案目標不會有失真的狀態。</p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 mx-md-auto mt-5">
            <img class="ml-lg-5 wow fadeInUp" src="assets/img/ill/about.jpg" width="100%">
          </div>
          <div class="col-lg-12 info info-horizontal info-hover-primary mt-7">
            <div class="description">
              <h5 class="title">核心價值</h5>
              <h6>自許成為「解決方案的領頭羊」，協助客戶提升競爭優勢。</h6>
              <p class="mt-4">我們看見許多專案最後的結果往往失衡，在於溝通過程、開發過程、驗收過程，與涵蓋整個程序的專案管理上的環節出現問題。原碼能夠精準地解決問題並且避免失衡狀況產生。以人性化的科技公司為核心理念，透過科技的實行為人們帶來更多可能與商機，讓客戶有更多機會去思考自身產品的可能性，過程中同時讓客戶能夠得到最大的滿足。
            </div>
          </div>
          <div class="col-lg-12 info info-horizontal info-hover-primary mt-7">
            <div class="description">
              <h5 class="title">服務優勢</h5>
              <h6>用心、細心、耐心</h6>
              <h6>技術＋經驗＋活力</h6>
              <p class="mt-4">原碼數位科技專注於開發技術,提供高品質及高效率解決方案,我們以<b>用心、細心、耐心的態度</b>，精雕細琢每一項細節，讓您的創意及想法得到最完整的實現。
              相較於坊間的科技公司，我們更加重視軟體美學與友善的使用者界面，不論是簡約時尚的界面設計或是順暢的操作流程都是我們在乎的面相，唯有達到完美平衡才能讓用戶願意嘗試及繼續使用。
              我們致力於為企業打造高品質與高效率的應用平台，團隊的領導人擁有多年開發經驗並且帶領一群既強大又熱血的年輕人所組成，紮實的技術底子及多年的開發經驗，讓我們深受客戶的青睞與信任，並與多家企業建立長期合作關係。我們相信唯有強大技術與開發偏執理念，才能做為各種天馬行空、有趣點子的後盾。</p>
            </div>
          </div>
        </div>
      </div>
    </div>