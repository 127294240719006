<div class="wrapper">
  <div class="section section-hero section-shaped">
    <div class="shape shape-style-3"><img class="bg-image" src="assets/img/pages/business.jpg" style="width: 100%;">
    </div>
    <div class="page-header">
      <div class="container shape-container d-flex align-items-center py-lg">
        <div class="col px-0">
          <div class="row align-items-center justify-content-center">
            <div class="col-lg-6 text-center">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="features-1">
  <div class="container">
    <div class="row">
      <div class="col-md-8 mx-auto text-center  mt-6">
        <span class="badge badge-sourcecode badge-pill mb-3">Case Studies</span>
        <p class="display-3 mt-2">代表性客戶</p>
        <p class="lead mt-4">感謝過去許多產義菁英與客戶的合作往來<br />原碼始終堅持不斷創新，提升開發與管理品質。</p>
      </div>
    </div>
  </div>
</div>
<div class="col-md-10 mx-auto text-center mb-5">
  <ul id="rig">
    <li>
      <a class="rig-cell" >
        <img class="rig-img" src="assets/img/theme/imember_2.png">
        <span class="rig-overlay"></span>
        <span class="rig-text">
          <p>I Member</p>
        </span>
        <span class="rig-text">
          <p></p>
        </span>
      </a>
    </li>
    <li>
      <a class="rig-cell" >
        <img class="rig-img" src="assets/img/theme/fareastone.jpg">
        <span class="rig-overlay"></span>
        <span class="rig-text">
          <p>遠傳電信</p>
        </span>
      </a>
    </li>
    <li>
      <a class="rig-cell" >
        <img class="rig-img" src="assets/img/theme/tainan.jpg">
        <span class="rig-overlay"></span>
        <span class="rig-text">
          <p>臺南市政府</p>
        </span>
        <span class="rig-text">
          <p></p>
        </span>
      </a>
    </li>
    <li>
      <a class="rig-cell" >
        <img class="rig-img" src="assets/img/theme/cloudysys.jpg">
        <span class="rig-overlay"></span>
        <span class="rig-text">
          <p>雲端達人</p>
        </span>
        <span class="rig-text">
          <p></p>
        </span>
      </a>
    </li>
    <li>
      <a class="rig-cell">
        <img class="rig-img" src="assets/img/theme/tsuchi.jpg">
        <span class="rig-overlay"></span>
        <span class="rig-text">
          <p>慈濟長照</p>
        </span>
        <span class="rig-text">
          <p></p>
        </span>
      </a>
    </li>
    <li>
      <a class="rig-cell" >
        <img class="rig-img" src="assets/img/theme/readycom.jpg">
        <span class="rig-overlay"></span>
        <span class="rig-text">
          <p>潤淂康</p>
        </span>
        <span class="rig-text">
          <p></p>
        </span>
      </a>
    </li>
    <li>
      <a class="rig-cell" >
        <img class="rig-img" src="assets/img/theme/rabbit.jpg">
        <span class="rig-overlay"></span>
        <span class="rig-text">
          <p>韓語兔</p>
        </span>
        <span class="rig-text">
          <p><br />Krabbit APP</p>
        </span>
      </a>
    </li>
    <li>
      <a class="rig-cell" >
        <img class="rig-img" src="assets/img/theme/speedorder.jpg">
        <span class="rig-overlay"></span>
        <span class="rig-text">
          <p>速訂網</p>
        </span>
        <span class="rig-text">
          <p></p>
        </span>
      </a>
    </li>
  </ul>
  </div>
  <div class="features-1">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto text-center  mt-6">
          <span class="badge badge-sourcecode badge-pill mb-3">Case Studies</span>
          <p class="display-3 mt-2">專案分享</p>
          <p class="lead mt-4">原碼服務過各種類型的軟體，相信能滿足您的需求。</p>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-10 mx-auto text-center mb-5">
    <ul id="rig">
      <li>
        <a class="rig-cell" >
          <img class="rig-img" src="assets/img/theme/health_care.jpg">
          <span class="rig-overlay"></span>
          <span class="rig-text">
            <p>醫療長照系統</p>
          </span>
          <span class="rig-text">
            <p></p>
          </span>
        </a>
      </li>
      <li>
        <a class="rig-cell" >
          <img class="rig-img" src="assets/img/theme/point.jpg">
          <span class="rig-overlay"></span>
          <span class="rig-text">
            <p>會員點數APP</p>
          </span>
        </a>
      </li>
      <li>
        <a class="rig-cell" >
          <img class="rig-img" src="assets/img/theme/PM.jpg">
          <span class="rig-overlay"></span>
          <span class="rig-text">
            <p>專案時程APP</p>
          </span>
        </a>
      </li></ul>
    </div>