<main class="profile-page" >
    <section class="section-profile-cover section-shaped my-0">
      <div class="wrapper">
          <div class=" section-hero section-shaped">
            <div class="shape shape-style-3"><img class="bg-image" src="assets/img/pages/group.jpg" style="width: 100%;">
            </div>
            <div class="page-header">
              <div class="container shape-container d-flex align-items-center py-lg">
                <div class="col px-0">
                  <div class="row align-items-center justify-content-center">
                    <div class="col-lg-6 text-center">
                      <p class="text-white display-3 wow fadeInDown mt-7">原碼團隊</p>
                      <div class="btn-wrapper mt-6 wow fadeIn">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
  </section>
  <section class="bg-sourcecode">
    <div class="row" style="margin: -100px 3% ;">
      <div class=" mb-5 card">
        <div class="px-2">
          <img src="assets/img/faces/jimmy.png" class="rounded-circle img-center img-fluid shadow shadow-lg--hover picture" style="width: 200px;">
          <div class="pt-4 text-center">
            <h5 class="title">
              <span class="d-block mb-1">Jimmy</span>
              <small class="h6 text-muted">Founder & CEO</small>
            </h5>
          </div>
        </div>
      </div>
      <div class=" mb-5 card">
        <div class="px-2">
          <img src="assets/img/faces/stanley.png" class="rounded-circle img-center img-fluid shadow shadow-lg--hover picture" style="width: 200px;">
          <div class="pt-4 text-center">
            <h5 class="title">
              <span class="d-block mb-1">Stanley</span>
              <small class="h6 text-muted">	Marketing Director</small>
            </h5>
          </div>
        </div>
      </div>
      <div class="mb-5 card">
        <div class="px-2">
          <img src="assets/img/faces/Jason.jpg" class="rounded-circle img-center img-fluid shadow shadow-lg--hover picture" style="width: 200px;">
          <div class="pt-4 text-center">
            <h5 class="title">
              <span class="d-block mb-1">Jason</span>
              <small class="h6 text-muted">CTO</small>
            </h5>
          </div>
        </div>
      </div>
      <div class=" mb-5 card text-center" >
        <div class="px-2 ">
          <img src="assets/img/faces/Carol.jpg" class="rounded-circle img-center img-fluid shadow shadow-lg--hover picture" style="width: 200px;">
          <div class="pt-4 text-center">
            <h5 class="title">
              <span class="d-block mb-1">Carol</span>
            </h5>
          </div>
        </div>
        <small class="h6 text-muted">PM & Artistic Designer</small>
      </div>
      <div class=" mb-5 card">
        <div class="px-2">
          <img src="assets/img/faces/Ryu.jpg" class="rounded-circle img-center img-fluid shadow shadow-lg--hover picture" style="width: 200px;">
          <div class="pt-4 text-center">
            <h5 class="title">
              <span class="d-block mb-1">Ryu</span>
              <small class="h6 text-muted">Senior Engineer</small>
            </h5>
          </div>
        </div>
      </div>
      <div class=" mb-5 card">
        <div class="px-2">
          <img src="assets/img/faces/Sheng.jpg" class="rounded-circle img-center img-fluid shadow shadow-lg--hover picture" style="width: 200px;">
          <div class="pt-4 text-center">
            <h5 class="title">
              <span class="d-block mb-1">De Sheng</span>
              <small class="h6 text-muted">Fullstack Engineer</small>
            </h5>
          </div>
        </div>
      </div>
      <div class=" mb-5 card">
        <div class="px-2">
          <img src="assets/img/faces/Evan.jpg" class="rounded-circle img-center img-fluid shadow shadow-lg--hover picture" style="width: 200px;">
          <div class="pt-4 text-center">
            <h5 class="title">
              <span class="d-block mb-1">Evan</span>
              <small class="h6 text-muted">Fullstack Engineer</small>
            </h5>
          </div>
        </div>
      </div>
      <div class=" mb-5 card">
        <div class="px-2">
          <img src="assets/img/faces/LimCheyYong.jpg" class="rounded-circle img-center img-fluid shadow shadow-lg--hover picture" style="width: 200px;">
          <div class="pt-4 text-center">
            <h5 class="title">
              <span class="d-block mb-1">Lim Chey Yong</span>
              <small class="h6 text-muted">Fullstack Engineer</small>
            </h5>
          </div>
        </div>
      </div>
      <div class=" mb-5 card">
        <div class="px-2">
          <img src="assets/img/faces/Ray.jpg" class="rounded-circle img-center img-fluid shadow shadow-lg--hover picture" style="width: 200px;">
          <div class="pt-4 text-center">
            <h5 class="title">
              <span class="d-block mb-1">Ray</span>
              <small class="h6 text-muted">Software Engineer</small>
            </h5>
          </div>
        </div>
      </div>
      <div class="mb-5 card">
        <div class="px-2">
          <img src="assets/img/faces/stella.jpg" class="rounded-circle img-center img-fluid shadow shadow-lg--hover picture" style="width: 200px;">
          <div class="pt-4 text-center">
            <h5 class="title">
              <span class="d-block mb-1">Stella</span>
              <small class="h6 text-muted">Graphic Designer</small>
            </h5>
          </div>
        </div>
      </div>
      <div class="mb-5 card">
        <div class="px-2">
          <img src="assets/img/faces/Vicky.jpg" class="rounded-circle img-center img-fluid shadow shadow-lg--hover picture" style="width: 200px;">
          <div class="pt-4 text-center">
            <h5 class="title">
              <span class="d-block mb-1">Vicky</span>
              <small class="h6 text-muted">Software Engineer</small>
            </h5>
          </div>
        </div>
      </div>
      <div class="mb-5 card">
        <div class="px-2">
          <img src="assets/img/faces/Henry.jpg" class="rounded-circle img-center img-fluid shadow shadow-lg--hover picture" style="width: 200px;">
          <div class="pt-4 text-center">
            <h5 class="title">
              <span class="d-block mb-1">Henry</span>
              <small class="h6 text-muted">Software Engineer</small>
            </h5>
          </div>
        </div>
      </div>
      <div class=" mb-5 card">
        <div class="px-2">
          <img src="assets/img/faces/Ming.jpg" class="rounded-circle img-center img-fluid shadow shadow-lg--hover picture" style="width: 200px;">
          <div class="pt-4 text-center">
            <h5 class="title">
              <span class="d-block mb-1">Ming</span>
              <small class="h6 text-muted">Maintenance Engineer</small>
            </h5>
          </div>
        </div>
      </div>
      <div class=" mb-5 card">
        <div class="px-2">
          <img src="assets/img/faces/Vincent.jpg" class="rounded-circle img-center img-fluid shadow shadow-lg--hover picture" style="width: 200px;">
          <div class="pt-4 text-center">
            <h5 class="title">
              <span class="d-block mb-1">Vicncent</span>
              <small class="h6 text-muted">Marketing</small>
            </h5>
          </div>
        </div>
      </div>
      <div class=" mb-5 card">
        <div class="px-2">
          <img src="assets/img/faces/Catherine.jpg" class="rounded-circle img-center img-fluid shadow shadow-lg--hover picture" style="width: 200px;">
          <div class="pt-4 text-center">
            <h5 class="title">
              <span class="d-block mb-1">Catherine</span>
              <small class="h6 text-muted">Marketing</small>
            </h5>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
